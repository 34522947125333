import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services';
import { RoutesTransportationAiSuggestionsType, RoutesTransportationAiSuggestionsItem } from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RoutesTransportationAiSuggestionsService {
  private BASE_PATH: string = 'TransportationAiSuggestions';

  private readonly apiService = inject(ApiService);

  initialize(rideIds: number[], types: RoutesTransportationAiSuggestionsType[]): Observable<string> {
    return this.apiService.post(`${this.BASE_PATH}/Initialize`, { rideIds, types }, 'text', false, true);
  }

  getSuggestions(): Observable<RoutesTransportationAiSuggestionsItem[]> {
    return this.apiService.get(`${this.BASE_PATH}/Suggestions`, {}, true);
  }

  save(routesToSave: number[]): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/Save`, routesToSave);
  }
}
