import { Component, OnInit, HostBinding, Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';

import { HeaderMenuIconsService, HeaderDataService } from '@app/shared/services';
import { AuthDataSnapshotService } from '@app/auth/services';
import { BuilderBuildMode, BuilderViewMode } from '@app/builder/models';
import { builderConfig } from '@app/builder/configs';
import {
  BuilderDataService,
  BuilderModalService,
  BuilderPopupService,
  BuilderDataStoreService,
  BuilderTuningStoreService,
  BuilderAgendaStoreService,
  BuilderFilterStoreService,
  BuilderPassengersStoreService,
  BuilderRoutesPassengersInfoStoreService
} from '@app/builder/services';

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: [ './builder.component.scss', './builder.component.rtl.scss' ],
  providers: [
    BuilderDataService,
    BuilderDataStoreService,
    BuilderModalService,
    BuilderPopupService,
    BsModalRef,
    BuilderAgendaStoreService,
    BuilderFilterStoreService,
    BuilderPassengersStoreService,
    BuilderTuningStoreService,
    BuilderRoutesPassengersInfoStoreService,
    AuthDataSnapshotService
  ]
})
export class BuilderComponent implements OnInit, OnDestroy {
  @Input() trackingId: string;
  @Input() viewMode: BuilderViewMode = BuilderViewMode.Default;
  @Input() buildMode: BuilderBuildMode = BuilderBuildMode.Routes;

  @HostBinding('class') hostClasses: string = 'builder';

  builderViewMode = BuilderViewMode;

  constructor(
    private route: ActivatedRoute,
    private headerDataService: HeaderDataService,
    private headerMenuIconsService: HeaderMenuIconsService,
    public builderDataService: BuilderDataService
  ) {}

  ngOnInit() {
    const { viewMode, buildMode } = (<any>this.route).data.value;

    if (viewMode) {
      this.viewMode = viewMode;
    }

    if (buildMode) {
      this.buildMode = buildMode;
    }

    if (this.buildMode) {
      this.trackingId = builderConfig.buildMode[this.buildMode].trackingId;
    }

    this.builderDataService.setConfig('viewMode', this.viewMode);
    this.builderDataService.setConfig('buildMode', this.buildMode);
    this.builderDataService.setConfig('trackingId', this.trackingId);

    this.headerDataService.updateIsDateDisabled(true);
  }

  ngOnDestroy() {
    this.headerDataService.updateIsDateDisabled(false);

    this.headerMenuIconsService.resetIcons();
  }
}
