import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UPopupService } from '@shift/ulib';

import { InputContact } from '@app/shared/models';
import { InputContactsService, ValidationService } from '@app/shared/services';
import { inputContactsConfig } from '@app/shared/configs';
import { AuthModuleAccountingFeature, AuthModuleAccountingFeatureType, AuthModuleName } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';

@Injectable()
export class ShuttleCompaniesCustomersInputContactsService extends InputContactsService {
  readonly isAccountingEnabled = this.authDataService.checkFeature({
    module: AuthModuleName.Accounting,
    name: AuthModuleAccountingFeature.Type,
    values: [ AuthModuleAccountingFeatureType.Generic ]
  });

  constructor(
    private authDataService: AuthDataService,
    fb: UntypedFormBuilder,
    validationService: ValidationService,
    uPopupService: UPopupService
  ) {
    super(fb, validationService, uPopupService);
  }

  generateContactForm(contact: InputContact): UntypedFormGroup {
    return this.fb.group({
      groupId: [ contact.groupId ],
      role: [ contact.role, [ Validators.required ] ],
      name: [ contact.name ],
      contacts: this.fb.array([]),
      ...(this.isAccountingEnabled ? { sendAccountingDocuments: [ { value: false, disabled: true } ] } : {})
    });
  }

  generateNewContactData(groupId: number): InputContact {
    return {
      groupId,
      role: '',
      name: '',
      contacts: [
        { type: inputContactsConfig.defaultContactType, contact: '' }
      ],
      ...(this.isAccountingEnabled ? { sendAccountingDocuments: false } : {})
    };
  }
}
