import { UInputCitiesCityLocation, UMultiselectItem } from '@shift/ulib';

import { ApiBaseResponse, Cities, PersonContact } from '@app/shared/models';
import { BuilderFullRoutesFor } from '@app/builder/models';
import { DemandsRemovePassengerRideParam } from '@app/demands/models';

export enum PassengerPickUpType {
  FromHome,
  FromStation
}

export enum PassengerGender {
  No,
  Male,
  Female
}

export enum PassengersRoutesActions {
  Remove,
  Keep
}

export enum PassengersChangesActions {
  Remove,
  Add
}

export enum PassengerDistanceToStation {
  NoValue = -2,
  NoStation = -1
}

export enum PassengerType {
  All,
  Assigned,
  Unassigned
}

export enum PassengerStatusType {
  Active,
  Inactive,
  Other,
  Shuttled,
  NotShuttled,
  TravelExpenses
}

export enum PassengersAddEditMode {
  Default = 'default',
  AssignToRoute = 'assignToRoute',
  UpdateOnRoute = 'updateOnRoute'
}

export enum PassengersFiltersType {
  RouteSuggestions = 'routeSuggestions',
  FeedPassengersWithoutSchedule = 'feedPassengersWithoutSchedule'
}

export enum PassengerDemandStatus {
  WithSchedule = 1,
  WithoutSchedule = 2,
  WithSchool = 4,
  WithoutSchool = 8
}

export enum PassengersInitialDataType {
  PassengerCities = 'passengerCities',
  Branches = 'branches',
  Departments = 'departments',
  Genders = 'genders',
  RouteTypes = 'routeTypes',
  Statuses = 'statuses',
  AccompanyApprovals = 'accompanyApprovals',
  ClassTypes = 'classTypes',
  DisabilityCodes = 'disabilityCodes',
  Schools = 'schools',
  ShuttleApprovals = 'shuttleApprovals',
  Tags = 'tags',
  RefundEligibilities = 'refundEligibilities',
  RideSmsEventModes = 'rideSmsEventsModes',
  RideSmsEventOptions = 'rideSmsEventsOptions'
}

export enum PassengersRouteType {
  Regular,
  Shuttle,
  HopOnHopOff
}

export enum PassengersTabName {
  Details = 'details',
  Settings = 'settings',
  Logs = 'logs',
  Routes = 'routes'
}

export enum PassengersMode {
  Default = 'default',
  RoutePlannerSC = 'routePlannerSC'
}

export enum PassengersRideSmsEventsOption {
  XMinutesBeforeRideStarts = 'XMinutesBeforeRideStarts',
  UponPassengersAssignment = 'UponPassengersAssignment',
  UponPassengersRemoval = 'UponPassengersRemoval'
}

export enum PassengersRideSmsEventsMode {
  ByCustomerSettings = 'ByCustomerSettings',
  Custom = 'Custom'
}

export interface PassengerStation {
  value: number;
  name: string;
  latitude: number;
  longitude: number;
}

export interface PassengerDetails {
  id: number;
  firstName: string;
  lastName: string;
  identity: string;
  manualAddress: string;
  address: string;
  latitude: number;
  longitude: number;
  placeId: string;
  gender: number;
  birthDate: string;
  comment: string;
  contacts: PersonContact[];
  validationErrors: { [key: string]: string; };
  passengerId: number;
  departmentId: number;
  department: string;
  stationId: number;
  stationName: string;
  eligibleForShuttle: boolean;
  status: number;
  pickUpType: number;
  branchId: number;
  branch: string;
  stationAddressId: number;
  stationAddress: {
    fullAddress: string;
    latitude: number;
    longitude: number;
    placeId: string;
  };
  travelingAlone: boolean;
  isSmsNotificationsEnabled: boolean;
  tags: string;
  tagsList?: {
    name: string;
    tagId: number;
  }[];
  areaId: number;
  passengerNumber: number;
  refundEligibility: number;
  distanceToStation?: number;
  passengerRoutes: PassengerRoute[];
  mobile: PersonContact;
  addressId: number;
  role?: string;
  needElevator?: string | boolean;
  schoolId?: number;
  schoolName?: string;
  schoolIdentity?: number;
  aadCode?: string;
  sapCode?: string;
}

export interface PassengerSettings {
  isSmsNotificationsEnabled: boolean;
  travelingAlone: boolean;
  rideSmsEventsMode: PassengersRideSmsEventsMode;
  rideSmsEventsOptions: PassengersRideSmsEventsOption[];
  permissions: {
    rideReservationEnabled: boolean;
    joinRouteTypes: PassengersRouteType[];
    createRouteTypes: PassengersRouteType[];
  };
}

export interface Passenger {
  details: PassengerDetails;
  settings?: PassengerSettings;
}

export interface PassengerBase extends ApiBaseResponse {
  value: PassengerDetails;
}

export interface PassengersExtraFiltersValues {
  byDemand: boolean;
  directions: number[];
  startDate: string;
  startTime?: string;
  endDate: string;
  endTime?: string;
  routesFor?: BuilderFullRoutesFor;
  customerId?: number;
}

export interface PassengersExtraFiltersOptions {
  shifts: UMultiselectItem[];
}

export interface PassengersExtraFiltersOriginalOptions {
  shifts: {
    branchId: number;
    id: number;
    name: string;
  }[];
}

export interface PassengersExtraFilters {
  values: PassengersExtraFiltersValues;
  originalOptions: PassengersExtraFiltersOriginalOptions;
  options: PassengersExtraFiltersOptions;
}

export interface PassengerRoute {
  routeId: number;
  direction: number;
  code: number;
  name: string;
  dateFrom: string;
  dateTo: string;
  isTemplate: boolean;
  keep: boolean;
  update: boolean;
}

export interface PassengersChangeParams {
  routeIds: number[];
  passengerId: number;
}

export interface PassengersChangePassengerOnRoutesParams {
  routeIds: number[];
  passengerId: number;
  moveToNewStation: boolean;
  moveToNewTarget: boolean;
  needAccompany?: boolean;
}

export interface PassengersChange {
  errorMessage: string;
  errors: {
    code: string;
    description: string;
  }[];
  success: boolean;
  value: number;
}

export interface PassengersRoutesPopupAction {
  type: string;
  removeRoutes?: number[];
  removeErrorPopup?: boolean;
  params?: {
    routes?: PassengerRoute[];
    passengersRides?: DemandsRemovePassengerRideParam[];
  };
}

export interface PassengersGetParams {
  statusTypes?: PassengerStatusType[];
  passengerType?: PassengerType;
  departmentsIds: number[];
  search: string;
  shiftIds?: number[];
  classTypeIds?: number[];
  schoolsIds?: number[];
  skip?: number;
  take?: number;
  eligibleForShuttle?: boolean;
  cities?: UInputCitiesCityLocation[];
  branchIds?: number[];
  tagsIds?: number[];
  customerIds?: number[];
}

export interface PassengerMember extends ApiBaseResponse {
  value: number;
}

export interface PassengersHistoryRow {
  auditLogId: number;
  dateCreated: string;
  entityId: number;
  entityName: string;
  isNextYear: boolean;
  logType: number;
  newValue: string;
  oldValue: string;
  periodFrom: string;
  periodTo: string;
  propertyName: string;
  user: string;
  executor?: string;
  executionDate?: string;
  executionTime?: string;
  changeType?: string;
  content?: string;
  changePeriod?: string;
}


export interface PassengersInitialDataItem {
  id: number;
  name: string;
}

export interface PassengersInitialDataBranchItem extends PassengersInitialDataItem {
  isMain: boolean;
}

export interface PassengersInitialDataDisabilityCodeItem extends PassengersInitialDataItem {
  id: number;
  name: string;
  eligibleForAccompany: boolean;
  eligibleForShuttle: boolean;
}

export interface PassengersInitialDataClassTypeItem extends PassengersInitialDataItem {
  grade: number;
  minDistanceToSchool: number;
}

export interface PassengersInitialDataSchoolItem {
  id: number;
  name: string;
  identity: string;
  classNumbers: number[];
  classTypes: PassengersInitialDataItem[];
}

export interface PassengersInitialData {
  accompanyApprovals?: PassengersInitialDataItem[];
  shuttleApprovals?: PassengersInitialDataItem[];
  tags?: PassengersInitialDataItem[];
  departments?: PassengersInitialDataItem[];
  disabilityCodes?: PassengersInitialDataDisabilityCodeItem[];
  genders?: PassengersInitialDataItem[];
  routeTypes?: PassengersInitialDataItem[];
  schools?: PassengersInitialDataSchoolItem[];
  statuses?: PassengersInitialDataItem[];
  classTypes?: PassengersInitialDataClassTypeItem[];
  branches?: PassengersInitialDataBranchItem[];
  passengerCities?: Cities;
  refundEligibilities?: PassengersInitialDataItem[];
  rideSmsEventModes?: PassengersInitialDataItem[];
  rideSmsEventOptions?: PassengersInitialDataItem[];
}

export interface PassengersInitialDataState {
  data: PassengersInitialData;
  initialBaseDataTypesLoaded: boolean;
  customerId: number;
}
