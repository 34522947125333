<app-general-card
  [class]="'general-card_app-routes-ai-suggestions-confirm'"
  [title]="config().dictionary.title"
  [buttons]="buttons()"
  (buttonClick)="save()"
  (closeAction)="close()"
>
  <div class="routes-ai-suggestions-confirm__explanation">
    <button
      uButton
      class="routes-ai-suggestions-confirm__explanation-btn"
      (click)="onShowExplanationChange()"
    >
      {{config().dictionary.howDoesItWork | translate}}
    </button>

    @if (showExplanation()) {
      <p class="routes-ai-suggestions-confirm__explanation-text">
        {{config().dictionary.explanation | translate}}
      </p>
    }
  </div>
  <div class="routes-ai-suggestions-confirm__checkboxes">
    @for (checkbox of checkboxes(); track $index) {
      <div class="routes-ai-suggestions-confirm__checkboxes-item">
        <u-checkbox
          class="u-checkbox_box u-checkbox_app-routes-ai-suggestions-confirm"
          [ngModel]="checkbox.checked"
          (ngModelChange)="updateCheckbox($index, $event)"
        />
        <div class="routes-ai-suggestions-confirm__checkboxes-name">{{checkbox.name | translate}}</div>
      </div>
    }
  </div>
</app-general-card>
