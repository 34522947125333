import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin, of, Observable, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { filter, finalize, first, map, pairwise, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { omit, isEqual, cloneDeep } from 'lodash';
import {
  UGridScroll,
  USearchFilter,
  UInputCitiesCity,
  UPopupService,
  UDaysOrderService,
  USearchFilterType
} from '@shift/ulib';

import { environment } from '@environments/environment';
import { HeaderSearchFiltersService, TablePageService } from '@app/shared/services';
import {
  CommonService,
  ConstantsService,
  CustomerDataService,
  LocalizationService,
  TrackingService,
  HeaderDataService,
  CitiesStoreService
} from '@app/shared/services';
import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import {
  CustomerDataType,
  WeekSwitchStartEnd,
  GlobalSearchFilter,
  ModalActions
} from '@app/shared/models';
import { generateWeekDates } from '@app/shared/utils';
import { AuthModuleName, AuthModuleDemandsPassengersViewFeature, AuthModuleDemandsPassengersViewFeatureType } from '@app/auth/models';
import { AuthDataService, AuthDataSnapshotService } from '@app/auth/services';
import { AppConstants } from '@app/shared/constants';
import {
  PassengersRoutesPopupAction,
  PassengerDistanceToStation
} from '@app/passengers/models';
import { PassengersDataService } from '@app/passengers/services';
import { UserService } from '@app/user/services';
import { BranchesOption } from '@app/branches/models';
import { DemandsCommonService, DemandsPassengersViewService } from '@app/demands/services';
import {
  DemandsTableParams,
  DemandsTableRow,
  DemandsCustomerData,
  DemandsPassengerRide,
  DemandsWeekDuplicateActionType
} from '@app/demands/models';
import { DemandsExcelExportComponent } from '@app/demands/components/demands-excel-export/demands-excel-export.component';
import { DemandsWeekDuplicateComponent } from '@app/demands/components/demands-week-duplicate/demands-week-duplicate.component';
import { DemandsRoutesPopupComponent } from '@app/demands/components/demands-routes-popup/demands-routes-popup.component';
import { demandsConfig } from '@app/demands/configs';
import { demandsPassengersViewComponentConfig } from './demands-passengers-view.component.config';

@Component({
  selector: 'app-demands-passengers-view',
  templateUrl: './demands-passengers-view.component.html',
  styleUrls: [ './demands-passengers-view.component.scss', 'demands-passengers-view.component.rtl.scss' ],
  providers: [ TablePageService, CitiesStoreService, AuthDataSnapshotService ]
})
export class DemandsPassengersViewComponent implements OnInit, OnDestroy {
  @ViewChild('dayCellHeader', { static: false }) public dayCellHeader: TemplateRef<any>;
  @ViewChild('dayCell', { static: false }) public dayCell: TemplateRef<any>;
  @ViewChild('demandsNameCell', { static: false }) public demandsNameCell: TemplateRef<any>;
  @ViewChild('departmentCell', { static: false }) public departmentCell: TemplateRef<any>;
  @ViewChild('yesNoCell', { static: false }) public yesNoCell: TemplateRef<any>;
  @ViewChild('yesNoCellFilter', { static: false }) public yesNoCellFilter: TemplateRef<any>;
  @ViewChild('statusCell', { static: false }) public statusCell: TemplateRef<any>;
  @ViewChild('statusCellFilter', { static: false }) public statusCellFilter: TemplateRef<any>;
  @ViewChild('commentCell', { static: false }) public commentCell: TemplateRef<any>;
  @ViewChild('stationCell', { static: false }) public stationCell: TemplateRef<any>;
  @ViewChild('tagsCell', { static: false }) public tagsCell: TemplateRef<any>;
  @ViewChild('genderCell', { static: false }) public genderCell: TemplateRef<any>;
  @ViewChild('genderCellFilter', { static: false }) public genderCellFilter: TemplateRef<any>;
  @ViewChild('distanceToStationCell', { static: false }) public distanceToStationCell: TemplateRef<any>;
  @ViewChild('distanceToStationCellFilter', { static: false }) public distanceToStationCellFilter: TemplateRef<any>;
  @ViewChild('demandsTable', { static: false }) public demandsTable: ElementRef<HTMLElement>;

  @HostBinding('class') hostClasses: string = 'demands-passengers-view';

  private unsubscribe: Subject<void> = new Subject();
  private unsubscribeAuthModuleFeature: Subject<void> = new Subject();
  private passengersGet: BehaviorSubject<DemandsTableParams> = new BehaviorSubject(this.getPassengersParams());
  private filtersSubscription: Subscription = new Subscription();

  private defaultDepartmentIds: number[] = [];
  private defaultBranchIds: number[] = [];
  private isDestroyed: boolean;
  private visibleColumns = [];
  private isSoldier: boolean;
  private isStudent: boolean;
  private specialShiftText: string;
  private weekSwitchChangeStore: WeekSwitchStartEnd;
  private customerDataItemsStore: DemandsCustomerData;
  private customerDataItemsOriginalStore: { [key: string]: { id: number; name: string; branchId?: number; }[] } = {};
  private passengersGet$: Observable<DemandsTableParams> = this.passengersGet.asObservable();

  hasBranches: boolean;
  isBulkAssignOpen: boolean;
  isLoading: boolean = true;
  isLoadingPassengers: boolean;
  isRtl: boolean = this.localizationService.isRtl();
  passengerStatusType: { [key: number]: string; } = {};
  maxAmountPassengers: number = environment.config.maxAmountRecordsForPage;
  skip: number = 0;
  config = cloneDeep(demandsPassengersViewComponentConfig.default);
  appConstants = AppConstants;
  passengerDistanceToStation = PassengerDistanceToStation;
  featureType: AuthModuleDemandsPassengersViewFeatureType;

  constructor(
    private cdRef: ChangeDetectorRef,
    private demandsPassengersViewService: DemandsPassengersViewService,
    private uDaysOrderService: UDaysOrderService,
    private commonService: CommonService,
    private popupService: UPopupService,
    private toastr: LocalizedToastrService,
    private headerDataService: HeaderDataService,
    private bsModalService: BsModalService,
    private headerSearchFiltersService: HeaderSearchFiltersService,
    private localizationService: LocalizationService,
    private customerDataService: CustomerDataService,
    private trackingService: TrackingService,
    private translateService: TranslateService,
    private constantsService: ConstantsService,
    private userService: UserService,
    private passengersDataService: PassengersDataService,
    private authDataService: AuthDataService,
    private citiesStoreService: CitiesStoreService,
    public tablePageService: TablePageService,
    public demandsCommonService: DemandsCommonService,
    public authDataSnapshotService: AuthDataSnapshotService
  ) {}

  ngOnInit() {
    this.init();

    this.tablePageService.stickyRowHeight$ = this.demandsCommonService.bulkRowHeight$;

    this.initHeaderComponents();
  }

  ngOnDestroy() {
    this.closeBulkAssignRow();
    this.initHeaderComponents(false);

    this.unsubscribeAuthModuleFeature.next();
    this.unsubscribeAuthModuleFeature.complete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.filtersSubscription.unsubscribe();

    this.isDestroyed = true;
  }

  private init() {
    forkJoin([
      this.userService.getUserDepartments().pipe(map(departments => departments.map(department => department.id))),
      this.userService.getUserBranches().pipe(map(branches => branches.map(branch => branch.id))),
      this.translateService.get(this.config.dictionary.specialShift),
      this.constantsService.getConstants('passengerStatusType'),
      this.authDataService.moduleFeatureByName$(AuthModuleName.DemandsPassengersView, AuthModuleDemandsPassengersViewFeature.Type).pipe(take(1))
    ])
      .pipe(
        takeUntil(this.unsubscribeAuthModuleFeature),
        takeWhile(() => !this.isDestroyed)
      )
      .subscribe(([ departmentIds, branchIds, specialShift, constants, featureType ]) => {
        this.defaultDepartmentIds = departmentIds;
        this.defaultBranchIds = branchIds;
        this.specialShiftText = specialShift;

        constants.passengerStatusType.forEach(status => this.passengerStatusType[status.id] = status.name);

        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.unsubscribe = new Subject();

        this.featureType = featureType;
        this.isSoldier = featureType === AuthModuleDemandsPassengersViewFeatureType.Soldier;
        this.isStudent = featureType === AuthModuleDemandsPassengersViewFeatureType.Student;

        this.initConfig(featureType);
        this.onGlobalSearch();
        this.onWeekChange();
        this.onGlobalFilters();
        this.onAssignShift();
        this.setGlobalFilters();
        this.onPassengersGet();
        this.getPassengers();
      });
  }

  private initHeaderComponents(active: boolean = true) {
    this.headerDataService.updateShowWeekSwitch(active);
  }

  private initConfig(data: AuthModuleDemandsPassengersViewFeatureType) {
    this.config = cloneDeep(demandsPassengersViewComponentConfig[data] || demandsPassengersViewComponentConfig.default);
    this.config.globalFilters = this.config.globalFilters.filter(globalFilter => globalFilter.feature ? this.authDataService.checkFeature(globalFilter.feature) : true);
    this.config.table.tableConfig.rowHeight = this.getRowHeight;

    const columns = cloneDeep(demandsPassengersViewComponentConfig[data] || demandsPassengersViewComponentConfig.default).table.tableConfig.columns
      .filter(column => column.feature ? this.authDataService.checkFeature(column.feature) : true);

    this.config.table.tableConfig.columns = [
      ...this.attachTemplatesAndFunctionsToColumns(columns)
    ];

    this.config.table.tableConfig.filterTypeItemsFns = {
      distanceToStationFilterTypeItemsFn: this.distanceToStationFilterTypeItemsFn
    };
  }

  private onGlobalSearch() {
    this.headerSearchFiltersService.appliedSearch$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.trackingService.track(`[${this.config.table.trackingId}] - search`);
        this.getPassengers();
      });
  }

  private onWeekChange() {
    this.headerDataService
      .weekSwitchChange$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.getPassengers());
  }

  private onGlobalFilters() {
    this.headerSearchFiltersService.filters$.pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.getPassengers());
  }

  private onAssignShift() {
    this.demandsCommonService
      .updatedPassengers$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(updates => {
        this.tablePageService.updateRows(updates.passengers.map(passenger => ({ ...passenger, id: passenger.passengerId })));

        if (updates.passengers.length > 1) {
          this.openBulkAssignRow();
          this.updateRows(this.tablePageService.rows);
        }

        if (updates.rides.length) {
          this.openAssignedRoutesPopup(updates.rides);
        }
      });
  }

  private openAssignedRoutesPopup(passengerRides: DemandsPassengerRide[]) {
    if (passengerRides.length) {
      const assignedRoutesModalRef = this.bsModalService.show(
        DemandsRoutesPopupComponent,
        {
          class: 'u-modal u-modal_app-demands-passengers-assigned-routes',
          animated: true,
          ignoreBackdropClick: true,
          initialState: {
            passengerRides
          }
        }
      );

      assignedRoutesModalRef.content.action
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((action: PassengersRoutesPopupAction) => {
          if (action.params && action.params.passengersRides.length) {
            this.demandsPassengersViewService.removePassengerFromRide({ passengersRides: action.params.passengersRides })
              .pipe(first())
              .subscribe(() => this.toastr.success('general.successful'));
          }
        });
    }
  }

  private getRowHeight(row: DemandsTableRow) {
    const defaultHeight = demandsConfig.shiftHeight + demandsConfig.rowPadding;

    if (!row) {
      return defaultHeight;
    }

    const maxShiftsCount = demandsConfig.daysOfWeek
      .reduce((acc, dayOfWeek) =>
        row[`dayOfWeek-${dayOfWeek}`] && row[`dayOfWeek-${dayOfWeek}`].length > acc ?
          row[`dayOfWeek-${dayOfWeek}`].length : acc
      , 0);

    return maxShiftsCount > 1 ? (maxShiftsCount * demandsConfig.shiftHeight) + demandsConfig.rowPadding : defaultHeight;
  }

  private distanceToStationFilterTypeItemsFn = (prop, rows) => (
    rows.reduce((accRows, row) => ([
      ...accRows,
      !accRows.find(item =>
        row.distanceToStationRange && item ?
          item.between === row.distanceToStationRange.between && item.to === row.distanceToStationRange.to : item === row[prop])
        ? row.distanceToStationRange || row[prop] : null
    ]), [])
      .filter(value => value)
      .sort(this.sortDistanceRange)
  );

  private sortDistanceRange(a, b): -1 | 0 | 1 {
    if (a === PassengerDistanceToStation.NoStation || a === PassengerDistanceToStation.NoValue) {
      return -1;
    }

    if (a && b) {
      if ((a && a.between) < (b && b.between)) {
        return -1;
      }

      if ((a && a.between) > (b && b.between)) {
        return 1;
      }
    }

    return 0;
  }

  private getCitiesFilterLazyItems(name: string, nameToGetParams?: string): Observable<UInputCitiesCity[]> {
    return of(name)
      .pipe(
        switchMap(() => {
          const filterToGetParams = nameToGetParams && this.headerSearchFiltersService.getFilters()?.find(item => item.name === nameToGetParams);
          const params = filterToGetParams?.control?.value || [];

          return this.citiesStoreService.getCitiesBySelectedBranchIds$(params);
        }),
        tap(items => {
          this.customerDataItemsStore[name] = items;

          this.headerSearchFiltersService.updateFiltersItems({ [name]: items });
        })
      );
  }

  private addGlobalFilterResetting(control: UntypedFormControl, resetFiltersByNames: string[] = [], filterToGetParams?: string) {
    control.valueChanges
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        const filters = (this.headerSearchFiltersService.getFilters() || []);

        resetFiltersByNames.forEach(name => {
          const currentFilter = filters.find(item => item.name === name);

          if (currentFilter) {
            currentFilter.items = [];
            currentFilter.lazyLoadItems = [ USearchFilterType.InputCitiesMultiselect, USearchFilterType.InputCitiesSelect ].includes(currentFilter.type) ?
              this.getCitiesFilterLazyItems(currentFilter.name, filterToGetParams) : null;
            currentFilter.control.patchValue([]);
          }
        });

        this.headerSearchFiltersService.updateFiltersControls(filters);
      });
  }

  private setGlobalFilters() {
    this.customerDataItemsStore = cloneDeep(this.customerDataItemsStore || this.config.customerDataFilters);

    const appliedFilters = this.headerSearchFiltersService.getAppliedFilters();
    const appliedFiltersValues = appliedFilters?.reduce((acc, obj) => ({ ...acc, [obj.name]: obj.value }), {}) || {};
    const currentFilters: USearchFilter[] = this.headerSearchFiltersService.getFilters();
    const currentFiltersValues = this.passengersDataService.generateFiltersValues(currentFilters);
    const selectedFiltersValues = { ...appliedFiltersValues, ...currentFiltersValues };
    const currentFiltersItems = this.passengersDataService.generateFiltersItems(currentFilters);
    const onlyDefaultFiltersSelected = !Object.keys(selectedFiltersValues)
      .filter(filterKey => filterKey !== 'departmentIds' && filterKey !== 'branchIds')
      .some(filterKey => !!selectedFiltersValues[filterKey]?.length);

    if (this.defaultDepartmentIds.length && !selectedFiltersValues?.departmentIds?.length && onlyDefaultFiltersSelected) {
      selectedFiltersValues.departmentIds = this.defaultDepartmentIds;
    }

    if (this.defaultBranchIds.length && !selectedFiltersValues?.branchIds?.length && onlyDefaultFiltersSelected) {
      selectedFiltersValues.branchIds = this.defaultBranchIds;
    }

    const selectedGlobalFilters = this.headerSearchFiltersService.getFilters();
    const filters: USearchFilter[] = this.config.globalFilters.map(filterObj => {
      const selectedGlobalFilter = selectedGlobalFilters.find(obj => obj.name === filterObj.name);
      const globalFilterItem = this.generateGlobalFilterItem(selectedGlobalFilter, filterObj, selectedFiltersValues);

      if (filterObj.resetFiltersByNames) {
        this.addGlobalFilterResetting(globalFilterItem.control, filterObj.resetFiltersByNames, filterObj.name);
      }

      return globalFilterItem;
    });

    this.onGlobalFiltersChange(filters);

    if (Object.keys(selectedFiltersValues).some(filterKey => !!selectedFiltersValues[filterKey]?.length)) {
      this.headerSearchFiltersService.updateFiltersControls(filters);

      const applySelectedFilters = () => {
        this.headerSearchFiltersService.onFiltersApplied(Object.keys(selectedFiltersValues).map(key => ({
          name: key,
          value: selectedFiltersValues[key]
        })));
      };

      const loadDefaultDepartments = !!this.defaultDepartmentIds.length && !currentFiltersItems.departmentIds?.length;
      const loadDefaultBranches = !!this.defaultBranchIds.length && !currentFiltersItems.branchIds?.length;

      if (loadDefaultDepartments || loadDefaultBranches) {
        const departmentsFilter = filters.find(item => item.name === 'departmentIds');
        const branchesFilter = filters.find(item => item.name === 'branchIds');

        forkJoin([
          loadDefaultDepartments && departmentsFilter.lazyLoadItems ? departmentsFilter.lazyLoadItems.pipe(take(1)) : of(null),
          loadDefaultBranches && branchesFilter.lazyLoadItems ? branchesFilter.lazyLoadItems.pipe(take(1)) : of(null)
        ])
          .pipe(
            takeUntil(this.unsubscribe)
          )
          .subscribe(() => {
            applySelectedFilters();
          });
      } else {
        applySelectedFilters();
      }
    } else {
      this.headerSearchFiltersService.applyFilters(filters);
    }
  }

  private onGlobalFiltersChange(filters: USearchFilter[]) {
    const shiftIdsDefaultFilter = this.config.globalFilters.find(ob => ob.name === 'shiftIds');

    filters.forEach(filterObj => {
      if (filterObj.lazyLoadItems) {
        this.filtersSubscription.add(
          filterObj.control
            .valueChanges
            .subscribe(branchIds => {
              let customValues = {};

              if (filterObj.name === 'branchIds') {
                const shiftIds = this.customerDataItemsOriginalStore && this.customerDataItemsOriginalStore.shiftIds ? this.customerDataItemsOriginalStore.shiftIds : [];
                let filteredShifts = [];

                if (branchIds && branchIds.length) {
                  const hasBranchesWithoutShift = !branchIds
                    .every(branchId => shiftIds.some(shift => shift.branchId === branchId));

                  filteredShifts = shiftIds
                    .filter(shift => branchIds.includes(shift.branchId) || (hasBranchesWithoutShift && shift.branchId === BranchesOption.Without));
                }

                const shiftIdsItems = (filteredShifts.length ? filteredShifts : shiftIds).map(item => ({ name: item.name, value: item.id }));

                const branchIdsStoreLength = this.customerDataItemsOriginalStore.branchIds && this.customerDataItemsOriginalStore.branchIds.length;

                this.customerDataItemsStore.shiftIds = branchIds && (!branchIds.length || branchIds.length === branchIdsStoreLength) ?
                  [ ...shiftIdsDefaultFilter.items, ...shiftIdsItems ] : shiftIdsItems;

                customValues = { shiftIds: [] };
              }

              this.updateGlobalFilters(this.customerDataItemsStore, customValues);
            })
        );
      }
    });
  }

  private generateGlobalFilterItem(selectedGlobalFilter: USearchFilter, filterObj: GlobalSearchFilter, selectedFiltersValues: { [key: string]: string | number | boolean | (string | number | boolean)[]; }): USearchFilter {
    return {
      name: filterObj.name,
      items: selectedGlobalFilter?.items || (filterObj.itemsLazyLoad ? null : filterObj.items),
      control: new UntypedFormControl(selectedFiltersValues[filterObj.name] || filterObj.value),
      type: filterObj.type,
      title: filterObj.title,
      ...(filterObj.emptyName ? { emptyName: filterObj.emptyName } : {}),
      lazyLoadItems: !selectedGlobalFilter?.items?.length && !this.customerDataItemsOriginalStore[filterObj.name] ?
        (
          filterObj.name === 'cities' ?
            this.getCitiesFilterLazyItems(filterObj.name, 'branchIds')
            :
            this.customerDataService.getCustomerData({ types: [ this.config.customerDataFilters[filterObj.name] ] })
              .pipe(
                map(data => {
                  let items = [];

                  if (filterObj.name === 'shiftIds') {
                    const filtersValues = this.headerSearchFiltersService.getFiltersValue();
                    const shifts = data[this.config.customerDataFilters[filterObj.name]];
                    let filteredShifts = [];

                    if (filtersValues && Array.isArray(filtersValues.branchIds) && filtersValues.branchIds.length) {
                      const branchIds = <number[]>filtersValues.branchIds;
                      const hasBranchesWithoutShift = !branchIds
                        .every(branchId => shifts.some(shift => shift.branchId === branchId));

                      filteredShifts = shifts
                        .filter(shift =>
                          branchIds.includes(shift.branchId) || (hasBranchesWithoutShift && shift.branchId === BranchesOption.Without)
                        );
                    }

                    const branchIdsStoreLength = this.customerDataItemsOriginalStore.branchIds && this.customerDataItemsOriginalStore.branchIds.length;

                    items = (filteredShifts.length ? filteredShifts : shifts).map(item => ({ name: item.name, value: item.id }));
                    items = Array.isArray(filtersValues.branchIds) &&
                    (
                      !filtersValues.branchIds.length || filtersValues.branchIds.length === branchIdsStoreLength
                    ) ? [ ...filterObj.items, ...items ] : items;
                  } else {
                    items = data[this.config.customerDataFilters[filterObj.name]].map(ob => ({ name: ob.name, value: ob.id }));
                    items = filterObj.items ? [ ...filterObj.items, ...items ] : items;
                  }

                  this.customerDataItemsOriginalStore[filterObj.name] = data[this.config.customerDataFilters[filterObj.name]];
                  this.customerDataItemsStore[filterObj.name] = items;

                  return items;
                }),
                tap(items => this.headerSearchFiltersService.updateFiltersItems({ [filterObj.name]: items }))
              )
        )
        : null
    };
  }

  private updateGlobalFilters(customerData: DemandsCustomerData, customValues?: object) {
    const newFilters = this.headerSearchFiltersService.getFilters()
      .map(filterObj => {
        filterObj.items = customerData && Array.isArray(customerData[filterObj.name]) ? customerData[filterObj.name] : filterObj.items;

        if (customValues && customValues[filterObj.name]) {
          filterObj.control.patchValue(customValues[filterObj.name]);
        }

        return filterObj;
      });

    this.headerSearchFiltersService.updateFiltersItems(newFilters);
  }

  private onPassengersGet() {
    this.passengersGet$
      .pipe(
        takeUntil(this.unsubscribe),
        takeWhile(() => !this.isDestroyed),
        pairwise(),
        filter(([ prev, next ]) => !isEqual(prev, next) || !this.isLoadingPassengers),
        map(([ prev, next ]) => next),
        switchMap(params => {
          this.isLoadingPassengers = true;

          const weekSwitchChange = this.headerDataService.getWeekSwitchChangeValue();

          return forkJoin([
            this.demandsPassengersViewService.getTableData(params)
              .pipe(
                tap(data => {
                  this.tablePageService.totalCount = data.totalCount;

                  if (!isEqual(weekSwitchChange, this.weekSwitchChangeStore)) {
                    this.weekSwitchChangeStore = weekSwitchChange;
                    this.updateColumns(data.totalCount, weekSwitchChange.startDate, weekSwitchChange.endDate);
                  }

                  this.updateRows(data.passengers);
                }),
                finalize(() => {
                  this.isLoading = false;
                  this.isLoadingPassengers = false;

                  this.cdRef.markForCheck();
                })
              ),
            this.customerDataService.getCustomerData({ types: [ CustomerDataType.Branches ] })
              .pipe(
                tap(data => {
                  this.hasBranches = !!data.branches.length;
                })
              )
          ]);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  private openBulkAssignRow() {
    this.isBulkAssignOpen = true;
    this.tablePageService.stickyRow = this.generateBulkAssignRow();
  }

  private generateBulkAssignRow() {
    const bulkAssignRow = cloneDeep(this.isStudent ? this.config.bulkAssignRowStudent : this.config.bulkAssignRow);

    this.config.daysOfWeek.forEach(day => {
      bulkAssignRow[`dayOfWeek-${day}`] = cloneDeep(this.demandsCommonService.bulkShiftAssignments[day]);
    });

    return bulkAssignRow;
  }

  private closeBulkAssignRow() {
    this.isBulkAssignOpen = false;
    this.tablePageService.stickyRow = null;
    this.demandsCommonService.initBulkShiftAssignments();
    this.tablePageService.rows = [ ...this.tablePageService.rows ];
  }

  private duplicateShiftModal(startDate, endDate, row) {
    this.bsModalService.show(
      DemandsWeekDuplicateComponent,
      {
        class: 'u-modal u-modal_app-demands-duplicate',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          startDate,
          endDate,
          passenger: row,
          passengers: this.tablePageService.selectedRows
        }
      }
    )
      .content.action
      .pipe(first())
      .subscribe(({ type, value }) => {
        const duplicateWeekStartDate = value.duplicateDates[0];
        const duplicateWeekEndDate = value.duplicateDates[value.duplicateDates.length - 1];
        const params = {
          activeWeekStartDate: startDate,
          activeWeekEndDate: endDate,
          duplicateWeekStartDate,
          duplicateWeekEndDate,
          duplicateWeekActiveDays: value.duplicateDays
        };

        if (row.passengerId) {
          switch (type) {
            case DemandsWeekDuplicateActionType.Apply: {
              this.demandsPassengersViewService.duplicatePassengerShiftsWeek({ ...params, passengerId: row.passengerId })
                .pipe(first())
                .subscribe(res => this.openAssignedRoutesPopup(res.passengersRides));
              break;
            }

            case DemandsWeekDuplicateActionType.ApplyGoWeek: {
              this.demandsPassengersViewService.duplicatePassengerShiftsWeek({ ...params, passengerId: row.passengerId })
                .pipe(first())
                .subscribe(res => {
                  this.openAssignedRoutesPopup(res.passengersRides);
                  this.headerDataService.weekSwitchChangeSet({ startDate: duplicateWeekStartDate, endDate: duplicateWeekEndDate });
                });
              break;
            }
          }
        } else {
          switch (type) {
            case DemandsWeekDuplicateActionType.Apply: {
              this.demandsPassengersViewService.duplicateBulkPassengersShiftsWeek({ ...params, passengerIds: this.tablePageService.selectedRows.map(selectedRow => selectedRow.passengerId) })
                .pipe(first())
                .subscribe(res => this.openAssignedRoutesPopup(res.passengersRides));
              break;
            }

            case DemandsWeekDuplicateActionType.ApplyGoWeek: {
              this.demandsPassengersViewService.duplicateBulkPassengersShiftsWeek({ ...params, passengerIds: this.tablePageService.selectedRows.map(selectedRow => selectedRow.passengerId) })
                .pipe(first())
                .subscribe(res => {
                  this.openAssignedRoutesPopup(res.passengersRides);
                  this.headerDataService.weekSwitchChangeSet({ startDate: duplicateWeekStartDate, endDate: duplicateWeekEndDate });
                });
              break;
            }
          }
        }
      });
  }

  private updateColumns(totalCount: number, startDate: string, endDate: string) {
    const weekColumns = [];
    const weekDates = generateWeekDates(startDate, endDate);
    const today = moment().startOf('day');

    for (const dayOfWeek of this.config.daysOfWeek) {
      const date = moment(weekDates[dayOfWeek]).startOf('day');

      weekColumns.push({
        prop: `dayOfWeek-${dayOfWeek}`,
        propAlias: dayOfWeek,
        name: date.format(AppConstants.DATE_FORMAT_BASE_DOT_DDD_DD_MM),
        minWidth: 172,
        maxWidth: 172,
        resizeable: false,
        cellTemplate: this.dayCell,
        dayOfWeek: dayOfWeek,
        date: date.format(AppConstants.DATE_FORMAT_BASE_DOT_DD_MM),
        dateISO: date.format(AppConstants.DATE_FORMAT_ISO),
        clickble: true,
        highlight: date.isSame(today),
        headerClass: 'day-header-cell',
        cellClass: 'day-body-cell',
        sortable: false,
        headerFilterTemplate: this.dayCellHeader,
        filterType: 'select',
        filterTypeItemsFn: (prop, rows) => rows.reduce((accRows, row) =>
          accRows.concat(row[prop] && row[prop].length ? row[prop].reduce((acc, item) => acc = [ ...acc, item.shiftName || this.specialShiftText ], []) : [ '' ])
        , []),
        filterTypeItemsArraySearchFn: (value, searchValue) => value.some(ob => ob.shiftName && ob.shiftName.includes(searchValue) || ob.shiftName === null && searchValue === this.specialShiftText)
      });
    }

    if (!this.uDaysOrderService.sundayFirstDay()) {
      weekColumns.push(cloneDeep(weekColumns.splice(0, 1)[0]));
    }

    const config = { ...this.config };

    const columns = [
      ...this.attachTemplatesAndFunctionsToColumns(
        cloneDeep(demandsPassengersViewComponentConfig[this.featureType] || demandsPassengersViewComponentConfig.default).table.tableConfig.columns
          .filter(column => column.feature ? this.authDataService.checkFeature(column.feature) : true)
      ),
      ...weekColumns
    ];

    config.table.tableConfig.columns = totalCount > this.maxAmountPassengers ?
      columns.map(column => ({ ...column, filterType: undefined, sortable: false })) : columns;

    this.config = config;
  }

  private attachTemplatesAndFunctionsToColumns(columns) {
    if (columns) {
      columns.forEach(column => {
        column['cellTemplate'] = column.cellTemplateName && this[column.cellTemplateName] ? this[column.cellTemplateName] : column.cellTemplate;
        column['filterTemplate'] = column.filterTemplateName && this[column.filterTemplateName] ? this[column.filterTemplateName] : column.filterTemplate;
      });
    }

    return columns;
  }

  private getPassengersParams(): DemandsTableParams {
    const weekSwitchChange = this.headerDataService.getWeekSwitchChangeValue();

    return {
      departmentIds: [],
      cities: [],
      tagIds: [],
      branchIds: [],
      shiftIds: [],
      demandType: null,
      passengerIds: [],
      ...this.headerSearchFiltersService.getFiltersValue(),
      startDate: weekSwitchChange.startDate,
      endDate: weekSwitchChange.endDate,
      search: this.headerSearchFiltersService.getSearchValue(),
      skip: this.skip,
      take: this.maxAmountPassengers
    };
  }

  private updateRows(rows: DemandsTableRow[]) {
    const parsedRows = rows && rows.length ? rows.map(row => {
      const editedRow = omit({
        ...row,
        id: row.passengerId,
        ...Object.assign({}, ...this.config.daysOfWeek.map(dayOfWeek => ({ [`dayOfWeek-${dayOfWeek}`]: row.demands ? row.demands.filter(demand => demand.dayOfWeek === dayOfWeek) : row[`dayOfWeek-${dayOfWeek}`] || null })))
      }, [ 'demands' ]);

      if ('distanceToStation' in row) {
        editedRow.distanceToStation = row.distanceToStation !== null ? row.distanceToStation : (row.stationId ? PassengerDistanceToStation.NoValue : PassengerDistanceToStation.NoStation);
        editedRow.distanceToStationRange = row.distanceToStation !== null && this.getDistanceRange(row.distanceToStation);
      }

      return editedRow;
    }) : [];

    this.tablePageService.rows = this.skip > 0 ? [ ...this.tablePageService.rows, ...parsedRows ] : parsedRows;

    this.tablePageService.selectedRows = this.tablePageService.selectedRows.map(selectedRow => this.tablePageService.rows.find(row => row.id === selectedRow.id) || selectedRow);
  }

  private getDistanceRange(distance: number, params: { bottom: number; top: number; step: number } = { bottom: 100, top: 1000, step: 99 }): { between: number; to: number } {
    if (distance < params.bottom) {
      return { between: null, to: params.bottom };
    }

    if (distance > params.top) {
      return { between: params.top, to: null };
    }

    for (let i = params.bottom; i < params.top; i += (params.step + 1)) {
      if (distance < i + params.step + 1) {
        return { between: i, to: i + params.step };
      }
    }
  }

  getPassengers() {
    this.skip = 0;

    this.passengersGet.next(this.getPassengersParams());
  }

  columnsSelect(columns) {
    this.visibleColumns = columns;
  }

  exportToExcel() {
    if (this.tablePageService.selectedRows && this.tablePageService.selectedRows.length) {
      this.trackingService.track(`[${this.config.table.trackingId}] - Export to excel`);

      const { startDate, endDate } = this.headerDataService.getWeekSwitchChangeValue();

      this.bsModalService.show(
        DemandsExcelExportComponent,
        {
          class: 'u-modal u-modal_app-demands-excel-export',
          animated: true,
          ignoreBackdropClick: true,
          backdrop: true,
          keyboard: false,
          initialState: {
            startDate,
            endDate
          }
        }
      )
        .content
        .export
        .pipe(take(1))
        .subscribe(({ value }) => {
          const resColumns = [];
          const { dateFrom, dateTo } = value;
          const params = {
            columns: resColumns,
            activeDays: [],
            startDate: dateFrom,
            endDate: dateTo,
            departmentIds: [],
            cities: [],
            tagIds: [],
            branchIds: [],
            shiftIds: [],
            demandType: null,
            ...this.headerSearchFiltersService.getFiltersValue(),
            passengerIds: this.tablePageService.selectedRows.length ? this.tablePageService.selectedRows.map(item => item.passengerId) : [],
            search: this.headerSearchFiltersService.getSearchValue()
          };
          let fileName = null;

          this.translateService.get('passengers')
            .pipe(first())
            .subscribe(translation => {
              params.activeDays = this.visibleColumns.filter(column => column.prop.includes('dayOfWeek')).map(column => column.propAlias);

              this.visibleColumns
                .filter(item => !item.hideColumn && item.prop !== 'check' && item.prop !== 'filter' && !item.prop.includes('dayOfWeek'))
                .forEach(column => {
                  resColumns.push({ field: column.prop, alias: column.prop === 'branch' && this.isSoldier ? translation.table['base'] : translation.table[column.propAlias ? column.propAlias : column.prop] });

                  fileName = `${moment(dateTo).format(AppConstants.DATE_FORMAT_BASE_HYPHEN)} - ${moment(dateFrom).format(AppConstants.DATE_FORMAT_BASE_HYPHEN)} ${translation.excelFileName}.xlsx`;
                });

              this.demandsPassengersViewService.exportToExcel(params, fileName);
            });
        });
    }
  }

  addEditRow(passengerId?: number) {
    if (passengerId) {
      this.trackingService.track(`[${this.config.table.trackingId}] - Edit row clicked`);
    }

    (
      passengerId ?
        this.passengersDataService.editPassengerById(passengerId) : this.passengersDataService.openAddEditModal()
    )
      .pipe(
        switchMap(data => data.content.action),
        take(1),
        takeUntil(this.unsubscribe)
      )
      .subscribe(({ type }: { type: ModalActions; }) => {
        switch (type) {
          case ModalActions.Submit: {
            this.getPassengers();

            break;
          }

          case ModalActions.Delete: {
            this.tablePageService.deleteRow(passengerId);

            break;
          }
        }
      });
  }

  rowsSelected(event: { [key: string]: any }[]) {
    if (event.length > 1 && !this.isBulkAssignOpen) {
      this.openBulkAssignRow();
    } else if (event.length <= 1 && this.isBulkAssignOpen) {
      this.closeBulkAssignRow();
    }
  }

  onCloseIconClicked() {
    this.closeBulkAssignRow();
    this.tablePageService.clearSelectedRows();
    this.tablePageService.rows = [ ...this.tablePageService.rows ];
  }

  selectBulkAssignDotsItemAction(action: string, row: any) {
    const { startDate, endDate } = this.headerDataService.getWeekSwitchChangeValue();

    switch (action) {
      case 'deleteWeek':
        this.trackingService.track(`[${this.config.table.trackingId}] - row 3 dots - Delete full week`);

        forkJoin([ this.translateService.get(this.config.table.dictionary.deleteWeekConfirm), this.translateService.get(this.config.table.dictionary.passengers) ])
          .pipe(first())
          .subscribe(([ deleteConfirm, passengers ]) => {
            const message = deleteConfirm.replace('{{passengerName}}',
              row.passengerId ? `${row.firstName} ${row.lastName}` : `${this.tablePageService.selectedRows.length} ${passengers}`);

            this.popupService.showMessage(
              {
                message,
                yes: this.config.table.dictionary.yes,
                no: this.config.table.dictionary.no
              },
              () => {
                const params = {
                  activeWeekStartDate: startDate,
                  activeWeekEndDate: endDate
                };

                if (row.passengerId) {
                  this.demandsPassengersViewService.deletePassengerShiftsWeek({ ...params, passengerId: row.passengerId })
                    .pipe(first())
                    .subscribe(affectedPassengers => this.demandsCommonService.assignShiftUpdate([ affectedPassengers.passenger ], affectedPassengers.passengersRides));
                } else {
                  this.demandsPassengersViewService.deleteBulkPassengersShiftsWeek({
                    ...params,
                    passengerIds: this.tablePageService.selectedRows.map(selectedRow => selectedRow.passengerId)
                  })
                    .pipe(first())
                    .subscribe(affectedPassengers => {
                      this.demandsCommonService.assignShiftUpdate(affectedPassengers.passengers, affectedPassengers.passengersRides);
                      this.demandsCommonService.initBulkShiftAssignments();
                      this.openBulkAssignRow();
                    });
                }
              },
              null
            );
          });
        break;

      case 'duplicateWeek':
        this.trackingService.track(`[${this.config.table.trackingId}] - row 3 dots - Duplicate full week`);

        this.duplicateShiftModal(startDate, endDate, row);
        break;
    }
  }

  onScrollTable(scrollPosition: UGridScroll) {
    const demandsTableBody = this.demandsTable.nativeElement.querySelector('datatable-body');

    if (
      !this.isLoading &&
      scrollPosition.offsetY === demandsTableBody.scrollHeight - demandsTableBody.clientHeight &&
      this.tablePageService.rows.length !== this.tablePageService.totalCount
    ) {
      this.skip = this.tablePageService.rows.length;

      this.passengersGet.next(this.getPassengersParams());
    }
  }
}
