import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  HostBinding,
  inject,
  DestroyRef
} from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { forkJoin, concat } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  reduce,
  pairwise,
  startWith,
  filter,
  tap,
  take
} from 'rxjs/operators';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { UButtonSize, URangePreset } from '@shift/ulib';

import {
  TrackingService,
  HeaderDataService
} from '@app/shared/services';
import { AppConstants } from '@app/shared/constants';
import { AuthDataService } from '@app/auth/services';
import { AuthCustomer, AuthCustomerType, AuthModuleName, AuthModuleRoutesFeature, AuthModuleRoutesFeatureMandatoryField, AuthModuleRoutesTableFeature } from '@app/auth/models';
import { Errors } from '@app/shared/models';
import {
  RoutesChangeType,
  RoutesChangeCarTypeConfirmAction,
  RoutesChangeEmailSendType,
  RouteDailyRow,
  RoutesTransportationAiSuggestionsItem
} from '@app/routes/models';
import { RouteChangeValidationService, RoutesChangeDataService, RoutesCommonService, RoutesTableService } from '@app/routes/services';
import { routesConfig } from '@app/routes/configs';
import { routesChangeShuttleCompanyComponentConfig } from './routes-change-shuttle-company.component.config';

@Component({
  selector: 'app-routes-change-shuttle-company',
  templateUrl: './routes-change-shuttle-company.component.html',
  styleUrls: [ './routes-change-shuttle-company.component.scss', './routes-change-shuttle-company.component.rtl.scss' ]
})
export class RoutesChangeShuttleCompanyComponent implements OnInit {
  @Input() activeRide: RouteDailyRow;
  @Input() viewportElement: HTMLElement;
  @Input() aiSuggestion: RoutesTransportationAiSuggestionsItem;

  @HostBinding('class') hostClasses: string = 'routes-change-shuttle-company';

  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly authDataService = inject(AuthDataService);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly routesCommonService = inject(RoutesCommonService);
  private readonly trackingService = inject(TrackingService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly routeChangeValidationService = inject(RouteChangeValidationService);
  private readonly routesChangeDataService = inject(RoutesChangeDataService);

  private allowShuttleCompanyChange: boolean;

  config = cloneDeep(routesChangeShuttleCompanyComponentConfig);
  form: UntypedFormGroup;
  shuttleCompanies: any[] = [];
  drivers: any[] = [];
  cars: any[] = [];
  storage: {
    shuttleCompany: 0,
    car: 0,
    driver: 0,
    type: 2
  };
  isChangedSC = false;
  isChangedDriver = false;
  isChangedCar = false;
  isSCMandatory = false;
  shuttleCompanyIdIsChanged: boolean = false;
  isSCCustomer: boolean = false;
  availablePresets: URangePreset[] = [
    URangePreset.DisplayedDay,
    URangePreset.Today,
    URangePreset.ActiveWeekDay,
    URangePreset.FromTodayOn,
    URangePreset.FromTomorrowOn,
    URangePreset.FromCustomDayOn,
    URangePreset.All,
    URangePreset.Custom,
    URangePreset.UpcomingWeek
  ];
  routesChangeEmailSendType = RoutesChangeEmailSendType;
  authCustomer: AuthCustomer;
  hasSubShuttleCompany = false;
  authShuttleCompanyId = null;
  uButtonSize = UButtonSize;

  ngOnInit() {
    this.createForm();

    this.authDataService.userInfo$
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ customer, modules }) => {
        if (this.activeRide) {
          const routesMandatoryFields = modules[AuthModuleName.Routes] && modules[AuthModuleName.Routes][AuthModuleRoutesFeature.MandatoryFields];

          this.isSCMandatory = !!routesMandatoryFields && routesMandatoryFields.includes(AuthModuleRoutesFeatureMandatoryField.ShuttleCompany);
          this.hasSubShuttleCompany = !!(this.activeRide.subShuttleCompany && this.activeRide.subShuttleCompany.value);
          this.authCustomer = customer;
          this.authShuttleCompanyId = customer.type === AuthCustomerType.ShuttleCompany ? customer.customerId : customer.selfShuttleCompanyId;
          this.isSCCustomer = customer.type === AuthCustomerType.ShuttleCompany;
          this.allowShuttleCompanyChange = !!modules?.[AuthModuleName.RoutesTable]?.[AuthModuleRoutesTableFeature.ShuttleCompanyChange];

          this.updateFormValidators();
          this.updateChanges(this.activeRide);
          this.updateCheckDays(this.activeRide);
          this.updateSCFieldsState();
        }
      });

    const scForm = this.form.get('shuttleCompanyId');
    const driverForm = this.form.get('driverId');
    const carForm = this.form.get('carId');

    scForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((id: any) => {
        if (!id) {
          driverForm.patchValue(null);
          carForm.patchValue(null);
          this.drivers = [];
          this.cars = [];

          this.isChangedSC = this.checkIsChanged(this.storage.shuttleCompany, id);

          return;
        }

        this.getData(id);
        this.isChangedSC = this.checkIsChanged(this.storage.shuttleCompany, id);
        this.shuttleCompanyIdIsChanged = this.checkIsChanged(this.storage.shuttleCompany, id);
        driverForm.patchValue(null);
        carForm.patchValue(null);

        this.updateSCFieldsState();
      });

    driverForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.storage.driver),
        pairwise()
      )
      .subscribe(([ prevId, nextId ]:  [ number, number ]) => {
        if (!nextId) {
          const driver = this.drivers.find(obj => obj.value === prevId);

          if (carForm && driver && carForm.value && carForm.value === driver.carId) {
            carForm.patchValue(null);

            this.isChangedDriver = this.checkIsChanged(this.storage.driver, nextId);

            return;
          }
        }

        this.isChangedDriver = this.checkIsChanged(this.storage.driver, nextId);

        this.autoAssignCarIfAvailable(nextId);
      });

    carForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.storage.car),
        pairwise()
      )
      .subscribe(([ prevId, nextId ]:  [ number, number ]) => {
        if (!nextId) {
          const driverObj = this.drivers.find(driver => driver.carId === prevId);
          const driverIdForm = this.form.get('driverId');

          if (driverIdForm && driverObj && driverIdForm.value && driverIdForm.value === driverObj.value) {
            driverIdForm.patchValue(null);

            this.isChangedDriver = this.checkIsChanged(this.storage.car, nextId);

            return;
          }
        }

        this.isChangedCar = this.checkIsChanged(this.storage.car, nextId);
        this.autoAssignDriverIfAvailable(nextId);
      });

    const typeForm = this.form.get('type');

    typeForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number) => {
        const { shuttleCompany, car, driver, type } = this.storage;
        const { shuttleCompanyId, carId, driverId } = this.form.value;

        if (value !== type) {
          this.isChangedSC = shuttleCompany ? true : this.checkIsChanged(shuttleCompany, shuttleCompanyId);
          this.isChangedDriver = driver ? true : this.checkIsChanged(driver, driverId);
          this.isChangedCar = car ? true : this.checkIsChanged(car, carId);
        }
      });
  }

  get changesForm(): UntypedFormGroup {
    return this.form.get('datesChange') as UntypedFormGroup;
  }

  get isChanges(): boolean {
    return this.isChangedSC || this.isChangedCar || this.isChangedDriver;
  }

  private updateSCFieldsState() {
    const scIdForm = this.form.get('shuttleCompanyId');
    const driverIdForm = this.form.get('driverId');
    const carIdForm = this.form.get('carId');

    if (scIdForm.value === this.authShuttleCompanyId) {
      driverIdForm.enable();
      carIdForm.enable();
    } else {
      driverIdForm.disable();
      carIdForm.disable();
    }

    if (!this.allowShuttleCompanyChange) {
      scIdForm.disable();
    }
  }

  private autoAssignCarIfAvailable(driverId: number): void {
    const driver = this.drivers.find(obj => obj.value === driverId);

    if (driver?.carId) {
      this.form.get('carId').patchValue(driver.carId);
    }
  }

  private autoAssignDriverIfAvailable(carId: number): void {
    if (carId && !this.form.get('driverId').value) {
      const driverAssignedToCarObj = this.drivers.find(driver => driver.carId === carId);

      if (driverAssignedToCarObj) {
        this.form.get('driverId').patchValue(driverAssignedToCarObj.value);
      }
    }
  }

  checkIsChanged(value: number, id: number) {
    return value !== id;
  }

  createForm() {
    this.form = this.fb.group({
      routeId: [ 0 ],
      shuttleCompanyId: [ null ],
      driverId: [ null ],
      carId: [ null ],
      datesChange: this.fb.group({
        dates: [ [] ],
        dateFrom: [ '' ],
        dateTo: [ '' ],
        type: [ this.headerDataService.isTodayActiveDate() ? URangePreset.Today : URangePreset.DisplayedDay ],
        availablePresets: [ this.availablePresets ],
        checkDaysActive: [ [] ],
        checkDaysAvailable: [ [] ]
      }),
      type: [ RoutesChangeType.Planned, Validators.required ],
      comment: [ '' ]
    });
  }

  updateFormValidators() {
    if (this.isSCMandatory) {
      this.form.get('shuttleCompanyId').setValidators(Validators.required);
    }
  }

  updateChanges(data: any) {
    const shuttleCompany = this.hasSubShuttleCompany ? data.subShuttleCompany : data.shuttleCompany;

    this.form.patchValue({
      routeId: data.routeId,
      shuttleCompanyId: shuttleCompany ? shuttleCompany.value : null,
      driverId: data.driver ? data.driver.value : null,
      carId: data.car ? data.car.value : null
    });

    this.storage = {
      shuttleCompany: this.form.get('shuttleCompanyId').value,
      car: this.form.get('carId').value,
      driver: this.form.get('driverId').value,
      type: this.form.get('type').value
    };

    if (this.aiSuggestion) {
      this.form.patchValue({
        shuttleCompanyId: this.aiSuggestion.shuttleCompany?.shuttleCompanyId ?? this.storage.shuttleCompany,
        driverId: this.aiSuggestion.driver?.driverId ?? this.storage.driver,
        carId: this.aiSuggestion.vehicle?.carId ?? this.storage.car
      });

      this.isChangedSC = this.checkIsChanged(this.storage.shuttleCompany, this.form.get('shuttleCompanyId').value);
      this.isChangedCar = this.checkIsChanged(this.storage.car, this.form.get('carId').value);
      this.isChangedDriver = this.checkIsChanged(this.storage.driver, this.form.get('driverId').value);
    }

    const supplierId = this.form.get('shuttleCompanyId').value;

    this.getShuttleCompanies();

    if (supplierId) {
      this.getData(supplierId);
    }
  }

  getShuttleCompanies() {
    this.routesTableService.getShuttleList(this.activeRide.rideId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(data => data.success)
      )
      .subscribe(data => this.shuttleCompanies = data.value);
  }

  getData(supplierId: number) {
    if (supplierId === this.authShuttleCompanyId) {
      forkJoin([
        this.routesTableService.getDriversList(),
        this.routesTableService.getCarsList()
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(([ resDrivers, resCars ]) => {
          if (resDrivers.success && resDrivers.value) {
            this.drivers = resDrivers.value;
          }

          if (resCars.success && resCars.value) {
            this.cars = resCars.value.map(car => ({ value: car.value, name: `${car.name} (${car.carTypeKeyword})` }));
          }
        });
    } else {
      this.drivers = [];
      this.cars = [];
    }
  }

  updateCheckDays(route) {
    const { routeStartDate, routeEndDate, days } = route;
    const activeDate = this.headerDataService.getActiveDate();

    this.changesForm.patchValue({
      dates: [ activeDate ],
      dateFrom: routeStartDate,
      dateTo: routeEndDate,
      checkDaysActive: [ moment(activeDate).get('day') ],
      checkDaysAvailable: days
    });
  }

  updatePeriod(data: any) {
    this.changesForm.patchValue({
      dates: data.dates,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      type: data.type,
      checkDaysActive: data.checkDaysActive,
      checkDaysAvailable: data.checkDaysAvailable
    });

    this.isChangedSC = true;
    this.isChangedDriver = true;
    this.isChangedCar = true;

    this.cdRef.detectChanges();
  }

  saveChanges(emailSendType?: RoutesChangeEmailSendType) {
    const { shuttleCompanyId, driverId, carId, routeId, type, comment } = this.form.getRawValue();
    const dates = this.changesForm.get('dates').value;
    const requests = [];
    const body = {
      routeId,
      activeDate: moment(this.headerDataService.getDate(), AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_TIME_LINE),
      sendBackgroundEmail: false,
      generateEditableEmail: false,
      value: {
        type,
        comment,
        dateFrom : moment(dates[0]).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
        dateTo: moment(dates[dates.length - 1]).startOf('day').format(AppConstants.DATE_FORMAT_ISO),
        days: this.changesForm.get('checkDaysActive').value,
        updateShuttleCompany: false,
        updateDriver: false,
        updateCar: false,
        shuttleCompanyId,
        driverId,
        carId
      }
    };

    if (emailSendType) {
      body[emailSendType] = true;
    }

    if (this.isChangedSC) {
      if (this.typeOfChanges() === 1) {
        this.trackingService.track('[Track daily view change] - fixed change - transportation company changed');
      }

      if (this.typeOfChanges() === 2) {
        this.trackingService.track('[Track daily view change] - temp change - transportation company changed');
      }

      if (shuttleCompanyId) {
        body.value.updateShuttleCompany = this.authCustomer.type === AuthCustomerType.ShuttleCompany ? shuttleCompanyId !== this.authShuttleCompanyId : true;
      } else {
        requests.push(this.routesTableService.changeRemoveShuttleCompany({
          ...body,
          value: {
            type: body.value.type,
            comment: body.value.comment,
            dateFrom: body.value.dateFrom,
            dateTo: body.value.dateTo,
            days: body.value.days,
            shuttleCompanyId: this.activeRide && this.activeRide.shuttleCompany ? this.activeRide.shuttleCompany.value : null
          }
        }));

        this.isChangedSC = false;
        this.isChangedCar = false;
        this.isChangedDriver = false;
      }
    }

    if (this.isChangedDriver && !this.form.get('driverId').disabled) {
      body.value.updateDriver = true;

      this.isChangedCar = true;

      if (this.typeOfChanges() === 1) {
        this.trackingService.track('[Track daily view change] - fixed change - driver changed');
      }

      if (this.typeOfChanges() === 2) {
        this.trackingService.track('[Track daily view change] - temp change - driver changed');
      }
    }

    if (this.isChangedCar && !this.form.get('carId').disabled) {
      body.value.updateCar = true;

      if (this.typeOfChanges() === 1) {
        this.trackingService.track('[Track daily view change] - fixed change - car changed');
      }

      if (this.typeOfChanges() === 2) {
        this.trackingService.track('[Track daily view change] - temp change - car changed');
      }
    }

    if (this.isChangedSC || this.isChangedCar || this.isChangedDriver) {
      if (shuttleCompanyId === this.authShuttleCompanyId) {
        this.routeChangeValidationService.validationCar(
          {
            routeId,
            value: {
              dateFrom: body.value.dateFrom,
              dateTo: body.value.dateTo,
              days: body.value.days,
              shuttleCompanyId: body.value.shuttleCompanyId,
              carId: body.value.carId,
              type: body.value.type
            }
          }
        )
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
            () => this.updateChangedRoute([ ...requests, this.routesTableService.changeShuttleSettings(body) ], routeId),
            error => {
              if (error.code === Errors.VehicleSubceedsCarTypeCapacity) {
                this.routesChangeDataService.changeCarTypeConfirm(error.description, routesConfig.trackingId)
                  .pipe(
                    take(1),
                    takeUntilDestroyed(this.destroyRef),
                    tap(action => {
                      if (action === RoutesChangeCarTypeConfirmAction.Ok) {
                        this.updateChangedRoute([
                          ...requests,
                          this.routesTableService.changeShuttleSettings({
                            ...body,
                            value: {
                              ...body.value,
                              allowSubceedCarTypeCapacity: true
                            } })
                        ], routeId);
                      }
                    })
                  ).subscribe();
              }
            }
          );
      } else {
        this.updateChangedRoute([ ...requests, this.routesTableService.changeShuttleSettings(body) ], routeId);
      }
    }

    if (requests.length === 0) { return; }

    this.updateChangedRoute(requests, routeId);
  }

  updateChangedRoute(requests, routeId) {
    concat(...requests)
      .pipe(
        reduce((acc: any, ob: any) => [ ...acc, ob ], []),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.routesCommonService.updateChangedRoute());
  }

  typeOfChanges(): number {
    return this.form.controls['type'].value;
  }
}
