@if (form) {
  @for (contactForm of form.controls; track contactForm; let i = $index; let last = $last;) {
    <u-group
      class="u-group_large-field u-group_app-input-communications u-group_flex input-communications__group"
      [formGroup]="contactForm"
      [name]="showTitle ? (config.dictionary.communications | translate) : null"
    >
      <div class="input-communications__container">
        <div class="input-communications__item">
          <u-select-s
            formControlName="type"
            [uId]="'contact-type'"
            [items]="config.contactTypes"
            [messages]="config.dictionary.uSelectS | translate"
            [placement]="isRtl ? [ 'bottom-right', 'top-right' ] : [ 'bottom-left', 'top-left' ]"
            [valid]="(contactForm.disabled || contactForm.get('type').disabled) ? true : contactForm.get('type').valid"
            [emptyName]="config.dictionary.select | translate"
            [emptyRemove]="config.dictionary.remove | translate"
            [placeholder]="config.dictionary.select | translate"
            [container]="'body'"
            [classUDropdownContent]="'u-dropdown-content_app-default'"
          ></u-select-s>
        </div>
        <div class="input-communications__item">
          @if (contactForm.get('value')) {
            <input
              uInput
              type="text"
              formControlName="value"
              placeholder="{{contactTypeNames[contactForm.value['type']] | translate}}"
              uId="contact-value"
              [valid]="!contactForm.get('value').pending && !contactForm.disabled ? contactForm.get('value').valid : true"
              (change)="onCommunicationChange($event.target.value, i)"
            >
          }

          @if (contactForm.get('contact')) {
            <input
              uInput
              type="text"
              formControlName="contact"
              placeholder="{{contactTypeNames[contactForm.value['type']] | translate}}"
              uId="contact-value"
              [valid]="!contactForm.get('contact').pending && !contactForm.disabled ? contactForm.get('contact').valid : true"
              (change)="onCommunicationChange($event.target.value, i)"
            >
          }
        </div>
      </div>
      <div class="input-communications__actions">
        @if ((!last || i > 0 || showRemoveIconInLastContact) && !contactForm.get('type').disabled) {
          <div
            class="input-communications__actions-btn input-communications__actions-btn_remove"
            [uId]="'input-communications-remove-contact'"
            (click)="removeContact(i)"
          ></div>
        }

        @if (last && showAddIcon) {
          <div
            class="input-communications__actions-btn input-communications__actions-btn_add"
            [uId]="'input-communications-add-contact'"
            (click)="addContact()"
          >
            +
          </div>
        }
      </div>
    </u-group>
  }
}
