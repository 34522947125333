import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { SignalRService } from '@app/shared/services';
import { RoutesAiRouteUpdated, RoutesAiTransportationSuggestionsStatusChanged } from '@app/routes/models';

@Injectable({
  providedIn: 'root'
})
export class RoutesAiTransportationSuggestionsHub extends SignalRService {
  BASE_PATH = 'aiTransportationSuggestionsHub';

  constructor(
    oAuthService: OAuthService
  ) {
    super(oAuthService);
  }

  async initialize(guid: string) {
    await this.stop();

    this.init(`?guid=${guid}`);

    await this.start();
  }

  onAiTransportationSuggestionsStatusChanged() {
    return this.onChannel<RoutesAiTransportationSuggestionsStatusChanged>('AiTransportationSuggestionsStatusChanged');
  }

  onRouteUpdated() {
    return this.onChannel<RoutesAiRouteUpdated>('RouteUpdated');
  }
}
