<div #agenda class="builder-agenda__main">
  <div class="builder-agenda__wrapper">
    <div class="builder-agenda__week">
      <div class="builder-agenda__week-column builder-agenda__week-column_names">
        <div class="builder-agenda__week-row builder-agenda__week-row_days builder-agenda__week-row_calendar">
          <div class="builder-agenda__week-row-calendar" *ngIf="builderDataConfig.buildMode === builderBuildMode.Routes && builderDataConfig.mode === builderMode.Edit">
            <u-input-date
              [class]="'u-input-date_blue'"
              [mode]="'icon-only'"
              [disableDaysBefore]="dateWeek.disableDaysBefore"
              [disableDaysAfter]="dateWeek.disableDaysAfter"
              [(ngModel)]="dateWeek.date"
              [placement]="isRtl ? ['bottom-right' ] : ['bottom-left']"
              [messages]="config.dictionary.uInputDate | translate"
              (ngModelChange)="dateWeekChange()"
              (click)="dateWeekClick()"
            ></u-input-date>
          </div>
          <div class="builder-agenda__week-row-name" *ngIf="builderDataConfig.buildMode === builderBuildMode.Routes && builderDataConfig.mode === builderMode.Edit">{{year()}}</div>
        </div>

        <!-- Days time -->
        <div class="builder-agenda__week-row builder-agenda__week-row_bold">{{config.dictionary.ride.startTime | translate}}</div>
        <div class="builder-agenda__week-row builder-agenda__week-row_bold">{{config.dictionary.ride.endTime | translate}}</div>

        <!-- SC -->
        @if (builderDataConfig.buildMode !== builderBuildMode.RouteTemplates) {
          <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.contract | translate}}</div>
        }

        <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.costCalc | translate}}</div>

        @if (isHashcalRideTypes()) {
          <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.hashcalRideType | translate}}</div>
        }

        <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.rideCost | translate}}</div>
        <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.carType | translate}}</div>
        <div class="builder-agenda__week-row">
          {{(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.ride.sc.customerCost : config.dictionary.ride.sc.totalCost) | translate}}
        </div>

        <!-- Executing SC -->
        <div class="builder-agenda__week-row builder-agenda__week-row_bold builder-agenda__week-row_sc">
          <div class="builder-agenda__week-row-toggle">
            <div
              class="u-icon u-icon_pointer u-icon_app-builder-arrow-toggle-top"
              [ngClass]="{
                'u-icon_app-builder-arrow-toggle-top_left': !sc 
              }"
              (click)="scToggle()"
            ></div>
          </div>
          <div class="builder-agenda__week-row-name">
            {{(routeBuilderFeatureTypeShuttleCompany() ? config.dictionary.ride.sc.executingSc : config.dictionary.ride.sc.name) | translate}}
          </div>
        </div>

        @if (sc) {
          <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.carNo | translate}}</div>
          <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.carDriver | translate}}</div>

          @if (routeBuilderFeatureTransportAdditionalFieldsDriverHours()) {
            <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.driverHours | translate}}</div>
          }

          <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.subContractorDriverCost | translate}}</div>

          @if (customerIsOwnedByScAndSupervisorModuleStatusActive() && builderDataConfig.buildMode === builderBuildMode.Routes && builderDataConfig.mode === builderMode.Edit) {
            @if (customerSupervisorModuleStatusFeatureTypeGeneric()) {
              <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.supervisor | translate}}</div>
            }

            @if (customerSupervisorModuleStatusFeatureTypeCommander()) {
              <div class="builder-agenda__week-row">{{config.dictionary.ride.sc.commander | translate}}</div>
            }
          }
        }

        <!-- ACC -->
        <div
          class="builder-agenda__week-row builder-agenda__week-row_bold builder-agenda__week-row_acc"
          *ngIf="!!accompanyFeatureType()"
        >
          <div class="builder-agenda__week-row-toggle">
            <div
              class="u-icon u-icon_pointer u-icon_app-builder-arrow-toggle-top"
              [ngClass]="{
                'u-icon_app-builder-arrow-toggle-top_left': !acc 
              }"
              (click)="accToggle()"
            ></div>
          </div>
          <div class="builder-agenda__week-row-name">{{config.dictionary.ride.acc.name | translate}}</div>
        </div>
        <div
          class="builder-agenda__week-row"
          *ngIf="acc && !!accompanyFeatureType()"
        >
          {{config.dictionary.ride.acc.costCalc | translate}}
        </div>
        <div
          class="builder-agenda__week-row"
          *ngIf="acc && !!accompanyFeatureType()"
        >
          {{config.dictionary.ride.acc.hours | translate}}
        </div>
        <div
          class="builder-agenda__week-row"
          *ngIf="acc && !!accompanyFeatureType()"
        >
          {{config.dictionary.ride.acc.cost | translate}}
        </div>
        <div
          class="builder-agenda__week-row"
          *ngIf="acc && !!accompanyFeatureType()"
        >
          {{config.dictionary.ride.acc.totalCost | translate}}
        </div>
      </div>
      <div class="builder-agenda__week-columns">
        <div
          class="builder-agenda__week-column"
          *ngFor="let weekDay of week; trackBy: weekElement"
          [ngClass]="{
            'builder-agenda__week-column_inactive': !weekDay.active || weekDay.ride.isCancelled 
          }"
        >
          <div
            class="builder-agenda__week-row builder-agenda__week-row_days"
            [ngClass]="{
              'builder-agenda__week-row_bold': weekDay.date && dateOfWeekIsToday(weekDay.date)
            }"
          >
            <span class="builder-agenda__week-row-day">{{dayOfWeekShort(weekDay.dayOfWeek)}}</span>
            <span
              class="builder-agenda__week-row-date"
              *ngIf="builderDataConfig.mode === 'edit' && weekDay.date"
            >
              {{dateOfWeek(weekDay.date)}}
            </span>
            <div
              class="builder-agenda__week-row-canceled"
              *ngIf="weekDay.ride.isCancelled"
            >
              {{config.dictionary.cancelled | translate}}
            </div>
            <div
              *ngIf="builderDataService.createOrEditRoutesAllowed()"
              class="builder-agenda__week-row-duplicate builder-agenda__week-row-duplicate_days"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                *ngIf="!weekDay.ride.isCancelled"
                (click)="duplicate('all', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>

          <!-- Days time -->
          <div class="builder-agenda__week-row">
            <u-input-time
              [class]="'u-input-time_center'"
              [defaultTime]="false"
              [disabled]="weekDay.ride.rideCalculationMode === builderCalculationMode.Manual || !builderDataService.createOrEditRoutesAllowed()"
              [(ngModel)]="weekDay.ride.startDateTime"
              (ngModelChange)="editStartTime(weekDay)"
            ></u-input-time>
            <div
              class="builder-agenda__week-row-duplicate"
              *ngIf="weekDay.ride.timeType === 1 && weekDay.ride.rideCalculationMode !== builderCalculationMode.Manual && builderDataService.createOrEditRoutesAllowed()"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('startTime', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
          <div class="builder-agenda__week-row">
            <u-input-time
              [class]="'u-input-time_center'"
              [defaultTime]="false"
              [disabled]="weekDay.ride.rideCalculationMode === builderCalculationMode.Manual || !builderDataService.createOrEditRoutesAllowed()"
              [(ngModel)]="weekDay.ride.endDateTime"
              (ngModelChange)="editEndTime(weekDay)"
            ></u-input-time>
            <div
              class="builder-agenda__week-row-duplicate"
              *ngIf="weekDay.ride.timeType === 2 && weekDay.ride.rideCalculationMode !== builderCalculationMode.Manual && builderDataService.createOrEditRoutesAllowed()"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('endTime', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>

          <!-- SC -->
          @if (builderDataConfig.buildMode !== builderBuildMode.RouteTemplates) {
            <div class="builder-agenda__week-row">
              <u-select-s
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [empty]="true"
                [items]="weekDay.options.transport.contracts"
                [placeholder]="config.dictionary.search | translate"
                [messages]="config.dictionary.uSelectS | translate"
                [disabled]="!weekDay.ride.isAnyTargetStation || (routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) || !builderDataService.createOrEditRoutesAllowed()"
                [(ngModel)]="weekDay.ride.transport.contractId"
                (ngModelChange)="editContract(weekDay)"
              />

              @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
                <div class="builder-agenda__week-row-duplicate">
                  <div
                    class="builder-agenda__week-row-duplicate-text"
                    (click)="duplicate('contractSet', weekDay)"
                  >
                    {{config.dictionary.duplicate | translate}}
                  </div>
                </div>
              }
            </div>
          }

          <div class="builder-agenda__week-row">
            <u-select-s
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [empty]="false"
              [items]="weekDay.options.transport.costTypes"
              [(ngModel)]="weekDay.ride.transport.costType"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              [disabled]="!weekDay.ride.isAnyTargetStation || (routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) || !builderDataService.createOrEditRoutesAllowed()"
              (ngModelChange)="editCost(weekDay)"
            />

            @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
              <div class="builder-agenda__week-row-duplicate">
                <div
                  class="builder-agenda__week-row-duplicate-text"
                  (click)="duplicate('cost', weekDay)"
                >
                  {{config.dictionary.duplicate | translate}}
                </div>
              </div>
            }
          </div>

          @if (isHashcalRideTypes()) {
            <div class="builder-agenda__week-row">
              <u-select-s
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [empty]="false"
                [items]="weekDay.options.transport.hashcalRideTypes?.[weekDay.ride.transport.costType]"
                [(ngModel)]="weekDay.ride.transport.hashcalRideType"
                [placeholder]="config.dictionary.search | translate"
                [messages]="config.dictionary.uSelectS | translate"
                [disabled]="
                  !weekDay.options.transport.hashcalRideTypes?.[weekDay.ride.transport.costType]?.length ||
                  (routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) ||
                  !builderDataService.createOrEditRoutesAllowed()
                "
                (ngModelChange)="editHashcalRideType(weekDay)"
              />

              @if (builderDataService.createOrEditRoutesAllowed()) {
                <div class="builder-agenda__week-row-duplicate">
                  <div
                    class="builder-agenda__week-row-duplicate-text"
                    (click)="duplicate('hashcalRideType', weekDay)"
                  >
                    {{config.dictionary.duplicate | translate}}
                  </div>
                </div>
              }
            </div>
          }

          <div class="builder-agenda__week-row">
            <input
              uInput
              type="text"
              [mask]="'0*.0*'"
              [dropSpecialCharacters]="false"
              [disabled]="
                !weekDay.ride.isAnyTargetStation ||
                (autoCalcSCCostTypes | includes: weekDay.ride.transport.costType) ||
                weekDay.ride.transport.costType === null ||
                (routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) ||
                !builderDataService.createOrEditRoutesAllowed()
              "
              [(ngModel)]="weekDay.ride.transport.cost"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="editCost(weekDay)"
            >

            @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
              <div class="builder-agenda__week-row-duplicate">
                <div
                  class="builder-agenda__week-row-duplicate-text"
                  (click)="duplicate('cost', weekDay)"
                >
                  {{config.dictionary.duplicate | translate}}
                </div>
              </div>
            }
          </div>

          <div class="builder-agenda__week-row">
            <u-select-s
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [emptyName]="config.dictionary.select | translate"
              [emptyValue]="null"
              [emptyRemove]="config.dictionary.removeSelect | translate"
              [items]="weekDay.options.transport.carTypeIds"
              [(ngModel)]="weekDay.ride.transport.carTypeId"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              [disabled]="(routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) || !builderDataService.createOrEditRoutesAllowed()"
              (ngModelChange)="editCarType(weekDay)"
            />

            @if (builderDataService.createOrEditRoutesAllowed()) {
              <div class="builder-agenda__week-row-duplicate">
                <div
                  class="builder-agenda__week-row-duplicate-text"
                  (click)="duplicate('carType', weekDay)"
                >
                  {{config.dictionary.duplicate | translate}}
                </div>
              </div>
            }
          </div>

          <div class="builder-agenda__week-row">
            <input
              uInput
              type="text"
              [mask]="'0*.0*'"
              [dropSpecialCharacters]="false"
              [ngModel]="weekDay.ride.transport.totalCost"
              [disabled]="true"
            >
          </div>

          <!-- Executing SC -->
          <div class="builder-agenda__week-row builder-agenda__week-row_sc">
            <u-select-s
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [emptyName]="config.dictionary.select | translate"
              [emptyValue]="null"
              [emptyRemove]="config.dictionary.removeSelect | translate"
              [items]="options.shuttleCompanies"
              [(ngModel)]="weekDay.ride.transport.shuttleCompanyId"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              [disabled]="!builderDataService.createOrEditRoutesAllowed()"
              (ngModelChange)="editShuttleCompany(weekDay)"
            />

            @if (builderDataService.createOrEditRoutesAllowed()) {
              <div class="builder-agenda__week-row-duplicate">
                <div
                  class="builder-agenda__week-row-duplicate-text"
                  (click)="duplicate('shuttleCompany', weekDay)"
                >
                  {{config.dictionary.duplicate | translate}}
                </div>
              </div>
            }
          </div>

          @if (sc) {
            <div class="builder-agenda__week-row">
              <u-select-s
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [emptyName]="config.dictionary.select | translate"
                [emptyValue]="null"
                [emptyRemove]="config.dictionary.removeSelect | translate"
                [items]="weekDay.options.transport.carIds"
                [(ngModel)]="weekDay.ride.transport.carId"
                [placeholder]="(weekDay.ride.transport.vehicle | builderVehicleNumber) || (config.dictionary.search | translate)"
                [messages]="config.dictionary.uSelectS | translate"
                [disabled]="weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId() || !builderDataService.createOrEditRoutesAllowed()"
                (ngModelChange)="editCar(weekDay)"
              />

              @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
                <div class="builder-agenda__week-row-duplicate">
                  <div
                    class="builder-agenda__week-row-duplicate-text"
                    (click)="duplicate('car', weekDay)"
                  >
                    {{config.dictionary.duplicate | translate}}
                  </div>
                </div>
              }
            </div>

            <div class="builder-agenda__week-row">
              <u-select-s
                [container]="'body'"
                [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
                [emptyName]="config.dictionary.select | translate"
                [emptyValue]="null"
                [emptyRemove]="config.dictionary.removeSelect | translate"
                [items]="weekDay.options.transport.driverIds"
                [(ngModel)]="weekDay.ride.transport.driverId"
                [placeholder]="(weekDay.ride.transport.driver | builderDriverName : weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) || (config.dictionary.search | translate)"
                [disabled]="weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId() || !builderDataService.createOrEditRoutesAllowed()"
                [messages]="config.dictionary.uSelectS | translate"
                (ngModelChange)="editDriver(weekDay)"
              />

              @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
                <div class="builder-agenda__week-row-duplicate">
                  <div
                    class="builder-agenda__week-row-duplicate-text"
                    (click)="duplicate('driver', weekDay)"
                  >
                    {{config.dictionary.duplicate | translate}}
                  </div>
                </div>
              }
            </div>

            @if (routeBuilderFeatureTransportAdditionalFieldsDriverHours()) {
              <div class="builder-agenda__week-row">
                <u-input-time
                  [class]="'u-input-time_center'"
                  [defaultTime]="false"
                  [(ngModel)]="weekDay.ride.transport.driverHours"
                  [disabled]="(routeBuilderFeatureTypeShuttleCompany() && weekDay.ride.transport.shuttleCompanyId !== routeBuilderAuthShuttleCompanyId()) || !builderDataService.createOrEditRoutesAllowed()"
                  [min]="'00:01'"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (ngModelChange)="editDriverHours(weekDay)"
                />

                @if (weekDay.ride.transport.shuttleCompanyId && builderDataService.createOrEditRoutesAllowed()) {
                  <div class="builder-agenda__week-row-duplicate">
                    <div
                      class="builder-agenda__week-row-duplicate-text"
                      (click)="duplicate('driverHours', weekDay)"
                    >
                      {{config.dictionary.duplicate | translate}}
                    </div>
                  </div>
                }
              </div>
            }

            <div class="builder-agenda__week-row">
              <input
                uInput
                type="text"
                [mask]="'0*.0*'"
                [dropSpecialCharacters]="false"
                [ngModel]="weekDay.ride.assignedScExecutionCost"
                [disabled]="true"
              >
            </div>
          }

          <div
            *ngIf="
              sc && customerIsOwnedByScAndSupervisorModuleStatusActive() && builderDataConfig.buildMode === builderBuildMode.Routes &&
              builderDataConfig.mode === builderMode.Edit
            "
            class="builder-agenda__week-row"
          >
            <u-select-s
              [alphabeticalSort]="true"
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [emptyName]="config.dictionary.select | translate"
              [emptyValue]="null"
              [emptyRemove]="config.dictionary.removeSelect | translate"
              [items]="rideSupervisors"
              [disabled]="!builderDataService.createOrEditRoutesAllowed()"
              [(ngModel)]="weekDay.ride.supervisorId"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              (ngModelChange)="changeSupervisor($event, weekDay)"
            ></u-select-s>
          </div>

          <!-- ACC -->
          <div
            class="builder-agenda__week-row builder-agenda__week-row_acc"
            *ngIf="!!accompanyFeatureType()"
          >
            <u-select-s
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [emptyValue]="'required'"
              [emptyName]="config.dictionary.required | translate"
              [emptyRemove]="config.dictionary.removeSelect | translate"
              [items]="options.accompaniesWithoutRequired"
              [(ngModel)]="weekDay.ride.accompany.accompanyId"
              [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              (ngModelChange)="editAccompanyId(weekDay)"
            ></u-select-s>
            <div
              *ngIf="builderDataService.assignAccompanyToRidesAllowed()"
              class="builder-agenda__week-row-duplicate"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('accompanySet', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
          <div
            class="builder-agenda__week-row"
            *ngIf="acc && !!accompanyFeatureType()"
          >
            <u-select-s
              [container]="'body'"
              [placement]="isRtl ? [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ] : [ 'bottom-right', 'bottom-left', 'top-right', 'top-left' ]"
              [emptyName]="config.dictionary.select | translate"
              [emptyValue]="null"
              [emptyRemove]="config.dictionary.removeSelect | translate"
              [emptyHidden]="true"
              [items]="weekDay.ride.accompany.accompanyId !== 'none' ? options.costAccompanyFull : []"
              [(ngModel)]="weekDay.ride.accompany.costType"
              [disabled]="!builderDataService.assignAccompanyToRidesAllowed()"
              [placeholder]="config.dictionary.search | translate"
              [messages]="config.dictionary.uSelectS | translate"
              (ngModelChange)="editAccompanyCostType(weekDay); editAccompanyCost(weekDay)"
            ></u-select-s>
            <div
              *ngIf="builderDataService.assignAccompanyToRidesAllowed()"
              class="builder-agenda__week-row-duplicate"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('accompanyCost', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
          <div
            class="builder-agenda__week-row"
            *ngIf="acc && !!accompanyFeatureType()"
          >
            <u-input-time
              [class]="'u-input-time_center'"
              [defaultTime]="false"
              [(ngModel)]="weekDay.ride.accompany.hours"
              [disabled]="weekDay.ride.accompany.costType !== 1 || !builderDataService.assignAccompanyToRidesAllowed()"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="editAccompanyCost(weekDay)"
            ></u-input-time>
            <div
              *ngIf="builderDataService.assignAccompanyToRidesAllowed()"
              class="builder-agenda__week-row-duplicate"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('accompanyCost', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
          <div
            class="builder-agenda__week-row"
            *ngIf="acc && !!accompanyFeatureType()"
          >
            <input
              uInput
              type="text"
              [class]="'u-input_text-center'"
              [mask]="'0*.00'"
              [dropSpecialCharacters]="false"
              [(ngModel)]="weekDay.ride.accompany.costPerHour"
              [disabled]="weekDay.ride.accompany.costType !== 1 && weekDay.ride.accompany.costType !== 2 || !builderDataService.assignAccompanyToRidesAllowed()"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="editAccompanyCost(weekDay)"
            >
            <div
              *ngIf="builderDataService.assignAccompanyToRidesAllowed()"
              class="builder-agenda__week-row-duplicate"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('accompanyCost', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
          <div
            class="builder-agenda__week-row"
            *ngIf="acc && !!accompanyFeatureType()"
          >
            <input
              uInput
              type="text"
              [class]="'u-input_text-center'"
              [mask]="'0*.00'"
              [dropSpecialCharacters]="false"
              [(ngModel)]="weekDay.ride.accompany.totalCost"
              [disabled]="weekDay.ride.accompany.costType !== 0 || !builderDataService.assignAccompanyToRidesAllowed()"
              [ngModelOptions]="{updateOn: 'blur'}"
              (ngModelChange)="editAccompanyCost(weekDay)"
            >
            <div
              *ngIf="builderDataService.assignAccompanyToRidesAllowed()"
              class="builder-agenda__week-row-duplicate"
            >
              <div
                class="builder-agenda__week-row-duplicate-text"
                (click)="duplicate('accompanyCost', weekDay)"
              >
                {{config.dictionary.duplicate | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
