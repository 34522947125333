import { Component, OnInit, HostBinding, Input, inject, output, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  RoutesChangeViewType,
  RoutesViewTypeMode,
  RoutesChangeOptions,
  RouteDailyRow,
  RoutesTransportationAiSuggestionsItem
} from '@app/routes/models';
import { AuthCustomer, AuthCustomerType } from '@app/auth/models';
import { RouteShuttleCompaniesService } from '@app/route-shuttle-companies/services';

@Component({
  selector: 'app-routes-change',
  templateUrl: './routes-change.component.html',
  styleUrls: [ './routes-change.component.scss', './routes-change.component.rtl.scss' ]
})
export class RoutesChangeComponent implements OnInit {
  @Input() viewportElement: HTMLElement;
  @Input() type: RoutesChangeViewType;
  @Input() activeRide: RouteDailyRow;
  @Input() changeOptions: RoutesChangeOptions;
  @Input() viewTypeMode: RoutesViewTypeMode.DailyView | RoutesViewTypeMode.WeeklyView = RoutesViewTypeMode.DailyView;
  @Input() authCustomer: AuthCustomer;
  @Input() aiSuggestion: RoutesTransportationAiSuggestionsItem;

  readonly closeAction = output<void>();

  private readonly destroyRef = inject(DestroyRef);
  private readonly routeShuttleCompaniesService = inject(RouteShuttleCompaniesService);

  @HostBinding('class') hostClasses: string = 'routes-change';

  routesChangeViewType = RoutesChangeViewType;
  isSCCustomer: boolean;
  activeDays: string[];

  ngOnInit() {
    this.setCustomerType();
    this.getRouteShuttleCompaniesDates();
  }

  private setCustomerType() {
    this.isSCCustomer = this.authCustomer && this.authCustomer.type === AuthCustomerType.ShuttleCompany;
  }

  private getRouteShuttleCompaniesDates() {
    if (this.isSCCustomer) {
      this.routeShuttleCompaniesService.getRouteShuttleCompaniesDates(this.activeRide.routeId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(data => this.activeDays = data.dates);
    }
  }
}
