import {
  AuthCustomerType,
  AuthModuleDepartmentsFeature,
  AuthModuleDepartmentsFeatureType,
  AuthModuleName,
  AuthModuleRoutesFeature,
  AuthModuleRoutesFeatureBidNumber,
  AuthModuleRoutesFeatureRegulationNumber,
  AuthModuleRoutesTableFeature,
  AuthModuleRoutesTableFeatureColumn,
  AuthModuleRoutesTableFeatureDefaultColumn,
  AuthModuleRoutesTableFeatureMasterCustomer,
  AuthModuleRoutesTableFeatureType,
  AuthModuleSupervisorsFeature,
  AuthModuleSupervisorsFeatureType,
  AuthOptionalModule
} from '@app/auth/models';
import { AppConstants } from '@app/shared/constants';
import { RouteDailyRideStatus, RouteDailyYitCtrlStatus, RoutesDailyColumn } from '@app/routes/models';

const DICTIONARY_PATH: string = 'routes.daily';
const DICTIONARY_PATH_AI_SUGGESTIONS: string = 'routes.daily.aiSuggestions';
const DICTIONARY_PATH_TABLE_COLUMNS: string = 'routes.daily.tableColumns';

const dictionary = {
  yitCtrl: {
    notSent: `${DICTIONARY_PATH}.yitCtrl.notSent`,
    sent: `${DICTIONARY_PATH}.yitCtrl.sent`,
    sendingFailed: `${DICTIONARY_PATH}.yitCtrl.sendingFailed`,
    driverOrVehicleAssigned: `${DICTIONARY_PATH}.yitCtrl.driverOrVehicleAssigned`,
    failedToAssignDriverOrCar: `${DICTIONARY_PATH}.yitCtrl.failedToAssignDriverOrCar`,
    executionTimeNotAvailable: `${DICTIONARY_PATH}.yitCtrl.executionTimeNotAvailable`
  },
  requiredRecalculation: {
    message: 'routes.requiredRecalculation.message',
    openEditorAndSave: 'routes.requiredRecalculation.openEditorAndSave'
  },
  notReported: `${DICTIONARY_PATH}.notReported`,
  ok: `${DICTIONARY_PATH}.ok`,
  reset: `${DICTIONARY_PATH}.timeTracking.reset`,
  editTimeTracking: `${DICTIONARY_PATH}.timeTracking.editTimeReport`,
  resetTimeTracking: `${DICTIONARY_PATH}.timeTracking.resetTimeReport`,
  resetTimeTrackingConfirm: `${DICTIONARY_PATH}.timeTracking.resetConfirm`,
  disabledChange: `${DICTIONARY_PATH}.disabledChange`,
  automaticProcess: `${DICTIONARY_PATH}.automaticProcess`,
  nis: 'general.nis',
  no: 'general.no',
  direction: 'general.direction',
  emptyValue: 'uGrid.emptyValue',
  aiSuggestions: {
    exit: `${DICTIONARY_PATH_AI_SUGGESTIONS}.exit`,
    assignAndSave: `${DICTIONARY_PATH_AI_SUGGESTIONS}.assignAndSave`
  },
  required: `${DICTIONARY_PATH}.placeholders.required`
};

const timeTrackingStartDateTimeColumn: RoutesDailyColumn = {
  prop: 'timeTrackingStartDateTime',
  exportPropNameAlias: 'startReportSupv',
  minWidth: 170,
  hideColumn: true,
  filterType: 'select',
  trackingName: 'Start report - SUPV',
  dataFormat: AppConstants.DATE_FORMAT_ISO,
  displayFormat: AppConstants.DATE_FORMAT_BASE_SLASH_SHORT_COMMA_TIME,
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.startReportSupv`,
  cellTemplateName: 'timeTrackingCell',
  filterTypeItemsFnName: 'timeTrackingDateTimeFilterTypeItemsFn',
  filterTypeItemsSearchFnName: 'timeTrackingFilter',
  feature: {
    module: AuthModuleName.Supervisors,
    name: AuthModuleSupervisorsFeature.Type,
    values: [ AuthModuleSupervisorsFeatureType.Generic ]
  },
  optionalModule: AuthOptionalModule.Supervisor,
  exceptCustomerType: AuthCustomerType.ShuttleCompany
};

const timeTrackingEndDateTimeColumn: RoutesDailyColumn = {
  prop: 'timeTrackingEndDateTime',
  exportPropNameAlias: 'endReportSupv',
  minWidth: 170,
  hideColumn: true,
  filterType: 'select',
  trackingName: 'End report - SUPV',
  dataFormat: AppConstants.DATE_FORMAT_ISO,
  displayFormat: AppConstants.DATE_FORMAT_BASE_SLASH_SHORT_COMMA_TIME,
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.endReportSupv`,
  cellTemplateName: 'timeTrackingCell',
  filterTypeItemsFnName: 'timeTrackingDateTimeFilterTypeItemsFn',
  filterTypeItemsSearchFnName: 'timeTrackingFilter',
  feature: {
    module: AuthModuleName.Supervisors,
    name: AuthModuleSupervisorsFeature.Type,
    values: [ AuthModuleSupervisorsFeatureType.Generic ]
  },
  optionalModule: AuthOptionalModule.Supervisor,
  exceptCustomerType: AuthCustomerType.ShuttleCompany
};

const timeTrackingDurationColumn: RoutesDailyColumn = {
  prop: 'timeTrackingDuration',
  exportPropNameAlias: 'rideDurationSupv',
  minWidth: 150,
  hideColumn: true,
  filterType: 'select',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideDurationSupv`,
  cellTemplateName: 'timeTrackingCell',
  filterTypeItemsFnName: 'timeTrackingDateTimeFilterTypeItemsFn',
  filterTypeItemsSearchFnName: 'timeTrackingFilter',
  feature: {
    module: AuthModuleName.Supervisors,
    name: AuthModuleSupervisorsFeature.Type,
    values: [ AuthModuleSupervisorsFeatureType.Generic ]
  },
  optionalModule: AuthOptionalModule.Supervisor,
  exceptCustomerType: AuthCustomerType.ShuttleCompany
};

const departmentColumn: RoutesDailyColumn = {
  prop: 'department',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.department`,
  minWidth: 136,
  filterType: 'select',
  exportPropName: 'department',
  feature: {
    module: AuthModuleName.Departments,
    name: AuthModuleDepartmentsFeature.Type,
    values: [ AuthModuleDepartmentsFeatureType.Generic, AuthModuleDepartmentsFeatureType.Iec ]
  }
};

const supervisorNameColumn: RoutesDailyColumn = {
  prop: 'supervisor.name',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.supervisor`,
  exportPropName: 'supervisor',
  exportPropNameAlias: 'supervisor',
  filterType: 'select',
  cellTemplateName: 'supervisorCell',
  filterTemplateName: 'supervisorCellFilter',
  minWidth: 156,
  optionalModule: AuthOptionalModule.Supervisor,
  feature: {
    module: AuthModuleName.Supervisors,
    name: AuthModuleSupervisorsFeature.Type,
    values: [ AuthModuleSupervisorsFeatureType.Generic ]
  }
};

const costColumn: RoutesDailyColumn = {
  prop: 'cost',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.cost`,
  exportPropName: 'shuttleCompanyCost',
  exportPropNameAlias: 'cost',
  filterType: 'select',
  filterTypeItemsFnName: 'costFilterTypeItemsFn',
  filterTypeItemsSearchFnName: 'costFilterTypeItemsSearchFn',
  minWidth: 116,
  sortable: true,
  hideColumn: true,
  feature: {
    module: AuthModuleName.RoutesTable,
    name: AuthModuleRoutesTableFeature.Type,
    values: [ AuthModuleRoutesTableFeatureType.Generic, AuthModuleRoutesTableFeatureType.Municipality, AuthModuleRoutesTableFeatureType.Army ]
  }
};

const executionCostColumn: RoutesDailyColumn = {
  prop: 'shuttleCompany.executionCost',
  exportPropName: 'executionCost',
  exportPropNameAlias: 'executionCost',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.executionCost`,
  filterType: 'select',
  minWidth: 160,
  sortable: true,
  features: [
    {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.ExecutionCost ]
    }
  ]
};

const shuttleCompanyColumn: RoutesDailyColumn = {
  prop: 'displayShuttleCompany',
  name: `${DICTIONARY_PATH_TABLE_COLUMNS}.shuttleCompany`,
  filterType: 'select',
  exportPropName: 'shuttleCompany',
  cellTemplateName: 'routeShuttleCompanyColumn',
  minWidth: 111,
  filterSortingFunctionName: 'displayShuttleCompanyFilterSortingFunction',
  feature: {
    module: AuthModuleName.RoutesTable,
    name: AuthModuleRoutesTableFeature.Type,
    values: [ AuthModuleRoutesTableFeatureType.Generic, AuthModuleRoutesTableFeatureType.Municipality, AuthModuleRoutesTableFeatureType.Army, AuthModuleRoutesTableFeatureType.ShuttleCompany ]
  },
  cellClass: 'datatable-body-cell_overflow-visible'
};

const columns: RoutesDailyColumn[] = [
  {
    prop: 'check',
    name: '',
    width: 43,
    hideable: false,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    headerCheckboxable: true,
    checkboxable: true,
    custom: true,
    hideDivider: true
  },
  {
    prop: 'activities',
    name: '',
    width: 43,
    maxWidth: 43,
    frozenLeft: true,
    hideable: false,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    custom: true,
    hideDivider: true,
    cellTemplateName: 'activitiesColumn',
    cellClass: 'activities-body-cell'
  },
  {
    prop: 'notes',
    name: '',
    width: 43,
    maxWidth: 43,
    frozenLeft: true,
    hideable: false,
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizeable: false,
    custom: true,
    hideDivider: true,
    cellTemplateName: 'notesColumn',
    cellClass: 'activities-body-cell'
  },
  {
    prop: 'status',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideStatus`,
    frozenLeft: true,
    cellTemplateName: 'routeMonitoringStatus',
    filterType: 'select',
    exportPropName: 'rideStatus',
    filterTemplateName: 'statusColumnFilter',
    draggable: false,
    minWidth: 111,
    width: 196,
    requiredRecalculationWidth: 255,
    requiredRecalculationMinWidth: 128
  },
  {
    prop: 'ownerName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.ownerName`,
    minWidth: 136,
    filterType: 'select',
    customerType: AuthCustomerType.ShuttleCompany
  },
  {
    prop: 'code',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.code`,
    filterType: 'select',
    cellTemplateName: 'routeCodeColumn',
    width: 85,
    minWidth: 77
  },
  {
    prop: 'rideId',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideId`,
    filterType: 'select',
    width: 85,
    minWidth: 77,
    hideColumn: true
  },
  { prop: 'name', name: `${DICTIONARY_PATH_TABLE_COLUMNS}.name`, filterType: 'select', minWidth: 170 },
  {
    prop: 'direction',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.direction`,
    cellTemplateName: 'routeDirectionColumn',
    filterType: 'select',
    filterTemplateName: 'routeDirectionFilter',
    resizeable: false,
    minWidth: 96,
    maxWidth: 96,
    maxWidthRtl: 77,
    filterProperty: 'value',
    filterSortingFunctionName: 'directionColumnFilter'
  },
  {
    prop: 'rideStartDateTime',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideStartDateTime`,
    filterType: 'select',
    cellTemplateName: 'routeStartHourColumn',
    filterTemplateName: 'routeStartHourColumnFilter',
    resizeable: false,
    minWidth: 111,
    maxWidth: 111,
    comparatorName: 'sortRideDateTime'
  },
  {
    prop: 'rideEndDateTime',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideEndDateTime`,
    filterType: 'select',
    cellTemplateName: 'routeEndHourColumn',
    filterTemplateName: 'routeEndHourColumnFilter',
    resizeable: false,
    minWidth: 111,
    maxWidth: 111,
    comparatorName: 'sortRideDateTime'
  },
  {
    prop: 'totalStations',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.stations`,
    minWidth: 88,
    maxWidth: 88,
    filterType: 'select',
    exportPropNameAlias: 'stations'
  },
  {
    prop: 'totalPassengers',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.totalPassengers`,
    filterType: 'select',
    cellTemplateName: 'routeTotalPassengersColumn',
    exportPropName: 'totalPassengers',
    resizeable: false,
    minWidth: 102,
    minWidthRtl: 81,
    maxWidth: 102,
    maxWidthRtl: 81,
    cellClass: 'datatable-body-cell_no-padding'
  },
  {
    prop: 'totalPassengerCustomers',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.customers`,
    filterType: 'select',
    cellTemplateName: 'customersColumn',
    exportPropName: 'totalPassengerCustomers',
    resizeable: false,
    minWidth: 136,
    maxWidth: 136,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.MasterCustomer,
      values: [ AuthModuleRoutesTableFeatureMasterCustomer.MasterCustomerFeature ]
    }
  },
  {
    prop: 'displayAccompanyName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.accompany`,
    filterType: 'select',
    exportPropName: 'accompany',
    cellTemplateName: 'routeAccompanyColumn',
    minWidth: 111,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Type,
      values: [ AuthModuleRoutesTableFeatureType.Municipality, AuthModuleRoutesTableFeatureType.ShuttleCompany ]
    }
  },
  {
    prop: 'accompany.cost',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.accompanyCost`,
    filterType: 'select',
    exportPropName: 'accompanyCost',
    cellTemplateName: 'accompanyCostCell',
    minWidth: 160,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Type,
      values: [ AuthModuleRoutesTableFeatureType.Municipality ]
    }
  },
  supervisorNameColumn,
  {
    ...supervisorNameColumn,
    optionalModule: null,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Type,
      values: [ AuthModuleRoutesTableFeatureType.ShuttleCompany ]
    }
  },
  {
    ...supervisorNameColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.commander`,
    exportPropNameAlias: 'commander',
    feature: {
      module: AuthModuleName.Supervisors,
      name: AuthModuleSupervisorsFeature.Type,
      values: [ AuthModuleSupervisorsFeatureType.Commander ]
    }
  },
  {
    prop: 'createdBy',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.createdBy`,
    minWidth: 160,
    sortable: true,
    hideColumn: true,
    filterType: 'select',
    filterProperty: 'value',
    filterSortingFunctionName: 'createdByColumnFilterSorting',
    cellTemplateName: 'createdByCell',
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.CreatedBy ]
    }
  },
  shuttleCompanyColumn,
  {
    prop: 'executingShuttleCompanyName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.executingShuttleCompanyName`,
    filterType: 'select',
    minWidth: 206,
    initialVisibilityFeature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.DefaultColumns,
      values: [ AuthModuleRoutesTableFeatureDefaultColumn.ExecutingSc ]
    }
  },
  {
    prop: 'routeCustomerName',
    exportPropNameAlias: 'for',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.for`,
    filterType: 'text',
    minWidth: 111,
    customerType: AuthCustomerType.ShuttleCompany
  },
  {
    prop: 'driver.name',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.driver`,
    filterType: 'select',
    cellTemplateName: 'routeDriverColumn',
    minWidth: 111,
    exportPropName: 'driver',
    cellClass: 'datatable-body-cell_overflow-visible'
  },
  {
    prop: 'car.name',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.car`,
    filterType: 'select',
    cellTemplateName: 'carCell',
    minWidth: 153,
    exportPropName: 'car',
    cellClass: 'datatable-body-cell_overflow-visible'
  },
  {
    prop: 'carTypeName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.carType`,
    filterType: 'select',
    cellTemplateName: 'carTypes',
    exportPropName: 'carType',
    minWidth: 153,
    comparatorName: 'sortCarTypes'
  },
  {
    prop: 'shuttleCompany.contractName',
    exportPropName: 'shuttleCompanyContractName',
    exportPropNameAlias: 'contractName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.contractName`,
    minWidth: 160,
    filterType: 'select',
    customerType: AuthCustomerType.ShuttleCompany
  },
  {
    prop: 'distance',
    exportPropNameAlias: 'plannedKm',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.plannedKm`,
    minWidth: 116,
    sortable: true,
    hideColumn: true,
    exceptCustomerType: AuthCustomerType.ShuttleCompany
  },
  {
    prop: 'duration',
    exportPropNameAlias: 'plannedRideDuration',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.plannedRideDuration`,
    minWidth: 128,
    sortable: true,
    hideColumn: true,
    exceptCustomerType: AuthCustomerType.ShuttleCompany
  },
  timeTrackingStartDateTimeColumn,
  {
    ...timeTrackingStartDateTimeColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.startReportCmdr`,
    exportPropNameAlias: 'startReportCmdr',
    trackingName: 'Start report - CMDR',
    feature: {
      module: AuthModuleName.Supervisors,
      name: AuthModuleSupervisorsFeature.Type,
      values: [ AuthModuleSupervisorsFeatureType.Commander ]
    }
  },
  timeTrackingEndDateTimeColumn,
  {
    ...timeTrackingEndDateTimeColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.endReportCmdr`,
    exportPropNameAlias: 'endReportCmdr',
    trackingName: 'End report - CMDR',
    feature: {
      module: AuthModuleName.Supervisors,
      name: AuthModuleSupervisorsFeature.Type,
      values: [ AuthModuleSupervisorsFeatureType.Commander ]
    }
  },
  timeTrackingDurationColumn,
  {
    ...timeTrackingDurationColumn,
    exportPropNameAlias: 'rideDurationCmdr',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.rideDurationCmdr`,
    cellTemplateName: 'timeTrackingCell',
    feature: {
      module: AuthModuleName.Supervisors,
      name: AuthModuleSupervisorsFeature.Type,
      values: [ AuthModuleSupervisorsFeatureType.Commander ]
    }
  },
  {
    prop: 'shuttleCompany.contractName',
    exportPropName: 'shuttleCompanyContractName',
    exportPropNameAlias: 'contractName',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.contractName`,
    minWidth: 160,
    filterType: 'select',
    exceptCustomerType: AuthCustomerType.ShuttleCompany
  },
  {
    prop: 'costType',
    exportPropNameAlias: 'pricingType',
    exportPropName: 'shuttleCompanyCostType',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.pricingType`,
    minWidth: 128,
    filterType: 'select',
    sortable: true,
    hideColumn: true,
    exceptCustomerType: AuthCustomerType.ShuttleCompany
  },
  costColumn,
  {
    ...costColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.customerCost`,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Type,
      values: [ AuthModuleRoutesTableFeatureType.ShuttleCompany ]
    }
  },
  {
    ...executionCostColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.subContractorDriverCost`,
    exportPropNameAlias: 'subContractorDriverCost',
    minWidth: 220,
    features: [
      ...executionCostColumn.features,
      {
        module: AuthModuleName.RoutesTable,
        name: AuthModuleRoutesTableFeature.Type,
        values: [ AuthModuleRoutesTableFeatureType.ShuttleCompany ]
      }
    ]
  },
  {
    ...executionCostColumn,
    features: [
      ...executionCostColumn.features,
      {
        module: AuthModuleName.RoutesTable,
        name: AuthModuleRoutesTableFeature.Type,
        values: [ AuthModuleRoutesTableFeatureType.Generic, AuthModuleRoutesTableFeatureType.Municipality, AuthModuleRoutesTableFeatureType.Army ]
      }
    ]
  },
  {
    prop: 'shuttleCompany.profit',
    exportPropName: 'profit',
    exportPropNameAlias: 'profitLoss',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.profitLoss`,
    filterType: 'select',
    minWidth: 160,
    sortable: true,
    hideColumn: true,
    cellTemplateName: 'profitLossCell',
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.ExecutionCost ]
    }
  },
  {
    prop: 'shuttleCompany.profitPercentage',
    exportPropName: 'profitPercentage',
    exportPropNameAlias: 'profitLossPercent',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.profitLossPercent`,
    filterType: 'select',
    minWidth: 160,
    sortable: true,
    hideColumn: true,
    cellTemplateName: 'profitLossPercentCell',
    filterTemplateName: 'profitLossPercentCellFilter',
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.ExecutionCost ]
    }
  },
  {
    prop: 'orderPurpose',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.orderPurpose`,
    filterType: 'select',
    minWidth: 200,
    sortable: true,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.OrderPurpose ]
    }
  },
  {
    prop: 'region',
    minWidth: 160,
    filterType: 'select',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.region`,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.Region ]
    }
  },
  departmentColumn,
  {
    ...departmentColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.command`,
    exportPropNameAlias: 'command',
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Section ]
    }
  },
  {
    ...departmentColumn,
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.departmentDivision`,
    exportPropNameAlias: 'departmentDivision',
    feature: {
      module: AuthModuleName.Departments,
      name: AuthModuleDepartmentsFeature.Type,
      values: [ AuthModuleDepartmentsFeatureType.Division ]
    }
  },
  {
    prop: 'bidNumber',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.contractCode`,
    exportPropNameAlias: 'contractCode',
    minWidth: 128,
    sortable: true,
    editType: 'input',
    filterType: 'select',
    filterEmptyValueName: 'withoutContractCodeFilter',
    filterTypeItemsSearchFnName: 'bidNumberFilterTypeItemsSearchFn',
    feature: {
      module: AuthModuleName.Routes,
      name: AuthModuleRoutesFeature.BidNumber,
      values: [ AuthModuleRoutesFeatureBidNumber.BidNumberFeature ]
    }
  },
  {
    prop: 'regulationNumber',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.regulationNumber`,
    minWidth: 128,
    sortable: true,
    filterType: 'select',
    filterEmptyValueName: 'withoutRegulationNumberFilter',
    filterTypeItemsSearchFnName: 'regulationNumberFilterTypeItemsSearchFn',
    feature: {
      module: AuthModuleName.Routes,
      name: AuthModuleRoutesFeature.RegulationNumber,
      values: [ AuthModuleRoutesFeatureRegulationNumber.RegulationNumberFeature ]
    }
  },
  {
    prop: 'purchaseOrder',
    width: 116,
    minWidth: 116,
    sortable: true,
    filterType: 'select',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.purchaseOrder`,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.PurchaseOrder ]
    }
  },
  {
    prop: 'comments',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.comments`,
    minWidth: 145,
    sortable: true,
    hideColumn: true,
    filterType: 'select',
    filterTypeItemsFnName: 'commentsFilterTypeItemsFn',
    filterTypeItemsSearchFnName: 'commentsFilterTypeItemsSearchFn'
  },
  {
    prop: 'yitStatus',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.yitStatus`,
    filterType: 'select',
    cellTemplateName: 'yitStatusCell',
    width: 213,
    minWidth: 213,
    custom: true,
    filterSortingFunctionName: 'yitCtrlStatusColumnFilter',
    filterProperty: 'value',
    filterEmptyValue: null
  },
  {
    prop: 'ctrlStatus',
    name: `${DICTIONARY_PATH_TABLE_COLUMNS}.ctrlStatus`,
    filterType: 'select',
    cellTemplateName: 'ctrlStatusCell',
    width: 213,
    minWidth: 213,
    custom: true,
    filterSortingFunctionName: 'yitCtrlStatusColumnFilter',
    filterProperty: 'value',
    filterEmptyValue: null
  },
  {
    prop: '',
    name: '',
    hideable: false,
    hideDivider: true,
    cellTemplateName: 'routeTimerColumn',
    minWidth: 102,
    maxWidth: 102,
    custom: true,
    resizeable: false,
    feature: {
      module: AuthModuleName.RoutesTable,
      name: AuthModuleRoutesTableFeature.Columns,
      values: [ AuthModuleRoutesTableFeatureColumn.RouteEndsInDays ]
    }
  }
];

const rideStatusClasses = {
  [RouteDailyRideStatus.New]: 'ride-status_new',
  [RouteDailyRideStatus.Cancelled]: 'ride-status_cancelled',
  [RouteDailyRideStatus.FinishedMonitored]: 'ride-status_finished-monitored',
  [RouteDailyRideStatus.Finished]: 'ride-status_finished',
  [RouteDailyRideStatus.OngoingMonitored]: 'ride-status_on-going-monitored',
  [RouteDailyRideStatus.Ongoing]: 'ride-status_on-going'
};

export const routesDailyComponentConfig = {
  tableName: 'routes-daily',
  dictionary,
  columns,
  routeBuilderUrl: 'Builder',
  headerTrackingId: 'Routes & Rides, global header',
  yitStatuses: {
    [RouteDailyYitCtrlStatus.NotSent]: {
      name: dictionary.yitCtrl.notSent,
      image: '/assets/images/routes/yit-gray.svg'
    },
    [RouteDailyYitCtrlStatus.Sent]: {
      name: dictionary.yitCtrl.sent,
      image: '/assets/images/routes/yit-yellow.svg'
    },
    [RouteDailyYitCtrlStatus.SendingFailed]: {
      name: dictionary.yitCtrl.sendingFailed,
      image: '/assets/images/routes/yit-red.svg'
    },
    [RouteDailyYitCtrlStatus.DriverOrVehicleAssigned]: {
      name: dictionary.yitCtrl.driverOrVehicleAssigned,
      image: '/assets/images/routes/yit-green.svg'
    },
    [RouteDailyYitCtrlStatus.FailedToAssignDriverOrCar]: {
      name: dictionary.yitCtrl.failedToAssignDriverOrCar,
      image: '/assets/images/routes/yit-red.svg'
    }
  },
  ctrlStatuses: {
    [RouteDailyYitCtrlStatus.NotSent]: {
      name: dictionary.yitCtrl.notSent,
      image: '/assets/images/routes/ctrl-gray.svg'
    },
    [RouteDailyYitCtrlStatus.Sent]: {
      name: dictionary.yitCtrl.sent,
      image: '/assets/images/routes/ctrl-yellow.svg'
    },
    [RouteDailyYitCtrlStatus.SendingFailed]: {
      name: dictionary.yitCtrl.sendingFailed,
      image: '/assets/images/routes/ctrl-red.svg'
    },
    [RouteDailyYitCtrlStatus.DriverOrVehicleAssigned]: {
      name: dictionary.yitCtrl.driverOrVehicleAssigned,
      image: '/assets/images/routes/ctrl-green.svg'
    },
    [RouteDailyYitCtrlStatus.FailedToAssignDriverOrCar]: {
      name: dictionary.yitCtrl.failedToAssignDriverOrCar,
      image: '/assets/images/routes/ctrl-red.svg'
    }
  },
  rideStatusClasses
};
