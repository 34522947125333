import { ElementRef } from '@angular/core';
import { merge } from 'lodash';
import {
  UDroplistItem,
  UGridSort,
  UHereMapMarkerInfo,
  UHereMapPathConfig,
  UInputCitiesCity,
  UMultiselectItem,
  USelectSItem
} from '@shift/ulib';
import { UHereMapAddress, UHereMapBounds, UHereMapLatLng, UHereMapPointCenterConfig } from '@shift/ulib';

import {
  AuthModuleFeature,
  AuthModuleSupervisorsFeatureType,
  AuthPermission
} from '@app/auth/models';
import { DaysOfWeek, HashcalRideType } from '@app/shared/models';
import { mapConfig } from '@app/shared/configs';
import { RideOrderStatus } from '@app/ride-orders/models';
import {
  RouteDirection,
  RouteRideSupervisorLevel,
  RoutesChangeType,
  RouteType
} from '@app/routes/models';
import { RoutePlannerDuplicateBody, RoutePlannerPassenger } from '@app/route-planner/models';

export enum BuilderMode {
  Add = 'add',
  Edit = 'edit'
}

export enum BuilderViewMode {
  Default = 'default',
  Full = 'full',
  RideOrders = 'rideOrders'
}

export enum BuilderBuildMode {
  Routes = 'routes',
  RouteTemplates = 'route-templates',
  RouteSuggestions = 'route-suggestions',
  RideOrders = 'ride-orders'
}

export enum BuilderCalculationMode {
  Optimal = 'Optimal',
  ManualStations = 'ManualStations',
  Manual = 'FullManual'
}

export enum BuilderRouteStatus {
  None = -1,
  Pending = 0,
  InProgress = 1,
  Saved = 2,
  Failed = 3,
  Deleted = 100
}

export enum BuilderTimeType {
  StartTime = 1,
  EndTime = 2
}

export enum BuilderSaveActionType {
  SaveClose = 'save-close',
  SaveNew = 'save-new',
  SaveClone = 'save-clone',
  SaveReverse = 'save-reverse',
  Delete = 'delete',
  Close = 'close'
}

export enum BuilderCustomerDataType {
  SubCustomers = 'subCustomers'
}

export enum BuilderCustomerDataCarSizeType {
  Small = 1,
  Medium,
  Large
}

export enum BuilderAccompanyOptionsType {
  Required = 'required',
  Without = 'without'
}

export enum BuilderActiveRideCostType {
  ManualPrice = 0,
  PricePerKm = 1,
  PriceByCity = 2,
  PriceByKmRanges = 9,
  HashcalPrice = 10
}

export enum BuilderActiveRideTollRoadsCostType {
  Manual,
  ByContract
}

export enum BuilderActiveRideTimeType {
  StartTime = 1,
  EndTime = 2
}

export enum BuilderActiveRideYitSyncMode {
  ByCode,
  ByDepartment
}

export enum BuilderStationArrivalTimeFormField {
  Time = 'time',
  DayAfter = 'dayAfter'
}

export enum BuilderFilterDataType {
  Passengers = 1,
  Stations = 2
}

export enum BuilderAfterSaveOption {
  None = 0,
  AddNew = 1,
  Duplicate = 2,
  Reverse = 3
}

export enum BuilderSearchItemType {
  All = 'all',
  Accompany = 'accompany',
  Station = 'station',
  Address = 'address',
  Passenger = 'passenger',
  School = 'school',
  Branch = 'branch'
}

export enum BuilderStationListItemType {
  Passenger = 'passenger',
  Station = 'station',
  Destination = 'destination',
  Accompany = 'accompany'
}

export enum BuilderRouteType {
  Regular,
  Shuttle,
  HopOnHopOff,
  FixedRide,
  OccasionalRideAttached,
  OccasionalRideNotAttached,
  StandByRide
}

export enum BuilderPassengerType {
  Regular,
  Anonymous,
  Supervisor,
  External
}

export enum BuilderTuningMode {
  Default = 'default',
  SC = 'sc'
}

export enum BuilderSectionIconValue {
  Details = 'details',
  Stations = 'stations',
  SC = 'sc'
}

export enum BuilderRouteMode {
  Regular = 0,
  Template = 1,
  RideOrder = 2
}

export enum BuilderRideOrderConfirmationItemType {
  Red = 'red',
  Green = 'green'
}

export enum BuilderRideOrderConfirmationItemProp {
  Disabled = 'disabled',
  Readonly = 'readonly'
}

export enum BuilderLayerId {
  MonitoringPath = 'monitoring-path'
}

export enum BuilderActiveRideMonitoredPathSourceType {
  Car = 1,
  Driver = 2,
  Passenger = 3,
  Accompany = 4,
  Supervisor = 5,
  ExternalDriver = 6
}

export enum BuilderExtraFeeOption {
  CheckExtraFee,
  Apply,
  DoNotApply
}

export interface BuilderLayer {
  id: BuilderLayerId;
  title: string;
  active?: boolean;
}

export interface BuilderRoutesStateGridProps {
  offsetY?: number;
  columnsOrder?: string[];
  hiddenColumns?: string[];
  columnsStoreVisible?: string[];
  sorts?: UGridSort[];
}

export interface BuilderRoutesState {
  routes: BuilderRoute[];
  activeRoute: {
    id: number;
    initialState: BuilderRoute;
  };
  search: string;
  showBackdrop: boolean;
  routesFullChanged: boolean;
  grid: {
    columns: {
      filtered: boolean;
      resetFilter: boolean;
    };
    rowClassObjects: {
      className?: string;
      rowPropertyName?: string;
      value?: string | number;
    }[];
    messages: string;
    props: BuilderRoutesStateGridProps;
  };
}

export interface BuilderConfig {
  mode: BuilderMode;
  viewMode: BuilderViewMode;
  buildMode: BuilderBuildMode;
  modal: ElementRef;
  trackingId?: string;
}

export interface BuilderSaveAction extends UDroplistItem {
  value: BuilderSaveActionType;
  feature?: AuthModuleFeature;
  permission?: AuthPermission;
}

export interface BuilderRoute {
  status?: number;
  routeId: number;
  code: number;
  name: string;
  direction: number;
  days: number[];
  startDate: string;
  endDate: string;
  rideStartDateTime: string;
  rideEndDateTime: string;
  totalStations?: number;
  totalPassengers: number;
  carTypeName: string;
  carTypeCapacity: number;
  shuttleCompany: string;
  timeType?: number;
  locked: boolean;
  errorMessage?: string;
  allowEmptyStations?: boolean;
  branchNames?: string[];
  hasAiSuggestions?: boolean;
}

export interface BuilderDuplicateRouteForm {
  routeId: number;
  name: string;
  isAutoGeneratedName: boolean;
  direction: number;
  startTime: string;
  endTime: string;
  timeType: number;
  preset: string;
  startDate: string;
  endDate: string;
  dates: string[];
}

export interface BuilderDuplicateRouteAction {
  type: string;
  body?: RoutePlannerDuplicateBody;
}

export interface BuilderAccompany {
  rideAccompanyId: number;
  accompanyId: number;
  active: boolean;
  planned: boolean;
  name: string;
  costType: number;
  plannedCostType: number;
  costPerHour: number;
  plannedCostPerHour: number;
  hours: number;
  plannedHours: number;
  totalCost: number;
  plannedTotalCost: number;
  sourceStationId: number;
  destinationStationId: number;
}

export interface BuilderPassenger {
  active: boolean;
  address: string;
  amount: number;
  latitude: number;
  longitude: number;
  name: string;
  passengerId: number;
  passengerType: BuilderPassengerType;
  plannedStationId: number;
  plannedTargetStationId: number;
  ridePassengerId: number;
  stationId: number;
  targetStationId: number;
  isSupervisor: boolean;
  supervisorLevel: RouteRideSupervisorLevel;
  customerName: string;
}

export interface BuilderStationBase {
  active: boolean;
  address: string;
  arrivalTime: string;
  branchId: number;
  customerId: number;
  latitude: number;
  longitude: number;
  name: string;
  rideStationId: number;
  type: number;
}

export interface BuilderActiveRideWaypoint {
  latitude: number;
  longitude: number;
  stopover: boolean;
}

export interface BuilderStationListItem {
  active?: boolean;
  address?: string;
  arrivalTime?: string;
  branchId?: number;
  customerId?: number;
  latitude?: number;
  longitude?: number;
  name?: string;
  order?: number;
  rideStationId?: number | string;
  type?: BuilderStationListItemType;
  passengers?: {
    active?: boolean;
    address?: string;
    amount?: number;
    checkIn?: boolean;
    customerName?: string;
    destination?: number;
    isSupervisor?: boolean;
    latitude?: number;
    longitude?: number;
    name?: string;
    passengerId?: number;
    passengerType?: BuilderPassengerType;
    plannedStationId?: number;
    plannedTargetStationId?: number;
    ridePassengerId?: number;
    stationId?: number;
    supervisorLevel?: number;
    targetStationId?: number;
    type?: string;
  }[];
  expand?: {
    check?: boolean;
    options?: {
      accompanyId?: number | string;
      costOption?: number;
      cost?: number;
      priceOption?: number;
      price?: number;
      priceH?: number;
      return?: boolean;
      pickUpHome?: boolean;
      costPerHour?: number;
      plannedCostPerHour?: number;
      costType?: number;
      plannedCostType?: number;
      hours?: string;
      plannedHours?: string;
      totalCost?: number;
      plannedTotalCost?: number;
    };
  };
}

export interface BuilderStation {
  list: BuilderStationListItem[];
  customerData: any;
  customerDataSet(data: any);
  isPassengerWithoutDestination(): boolean;
  isAnyDestination(): boolean;
  stationById(id: number): BuilderStationListItem;
  stationMovePassenger(ridePassengerId: number, rideStationId: number);
  stationAccUpdate(station: BuilderStationListItem, activeRide: BuilderActiveRideBase);
  stationAccExpandSync(activeRide: BuilderActiveRideBase);
  parse(activeRide: BuilderActiveRideBase, active: boolean);
  updatePassengerCustomerName(passengerId: number, name: string);
}

export interface BuilderActiveRideMonitoredPath {
  coordinates: {
    latitude: number;
    longitude: number;
    locatedAt: string;
  }[];
  memberId: number;
  sourceType: BuilderActiveRideMonitoredPathSourceType;
}

export interface BuilderActiveRideVehicle {
  carId: number;
  number: string;
  carTypeId: number;
  seatsCount: number;
  carSizeType: number;
}

export interface BuilderActiveRideDriver {
  driverId: number;
  firstName: string;
  lastName: string;
  mobile: string;
  code: string;
}

export interface BuilderActiveRideDefault {
  accompanies: BuilderAccompany[];
  cancelled: boolean;
  carTypeId: number;
  contractId: string;
  cost: number;
  costType: BuilderActiveRideCostType;
  date: string;
  dayOfWeek: DaysOfWeek;
  distance: number;
  duration: string;
  endDateTime: string;
  isYitActive: boolean;
  needAccompany: boolean;
  passengers: BuilderPassenger[];
  plannedEndDateTime: string;
  plannedStartDateTime: string;
  plannedTimeType: BuilderActiveRideTimeType;
  plannedYitSyncMode: BuilderActiveRideYitSyncMode;
  rideId: number;
  assignedShuttleCompanyId: number;
  shuttleCompanyId: number;
  purchaseOrder: string;
  purchaseOrderRow: string;
  seatsCount: number;
  startDateTime: string;
  totalStations?: number;
  totalPassengers: number;
  rideCalculationMode: BuilderCalculationMode;
  plannedTotalPassengers: number;
  timeType: BuilderActiveRideTimeType;
  encodedPolylineJson: string;
  waypoints: BuilderActiveRideWaypoint[];
  yitSyncMode: BuilderActiveRideYitSyncMode;
  yitSyncValue: string;
  branchId: number;
  isAnyTargetStation: boolean;
  chargeExtraFee: boolean;
  hashcalRideType: HashcalRideType;
  monitoredPaths: BuilderActiveRideMonitoredPath[];
  vehicle: BuilderActiveRideVehicle;
  driver: BuilderActiveRideDriver;
  subContractorExecutionCost: number;
  executionCost: number;
  driverHours: string;
  extraFee: number;
  totalCost: number;
  tollRoadsCost: number;
  tollRoadsCostType: BuilderActiveRideTollRoadsCostType;
  isMainScSession: boolean;
}

export interface BuilderActiveRideBase extends BuilderActiveRideDefault {
  stations: BuilderStationBase[];
}

export interface BuilderActiveRide extends BuilderActiveRideDefault {
  stations: BuilderStation;
  stationsInactive: BuilderStation;
  carId: number;
  driverId: number;
  assignedShuttleCompanyName: string;
}

export interface BuilderDetails {
  activeDays: number[];
  bidNumber: string;
  regulationNumber: string;
  budgetItemId: number;
  comment: string;
  creationDate: string;
  customerIds: number[];
  dateFrom: string;
  dateTo: string;
  departmentId: number;
  direction: RouteDirection;
  isAutoGeneratedName: boolean;
  name: string;
  number: number;
  routeId: number;
  regionCode: string;
  orderPurposeId: number;
  routeType: BuilderRouteType;
  allowEmptyStations: boolean;
  contactName: string;
  contactMobile: string;
}

export interface BuilderSettings {
  waitingTime: string;
  useTollRoads: boolean;
  calculateBy: number;
  cheapestPrice: boolean;
  useTraffic: boolean;
}

export interface BuilderRouteAddEdit {
  activeRide: BuilderActiveRideBase;
  details: BuilderDetails;
  requiredRecalculation: boolean;
  guid: string;
  settings: BuilderSettings;
}

export interface BuilderGetShuttleCompaniesBody {
  shuttleCompanyRides: {
    shuttleCompanyId: number;
    contractId: string;
  }[];
  routeMode: BuilderRouteMode;
}

export interface BuilderGetShuttleCompaniesDetails {
  shuttleCompany: {
    shuttleCompanyId: number;
    contractId: string;
  };
  details: {
    drivers: {
      id: number;
      name: string;
    }[];
    carTypes: {
      id: number;
      name: string;
      capacity: number;
      maxCapacity: number;
      carSizeType: number;
      cars: {
        id: number;
        name: string;
      }[];
    }[];
    costTypes: {
      id: number;
      name: string;
      hashcalRideTypes: {
        id: number;
        name: string;
      }[];
    }[];
  };
}

export interface BuilderGetShuttleCompanies {
  shuttleCompaniesDetails: BuilderGetShuttleCompaniesDetails[];
}

export interface BuilderCustomerDataItem {
  id: number;
  name: string;
}

export interface BuilderCustomerDataSubCustomer extends BuilderCustomerDataItem {
  isOwnedBySc: boolean;
}

export interface BuilderCustomerDataOrderPurpose extends BuilderCustomerDataItem {
  routeType: RouteType;
}

export interface BuilderCustomerDataBudgetType extends BuilderCustomerDataItem {
  number: string;
}

export interface BuilderCustomerDataCarType extends BuilderCustomerDataItem {
  capacity: number;
  carSizeType: BuilderCustomerDataCarSizeType;
  maxCapacity: number;
}

export interface BuilderCustomerDataAccompany {
  id: number | string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  mobile: string;
}

export interface BuilderCustomerDataShuttleCompany extends BuilderCustomerDataItem {
  phone: string;
}

export interface BuilderCustomerDataShift extends BuilderCustomerDataItem {
  days: {
    [key: string]: {
      startTime: string;
      endTime: string;
    };
  };
}

export interface BuilderCustomerDataClass extends BuilderCustomerDataItem {
  grade: number;
  minDistanceToSchool: number;
}

export interface BuilderCustomerDataRegion {
  sapCode: string;
  name: string;
  departments: {
    id: number;
    name: string;
  }[];
}

export interface BuilderCustomerData {
  schools?: BuilderCustomerDataItem[];
  departments: BuilderCustomerDataItem[];
  branches?: BuilderCustomerDataItem[];
  cities: UInputCitiesCity[];
  shifts?: BuilderCustomerDataShift[];
  classes?: BuilderCustomerDataClass[];
  subCustomers: BuilderCustomerDataSubCustomer[];
  shuttleCompanies: BuilderCustomerDataShuttleCompany[];
  carTypes: BuilderCustomerDataCarType[];
  accompanies?: BuilderCustomerDataAccompany[];
  budgetTypes: BuilderCustomerDataBudgetType[];
  tags?: BuilderCustomerDataItem[];
  office?: BuilderCustomerDataItem;
  regions?: BuilderCustomerDataRegion[];
  memberRegions?: BuilderCustomerDataRegion[];
  orderPurposes?: BuilderCustomerDataOrderPurpose[];
  supervisors?: BuilderCustomerDataItem[];
}

export interface BuilderStationSaveBody {
  stationId: number;
  name: string;
  fullAddress: string;
  longitude: number;
  latitude: number;
}

export interface BuilderStationSave {
  id: number;
  name: string;
}

export interface BuilderStationArrivalTimeSaveAction {
  arrivalTime: string;
  isNextDay: boolean;
}

export interface BuilderStationArrivalTimeEditAction extends BuilderStationArrivalTimeSaveAction {
  rideStationId: number;
}

export interface BuilderFilterData {
  direction: number;
  dataType: BuilderFilterDataType;
  cities: UInputCitiesCity[];
  stationsFilterType?: number;
  assignmentType?: number;
  eligibleToShuttle?: boolean;
  needAccompany?: boolean;
  gender?: number;
  schoolIds?: number[];
  classTypeIds?: number[];
  departmentIds?: number[];
  shiftIds?: number[];
  tagIds?: number[];
  branchIds?: number[];
  masterSubCustomerIds?: number[];
}

export interface BuilderDataStoreOptions {
  [key: string]: (USelectSItem | UMultiselectItem)[];
}

export interface BuilderSearch {
  addresses: {
    latitude: number;
    longitude: number;
    fullAddress: string;
    placeId: string;
    name: string;
  }[];
  passengers: {
    id: number;
    name: string;
    customerName: string;
    isSupervisor: boolean;
  }[];
  stations: {
    id: number;
    name: string;
  }[];
  branches: {
    id: number;
    name: string;
  }[];
  schools: {
    id: number;
    name: string;
  }[];
  accompanies: {
    id: number;
    name: string;
  }[];
}

export interface BuilderDayOfWeekVehicle {
  carId: number;
  number: string;
  carTypeId: number;
  seatsCount: number;
  carSizeType: number;
}

export interface BuilderDayOfWeekDriver {
  driverId: number;
  firstName: string;
  lastName: string;
  mobile: string;
  code: string;
}

export class BuilderDayOfWeek {
  active = true;
  date: string = null;
  dayOfWeek: number = null;
  ride: {
    rideId: string;
    branchId: number;
    isAnyTargetStation: boolean;
    isCancelled: boolean;
    startDateTime: string;
    endDateTime: string;
    timeType: number;
    transport: {
      shuttleCompanyId: number;
      costType: number;
      cost: string;
      carTypeId: number;
      vehicle: BuilderDayOfWeekVehicle;
      driver: BuilderDayOfWeekDriver;
      carId: number;
      driverId: number;
      driverHours: string;
      contractId: number;
      hashcalRideType: HashcalRideType;
      totalCost: number;
    },
    accompany: {
      accompanyId: number | string;
      costType: number;
      costPerHour: string;
      hours: string;
      totalCost: string;
    },
    supervisorId: number;
    rideCalculationMode: BuilderCalculationMode;
    assignedScExecutionCost: number;
  } = {
      rideId: null,
      branchId: null,
      isAnyTargetStation: false,
      isCancelled: false,
      startDateTime: null,
      endDateTime: null,
      timeType: null,
      transport: {
        shuttleCompanyId: null,
        costType: null,
        cost: null,
        carTypeId: null,
        vehicle: null,
        driver: null,
        carId: null,
        driverId: null,
        driverHours: null,
        contractId: null,
        hashcalRideType: null,
        totalCost: null
      },
      accompany: {
        accompanyId: 'none',
        costType: null,
        costPerHour: null,
        hours: null,
        totalCost: null
      },
      supervisorId: null,
      rideCalculationMode: null,
      assignedScExecutionCost: null
    };

  options: {
    transport: {
      costTypes: any[];
      carTypeIds: any[];
      carIds: any[];
      driverIds: any[];
      hashcalRideTypes: { [key in HashcalRideType]: USelectSItem[]; };
    }
  } = {
      transport: {
        costTypes: [],
        carTypeIds: [],
        carIds: [],
        driverIds: [],
        hashcalRideTypes: null
      }
    };

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  merge(values: Object = {}) {
    merge(this, values);
  }
}

export interface BuilderDuplicateChangesCalendarSave {
  checkDaysActive: number[];
  checkDaysAvailable: number[];
  dateFrom: string;
  dateTo: string;
  dates: string[];
  disableDaysAfter?: string;
  disableDaysBefore?: string;
  type: string;
}

export interface BuilderDuplicateChangesAction {
  type: string;
  content: {
    datesChanges: BuilderDuplicateChangesCalendarSave;
    plannedType?: number;
  };
}

export interface BuilderEditRouteTypeBodyValue {
  routeType: BuilderRouteType;
}

export interface BuilderEditRouteTypeBody {
  routeId: number;
  value: BuilderEditRouteTypeBodyValue;
}

export interface BuilderEditRequiresApprovalBody {
  routeId: number;
  activeDayOfWeek: DaysOfWeek;
  value: {
    requiresApproval: boolean;
  };
}

export interface BuilderEdit {
  routeId: number;
  activeDate: string;
  activeDayOfWeek?: DaysOfWeek;
}

export interface BuilderEditValue {
  dateFrom: string;
  dateTo: string;
  days: DaysOfWeek[];
  type: RoutesChangeType;
}

export interface BuilderEditCalculationModeSetBodyValue extends BuilderEditValue {
  rideCalculationMode: BuilderCalculationMode;
}

export interface BuilderEditCalculationModeSetBody extends BuilderEdit {
  value: BuilderEditCalculationModeSetBodyValue;
}

export interface BuilderEditPurchaseOrderBodyValue extends BuilderEditValue {
  comment: string;
  purchaseOrder: string;
}

export interface BuilderEditPurchaseOrderBody extends BuilderEdit {
  value: BuilderEditPurchaseOrderBodyValue;
}

export interface BuilderEditPurchaseOrderRowBodyValue extends BuilderEditValue {
  comment: string;
  purchaseOrderRow: string;
}

export interface BuilderEditPurchaseOrderRowBody extends BuilderEdit {
  value: BuilderEditPurchaseOrderRowBodyValue;
}

export interface BuilderEditContractSetBodyValue extends BuilderEditValue {
  contractId: string;
}

export interface BuilderEditContractSetBody extends BuilderEdit {
  value: BuilderEditContractSetBodyValue;
}

export interface BuilderEditContactNameBodyValue extends BuilderEditValue {
  contactName: string;
}

export interface BuilderEditContactNameBody extends BuilderEdit {
  value: BuilderEditContactNameBodyValue;
}

export interface BuilderEditContactMobileBodyValue extends BuilderEditValue {
  contactMobile: string;
}

export interface BuilderEditContactMobileBody extends BuilderEdit {
  value: BuilderEditContactMobileBodyValue;
}

export interface BuilderEditChargeExtraFeeBodyValue extends BuilderEditValue {
  chargeExtraFee: boolean;
  shuttleCompanyId: number;
}

export interface BuilderEditChargeExtraFeeBody extends BuilderEdit {
  value: BuilderEditChargeExtraFeeBodyValue;
}

export interface BuilderEditHashcalRideTypeBodyValue extends BuilderEditValue {
  shuttleCompanyId: number;
  hashcalRideType: HashcalRideType;
}

export interface BuilderEditHashcalRideTypeBody extends BuilderEdit {
  value: BuilderEditHashcalRideTypeBodyValue;
}

export interface BuilderEditExecutionCostBodyValue extends BuilderEditValue {
  executionCost: number;
}

export interface BuilderEditExecutionCostBody extends BuilderEdit {
  value: BuilderEditExecutionCostBodyValue;
}

export interface BuilderEditDriverHoursBodyValue extends BuilderEditValue {
  shuttleCompanyId: number;
  driverHours: string;
}

export interface BuilderEditDriverHoursBody extends BuilderEdit {
  value: BuilderEditDriverHoursBodyValue;
}

export interface BuilderEditTollRoadsCostBodyValue extends BuilderEditValue {
  shuttleCompanyId: number;
  cost: number;
  costType: BuilderActiveRideTollRoadsCostType;
}

export interface BuilderEditTollRoadsCostBody extends BuilderEdit {
  value: BuilderEditTollRoadsCostBodyValue;
}

export interface BuilderEditRegionBody extends BuilderEdit {
  value: {
    regionCode: string;
  };
}

export interface BuilderSearchItems {
  addresses: BuilderSearchItem[];
  passengers: BuilderSearchItem[];
  stations: BuilderSearchItem[];
  schools: BuilderSearchItem[];
  accompanies: BuilderSearchItem[];
  branches: BuilderSearchItem[];
}

export class BuilderSearchItem {
  constructor(
    public type: BuilderSearchItemType,
    public id: number | string = null,
    public name: string = null,
    public address: string = null,
    public latitude: number = null,
    public longitude: number = null,
    public placeId: string = null,
    public isSupervisor: boolean = false,
    public feature: AuthModuleFeature = null,
    public customerName: string = null,
    public addToStation: boolean = false,
    public permission: AuthPermission = null
  ) {}
}

export interface BuilderMapRideMonitoredPath {
  config: UHereMapPathConfig;
  sourceType?: BuilderActiveRideMonitoredPathSourceType;
}

export class BuilderMapRide {
  traffic = false;
  point: UHereMapAddress = null;
  bounds: UHereMapBounds = null;
  fitCenter: {
    position: UHereMapLatLng;
  } = {
      position: null
    };

  coords: {
    lat: number,
    lng: number
  } = {
      lat: 31.768319,
      lng: 35.21371
    };

  zoom = mapConfig.zoom;
  autoZoom = true;
  markerCenter: {
    position: UHereMapLatLng;
    config: UHereMapPointCenterConfig;
  } = {
      position: null,
      config: {
        zoom: 17
      }
    };

  filter: {
    passengers: boolean,
    stations: boolean
  } = {
      passengers: false,
      stations: true
    };

  markers: {
    stations: any[],
    passengers: any[],
    passengersClear: any[]
  } = {
      stations: [],
      passengers: [],
      passengersClear: []
    };

  paths: UHereMapPathConfig[] = [];

  showMonitoredPaths: boolean = true;
  monitoredPaths: BuilderMapRideMonitoredPath[] = [];
  monitoredPathsMarkers: UHereMapMarkerInfo[][] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface BuilderGetRidesPurchaseOrdersBody {
  routeId: number;
  period: {
    dateFrom: string;
    dateTo: string;
    days: DaysOfWeek[];
  };
}

export interface BuilderRidesPurchaseOrder {
  number: string;
  rows: BuilderRidesPurchaseOrderRow[];
}

export interface BuilderRidesPurchaseOrderRow {
  remainingBudget: number;
  totalBudget: number;
  departmentId: number;
  number: string;
}

export interface BuilderRideOrderConfirmationItem {
  status: RideOrderStatus;
  type: BuilderRideOrderConfirmationItemType;
  [BuilderRideOrderConfirmationItemProp.Disabled]?: boolean;
  [BuilderRideOrderConfirmationItemProp.Readonly]?: boolean;
}

export interface BuilderGetShuttleCompanyContractsBody {
  shuttleCompanyPeriods: {
    shuttleCompanyId: number;
    dateFrom: string;
    dateTo: string;
  }[];
}

export interface BuilderGetShuttleCompanyContractsShuttleCompany {
  shuttleCompanyId: number;
  dateFrom: string;
  dateTo: string;
  contracts: {
    id: string;
    name: string;
  }[];
}

export interface BuilderGetShuttleCompanyContracts {
  shuttleCompanies: BuilderGetShuttleCompanyContractsShuttleCompany[];
}

export interface BuilderAddPassengerToExistStationSaveAction {
  passengerId: number;
  stationId: number;
  destinationId: number;
}

export interface BuilderTuningState {
  data: BuilderGetShuttleCompaniesDetails;
}

export interface BuilderAgendaState {
  shuttleCompanies: BuilderGetShuttleCompanies;
  shuttleCompanyContracts: BuilderGetShuttleCompanyContracts;
}

export interface BuilderFilterState {
  cities: UInputCitiesCity[];
}

export interface BuilderPassengersState {
  mobiles: { [key: string]: string; };
}

export interface BuilderRoutesPassengersInfoState {
  info: { [key: string]: RoutePlannerPassenger[]; };
}

export interface BuilderCustomerDataParams {
  customerId: number;
  types: BuilderCustomerDataType[];
}

export interface BuilderCustomer {
  name: string;
  isOwnedBySc: boolean;
}

export interface BuilderCustomerSupervisorModuleStatus {
  active: boolean;
  featureType: AuthModuleSupervisorsFeatureType;
}

export interface BuilderNewParams {
  routeMode: BuilderRouteMode;
  routeType: RouteType;
  customerId?: number;
}
