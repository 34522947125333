import { ChangeDetectorRef, Component, DestroyRef, HostBinding, inject, OnDestroy, OnInit, signal, effect } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, mergeAll, switchMap, take, tap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import * as moment from 'moment';
import { KeyCode, UInputCitiesCity, UMultiselectItem, UPopupService, USearchFilter, USearchFilterType, USidebarMenuService } from '@shift/ulib';

import {
  HeaderMenuIconValue,
  MasterCustomerDataType,
  VisibleComponent,
  GridHeaderButton,
  GridHeaderButtonValue,
  GridHeaderTemplate,
  HeaderMenuIcon
} from '@app/shared/models';
import {
  CommonService,
  CustomerDataService,
  HeaderMenuIconsService,
  HeaderSearchFiltersService,
  LocalizationService,
  MasterCustomerService,
  TrackingService,
  GridHeaderService,
  HeaderDataService,
  LayoutService,
  CitiesStoreService
} from '@app/shared/services';
import { DeleteRouteComponent } from '@app/shared/components';
import { sequence, shortcut } from '@app/shared/utils';
import { AppConstants } from '@app/shared/constants';
import { RouteNotesService } from '@app/route-notes/services';
import { AuthDataService } from '@app/auth/services';
import {
  AuthCustomer,
  AuthCustomerType,
  AuthModuleBulkChangesFeatureType,
  AuthModuleName,
  AuthModuleRoutesTableFeature,
  AuthUserInfo
} from '@app/auth/models';
import { UserService } from '@app/user/services';
import { FeedFilterService } from '@app/feed/services';
import { FeedFilter, FeedFilterPage, FeedStatus } from '@app/feed/models';
import { PassengersDataService } from '@app/passengers/services';
import { NotesListItem } from '@app/notes/models';
import { ReOptimizationService } from '@app/re-optimization/services';
import { ReOptimizationStatus } from '@app/re-optimization/models';
import { NextYearDataService } from '@app/next-year/services';
import { EmailsDataService } from '@app/emails/services';
import { BulkChangeModalService, BulkChangeService } from '@app/bulk-change/services';
import { BulkChangeAction, BulkChangeShuttleCompanyCloseRidesBody, BulkChangeShuttleCompanyOpenRidesBody, BulkChangeType } from '@app/bulk-change/models';
import { bulkChangeConfig } from '@app/bulk-change/configs';
import { routesConfig } from '@app/routes/configs';
import { RoutesFacade } from '@app/routes/state/facades';
import {
  RouteDailyRow,
  RoutesDailyFilters,
  RoutesDailyFilterType,
  RoutesViewTypeMode,
  RoutesWeeklyFilters,
  RoutesWeeklyFilterType,
  RoutesExportType,
  RoutesInitalData,
  RoutesViewType
} from '@app/routes/models';
import { RoutesAiSuggestionsDataService, RoutesExportModalService, RoutesTableService } from '@app/routes/services';
import { routesComponentConfig } from './routes.component.config';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: [ './routes.component.scss', './routes.component.rtl.scss' ],
  providers: [ BsModalRef, GridHeaderService, CitiesStoreService ]
})
export class RoutesComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses: string = 'routes';

  private deleteModalRef = inject(BsModalRef);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly uSidebarMenuService = inject(USidebarMenuService);
  private readonly routesFacade = inject(RoutesFacade);
  private readonly trackingService = inject(TrackingService);
  private readonly translateService = inject(TranslateService);
  private readonly uPopupService = inject(UPopupService);
  private readonly reOptimizationService = inject(ReOptimizationService);
  private readonly headerSearchFiltersService = inject(HeaderSearchFiltersService);
  private readonly nextYearDataService = inject(NextYearDataService);
  private readonly deleteModalService = inject(BsModalService);
  private readonly localizationService = inject(LocalizationService);
  private readonly routesExportModalService = inject(RoutesExportModalService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly headerMenuIconsService = inject(HeaderMenuIconsService);
  private readonly routesTableService = inject(RoutesTableService);
  private readonly customerDataService = inject(CustomerDataService);
  private readonly masterCustomerService = inject(MasterCustomerService);
  private readonly passengersDataService = inject(PassengersDataService);
  private readonly routeNotesService = inject(RouteNotesService);
  private readonly authDataService = inject(AuthDataService);
  private readonly gridHeaderService = inject(GridHeaderService);
  private readonly citiesStoreService = inject(CitiesStoreService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly userService = inject(UserService);
  private readonly emailsDataService = inject(EmailsDataService);
  private readonly bulkChangeModalService = inject(BulkChangeModalService);
  private readonly bulkChangeService = inject(BulkChangeService);
  private readonly layoutService = inject(LayoutService);
  private readonly routesAiSuggestionsDataService = inject(RoutesAiSuggestionsDataService);
  public readonly commonService = inject(CommonService);
  public readonly feedFilterService = inject(FeedFilterService);

  private defaultBranchIds: number[] = [];
  private readNoteIds: number[] = [];
  private withoutContractCodeTranslation: string;
  private notesRouteData: BehaviorSubject<{ route: RouteDailyRow; notes: NotesListItem[]; }> = new BehaviorSubject<{ route: RouteDailyRow; notes: NotesListItem[]; }>(null);
  private routeParamsSubscription: Subscription;

  readonly #highlightRouteIds = signal<number[]>([]);

  readonly highlightRouteIds = this.#highlightRouteIds.asReadonly();

  viewTypes = [
    { value: RoutesViewType.Daily, name: 'routes.daily.tableViews.daily' },
    { value: RoutesViewType.Weekly, name: 'routes.daily.tableViews.weekly' }
  ];

  showAccompany: boolean;
  selectedDailyFilter: { value: RoutesDailyFilterType; name: string; };
  selectedWeeklyFilter: { value: RoutesWeeklyFilterType; name: string; };
  selectedViewType: { value: RoutesViewType; name: string; };
  routesViewType = RoutesViewType;
  checkedRoutes = [];
  refreshTableData = false;
  visibleRoutesAmount = 0;

  clearWeeklyCheckedItems = false;
  clearDailyCheckedItems = false;
  notesRouteData$: Observable<{ route: RouteDailyRow; notes: NotesListItem[]; }> = this.notesRouteData.asObservable();

  tableAssignmentType: RoutesWeeklyFilterType;

  checkedRoutesFullInfo = [];
  // track table daily, ongoing tracks
  feedFilter = new FeedFilter();
  showNextYearPanel = false;

  columnsFiltered = false;
  resetColumnsFilter = false;

  isArmyCustomer: boolean;
  isMunicipalityCustomer: boolean;
  isSCCustomer: boolean;
  hasMasterCustomerFeature: boolean;

  config;
  initialData: RoutesInitalData;
  modalRef: BsModalRef;
  closeAllPopovers: boolean;
  authUser: AuthUserInfo;
  authCustomer: AuthCustomer;
  reOptimizationSessionId: string;
  reOptimizationRouteIds: number[] = [];
  trackingId: string = routesConfig.trackingId;
  isRtl: boolean = this.localizationService.isRtl();
  visibleComponent = VisibleComponent;
  gridHeaderTemplate: GridHeaderTemplate;
  routesConfig = cloneDeep(routesConfig);

  constructor() {
    effect(() => this.headerMenuIconsService.updateDisabledMenuIcons(this.routesAiSuggestionsDataService.aiSuggestionsLoading()));

    effect(() => {
      if (this.config) {
        if (this.routesAiSuggestionsDataService.aiSuggestionsActive()) {
          this.gridHeaderTemplate = this.config.daily.gridHeaderTemplate.aiSuggestions;
        } else {
          this.gridHeaderTemplate = this.config.daily.gridHeaderTemplate.default;
        }
      }
    });
  }

  ngOnInit() {
    this.layoutService.updateClass('layout_routes');

    this.init();
    this.initGlobalFilters();

    this.initVisibleComponents();
    this.onHeaderAction();
    this.onRouteNotesClose();
    this.onShowReOptimization();

    this.headerDataService.updateShowRoutesViewType(true);
  }

  ngOnDestroy() {
    this.layoutService.updateClass(null);

    this.deleteModalRef.hide();
    this.headerDataService.updateShowRoutesViewType(false);
    this.commonService.updateVisibleComponent(VisibleComponent.Activities, false);
    this.headerMenuIconsService.resetIcons();
  }

  private onBulkHotkeys() {
    of(...this.config.bulkHotkeys.closeRides, ...this.config.bulkHotkeys.openRides)
      .pipe(
        map((data: KeyCode[]) => shortcut(data)),
        mergeAll(),
        sequence(),
        map(keys => keys.map(key => key.code)),
        filter(() => !!this.checkedRoutesFullInfo?.length),
        switchMap(data => {
          const isDailyViewType = this.selectedViewType.value === RoutesViewType.Daily;
          const isOpenRidesPressed = this.config.bulkHotkeys.openRides.some(keys => isEqual(keys, data));
          const minStartDate = moment
            .min(this.checkedRoutesFullInfo.map(route => moment(isDailyViewType ? route.routeStartDate : route.startDate).startOf('day')))
            .format(AppConstants.DATE_FORMAT_ISO);
          const maxEndDate = moment
            .max(this.checkedRoutesFullInfo.map(route => moment(isDailyViewType ? route.routeEndDate : route.endDate).startOf('day')))
            .format(AppConstants.DATE_FORMAT_ISO);
          const activeDate = this.headerDataService.getActiveDate();

          const body: BulkChangeShuttleCompanyCloseRidesBody | BulkChangeShuttleCompanyOpenRidesBody = {
            routeIds: this.checkedRoutesFullInfo.map(row => isDailyViewType ? row.routeId : row.id),
            value: {
              dateFrom: minStartDate,
              dateTo: maxEndDate,
              type: BulkChangeType.Unplanned,
              days: [ moment(activeDate).get('day') ],
              comment: ''
            }
          };

          return isOpenRidesPressed ? this.bulkChangeService.shuttleCompanyOpenRides(body) : this.bulkChangeService.shuttleCompanyCloseRides(body);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => this.updateHighlightRouteIds(this.checkedRoutesFullInfo.map(row => this.selectedViewType.value === RoutesViewType.Daily ? row.routeId : row.id)));
  }

  private setGridHeaderTemplate(viewType: RoutesViewType) {
    if (this.routeParamsSubscription) {
      this.routeParamsSubscription.unsubscribe();
    }

    this.gridHeaderTemplate = viewType === RoutesViewType.Daily ? this.config.daily.gridHeaderTemplate.default : this.config.weekly.gridHeaderTemplate;

    if (viewType === RoutesViewType.Daily) {
      this.routeParamsSubscription = this.activatedRoute.queryParams
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(params => {
          this.gridHeaderTemplate = this.config.daily.gridHeaderTemplate[params && params.feedFilter] || this.config.daily.gridHeaderTemplate.default;
        });
    }
  }

  private onViewTypeValueChange() {
    this.routesFacade.viewTypeValue$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(viewType => {
        this.updateHeaderMenuIcons(viewType);
        this.setGridHeaderTemplate(viewType);
      });
  }

  private updateHeaderMenuIcons(viewType: RoutesViewType) {
    this.headerMenuIconsService.setMenuIconsByTemplate(
      this.config?.[viewType === RoutesViewType.Daily ? 'daily' : 'weekly'].headerMenuIconsTemplate
    );
  }

  private initVisibleComponents() {
    if (this.commonService.showReOptimizationOnLoad()) {
      this.commonService.updateVisibleComponent(VisibleComponent.ReOptimization, true);
    }
  }

  private init() {
    forkJoin([
      this.authDataService.userInfo$.pipe(take(1)),
      this.translateService.get('routes.withoutContractCode')
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([ user, withoutContractCodeTranslation ]) => {
        this.authUser = user;
        this.authCustomer = user.customer;
        this.isArmyCustomer = this.authCustomer.type === AuthCustomerType.Army;
        this.isMunicipalityCustomer = this.authCustomer.type === AuthCustomerType.Municipality;
        this.isSCCustomer = this.authCustomer.type === AuthCustomerType.ShuttleCompany;
        this.showAccompany = !!user.modules[AuthModuleName.Accompany];
        this.tableAssignmentType = this.showAccompany ? RoutesWeeklyFilterType.AccompanyName : RoutesWeeklyFilterType.Driver;
        this.hasMasterCustomerFeature = !!(user.modules[AuthModuleName.RoutesTable] && user.modules[AuthModuleName.RoutesTable][AuthModuleRoutesTableFeature.MasterCustomer]);

        this.routesFacade.weeklySelectedFiltersInit({ type: this.tableAssignmentType });

        this.withoutContractCodeTranslation = withoutContractCodeTranslation;

        this.initSelectedViewType();
        this.initConfig(this.authCustomer.type);
        this.initDailySelectedFiltersType();
        this.initWeeklySelectedFiltersType();
        this.onViewTypeValueChange();
        this.onFeedSelectedFilterChange();
        this.setHeaderIcons();
        this.onNextYear();

        if (
          user.modules?.bulkChanges?.type === AuthModuleBulkChangesFeatureType.ShuttleCompany &&
          this.authDataService.editRoutes()
        ) {
          this.onBulkHotkeys();
        }
      });
  }

  private initGlobalFilters() {
    forkJoin([
      this.userService.getUserBranches().pipe(map(branches => branches.map(branch => branch.id))),
      this.routesTableService.getInitialData()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([ branchIds, initialData ]) => {
        this.defaultBranchIds = branchIds;
        this.initialData = initialData;

        this.setGlobalFilters(this.initialData);
      });
  }

  private onFeedSelectedFilterChange() {
    this.feedFilterService.selectedFilter$
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(selectedFilter => {
        this.feedFilter = selectedFilter;

        if ([ FeedFilterPage.RoutesDaily, FeedFilterPage.RoutesWeekly ].includes(selectedFilter.page)) {
          this.commonService.updateVisibleComponent(VisibleComponent.Feed, true);

          const view = this.viewTypes.find(type => type.value === this.config.viewTypeBySelectedFilterPage[selectedFilter.page]);

          if (view) {
            this.viewTypeChanged(view);
          }

          if (selectedFilter.page === FeedFilterPage.RoutesWeekly && [ FeedStatus.NeededDriver, FeedStatus.NeededShuttleCompany ].includes(selectedFilter.value)) {
            this.updateWeeklySelectedFiltersType(
              { type: this.config.weeklyFilters.find(weeklyFilter => weeklyFilter.value === 'driver').value },
              true
            );
          } else {
            this.updateWeeklySelectedFiltersType(
              {
                type: this.config.weeklyFilters.find(weeklyFilter =>
                  weeklyFilter.value === (this.showAccompany ? RoutesWeeklyFilterType.AccompanyName : RoutesWeeklyFilterType.Driver)
                ).value
              },
              true
            );
          }
        } else {
          this.tableAssignmentType = this.showAccompany ? RoutesWeeklyFilterType.AccompanyName : RoutesWeeklyFilterType.Driver;

          this.updateWeeklySelectedFiltersType({
            type: this.config.weeklyFilters.find(weeklyFilter => weeklyFilter.value === this.tableAssignmentType).value
          });
        }
      });
  }

  private initConfig(customerType: AuthCustomerType) {
    this.config = cloneDeep(routesComponentConfig[customerType] || routesComponentConfig.default);

    this.routesAiSuggestionsDataService.initHeaderMenuIconsTemplates(this.config.daily.headerMenuIconsTemplate, this.config.daily.headerMenuIconsTemplateAiSuggestions);
  }

  private updateViewType(viewType: RoutesViewType) {
    this.routesFacade.viewTypeUpdate(viewType);
  }

  private onRouteNoteAdded() {
    this.routesFacade.dailyRouteNoteAdded$
      .pipe(
        filter(note => this.notesRouteData.value && this.notesRouteData.value.route.routeId === note.routeId && note.createdByDifferentUser),
        switchMap(note => this.routeNotesService.getRouteNote(note.noteId)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(note => {
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: cloneDeep([
            {
              id: note.noteId,
              text: note.message,
              isRead: note.isRead,
              createdBy: note.createdByUserName,
              allowEdit: note.createdByMemberId === this.authUser.person.memberId
            },
            ...this.notesRouteData.value.notes
          ])
        });
      });
  }

  private onRouteNoteEdited() {
    this.routesFacade.dailyRouteNoteEdited$
      .pipe(
        filter(note => this.notesRouteData.value && this.notesRouteData.value.route.routeId === note.routeId && note.modifiedByDifferentUser),
        switchMap(note => this.routeNotesService.getRouteNote(note.noteId)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(editedNote => {
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: this.notesRouteData.value.notes.map(note => note.id === editedNote.noteId ? { ...note, text: editedNote.message } : note)
        });
      });
  }

  private onRouteNoteRemoved() {
    this.routesFacade.dailyRouteNoteRemoved$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(note => this.notesRouteData.value && this.notesRouteData.value.route.routeId === note.routeId)
      )
      .subscribe(removedNote => {
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: cloneDeep([ ...this.notesRouteData.value.notes.filter(note => removedNote.noteId !== note.id) ])
        });
      });
  }

  private initSelectedViewType() {
    this.routesFacade.viewTypeValue$
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(viewTypeValue =>
        this.selectedViewType = this.viewTypes.find(viewType => viewType.value === viewTypeValue)
      );
  }

  private updateWeeklySelectedFiltersType(filters: RoutesWeeklyFilters, updateTableAssignmentType: boolean = false) {
    this.routesFacade.weeklySelectedFiltersUpdate(filters);

    if (updateTableAssignmentType) {
      this.tableAssignmentType = filters.type;
    }
  }

  private initWeeklySelectedFiltersType() {
    this.routesFacade.weeklySelectedFilters$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(selectedFilters => selectedFilters.type)
      )
      .subscribe(selectedFiltersType => this.selectedWeeklyFilter = this.config.weeklyFilters.find(weeklyFilter => weeklyFilter.value === selectedFiltersType));
  }

  private updateDailySelectedFiltersType(filters: RoutesDailyFilters) {
    this.routesFacade.dailySelectedFiltersUpdate(filters);
  }

  private initDailySelectedFiltersType() {
    this.routesFacade.dailySelectedFiltersType$
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(selectedFilterType =>
        this.selectedDailyFilter = this.config.dailyFilters.find(dailyFilter => dailyFilter.value === selectedFilterType)
      );
  }

  private checkHeaderClickedIcon(icon: HeaderMenuIcon) {
    if (this.routesAiSuggestionsDataService.transportationAiSuggestions().length && !this.routesConfig.availableHeaderIconsInAiSuggestionsMode.includes(icon.value as HeaderMenuIconValue)) {
      this.uPopupService.showMessage(
        {
          message: this.routesConfig.dictionary.aiSuggestions.exitAiSuggestions,
          yes: this.routesConfig.dictionary.aiSuggestions.exit,
          no: this.routesConfig.dictionary.no
        },
        () => {
          this.headerMenuIconsService.setMenuIconsByTemplate(this.config?.daily.headerMenuIconsTemplate);
          this.routesAiSuggestionsDataService.disableAiSuggestions();

          if (icon.value !== HeaderMenuIconValue.AiAutoAssignment) {
            this.headerDataService.menuIconClick(icon, true);
          }
        }
      );

      return;
    }

    switch (icon.value) {
      case HeaderMenuIconValue.Refresh: {
        this.refreshTableData = true;
        this.clearCheckedItems();

        break;
      }

      case HeaderMenuIconValue.SendChanges: {
        this.emailsDataService.openEmailsSendShuttleCompaniesChangesModal();

        break;
      }

      case HeaderMenuIconValue.NextYear: {
        this.nextYearDataService.nextYearIconClick();

        break;
      }

      case HeaderMenuIconValue.NewPassenger: {
        this.trackingService.track(`[${this.trackingId}] - Global Header - Plus icon add passenger`);

        this.passengersDataService.openAddEditModal()
          .pipe(take(1))
          .subscribe();

        break;
      }

      case HeaderMenuIconValue.AiAutoAssignment: {
        if (!this.checkedRoutes.length) {
          this.uPopupService.showMessage({
            message: this.routesConfig.dictionary.aiSuggestions.noRoutesSelected,
            yes: this.routesConfig.dictionary.close
          });
        } else {
          this.routesAiSuggestionsDataService.openConfirmModal().content?.action
            .pipe(
              take(1),
              filter(result => !!result),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => this.routesAiSuggestionsDataService.initAiSuggestions(this.checkedRoutesFullInfo, result));
        }

        break;
      }
    }
  }

  private onHeaderAction() {
    this.headerMenuIconsService.menuIconClick$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => this.checkHeaderClickedIcon(value as HeaderMenuIcon));
  }

  private onNextYear() {
    this.nextYearDataService.nextYearActivationStatus$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(nextYearActivationStatus => !!nextYearActivationStatus)
      )
      .subscribe(() => this.setHeaderIcons());

    this.nextYearDataService.openNextYearPanel$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.uSidebarMenuService.updateCollapsed(true);

        this.showNextYearPanel = !this.showNextYearPanel;
      });
  }

  private closeRouteNotes() {
    this.notesRouteData.next(null);
  }

  private setHeaderIcons() {
    this.headerMenuIconsService.setMenuIconsByTemplate(
      this.config['routes-daily' ? 'daily' : 'weekly'].headerMenuIconsTemplate
    );

    this.headerDataService.updateShowGlobalSearch(true);
  }

  private onRouteNotesClose() {
    this.commonService.showRouteNotes$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (!value) {
          this.closeRouteNotes();
        }
      });
  }

  private updateHighlightRouteIds(data: number[]) {
    this.#highlightRouteIds.set(data);

    setTimeout(() => this.#highlightRouteIds.set([]), 500);
  }

  dailyRoutesSelect(selected: any) {
    this.checkedRoutes = selected.routes.map((route: any) => route.routeId);
    this.checkedRoutesFullInfo = selected.routes;

    this.routesAiSuggestionsDataService.onDailyRoutesSelect(selected.routes);
  }

  weeklyRoutesSelect(selected: any) {
    this.checkedRoutes = selected.routes.map((route: any) => route.id);
    this.checkedRoutesFullInfo = selected.routes;
  }

  viewTypeChanged(selectedViewType) {
    this.updateViewType(selectedViewType.value);

    this.changeTrackTable(true);
  }

  changeTrackTable(allowTracking) {
    if (allowTracking) {
      this.trackingChangingTrackTable();
    }
  }

  trackingChangingTrackTable() {
    let message = '';

    switch (this.selectedViewType.value) {
      case RoutesViewType.Daily: {
        message = '[Filter] - daily view';

        break;
      }

      case RoutesViewType.Weekly: {
        message = '[Filter] - weekly view';

        break;
      }
    }

    this.trackingService.track(message);
  }

  clearCheckedItems() {
    switch (this.selectedViewType.value) {
      case RoutesViewType.Daily: {
        this.clearDailyCheckedItems = true;

        break;
      }

      case RoutesViewType.Weekly: {
        this.clearWeeklyCheckedItems = true;

        break;
      }
    }
  }

  onTableRefresh() {
    this.refreshTableData = false;
    this.clearDailyCheckedItems = false;
  }

  deleteRoutes(routes: any, mode: string, route?: any) {
    let activeRoute;

    if (this.checkedRoutesFullInfo.length === 1 && !route) {
      activeRoute = this.checkedRoutesFullInfo[0];
    } else {
      activeRoute = route ? route : null;
    }

    this.deleteModalRef = this.deleteModalService.show(
      DeleteRouteComponent,
      {
        class: 'u-modal u-modal_app-delete-route',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: false,
        keyboard: false,
        initialState: <any>{
          routes,
          activeRoute,
          mode
        }
      });

    this.deleteModalRef.content.action
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(action => {
        if (action) {
          const { type, body } = action;

          if (type === 'save') {
            this.routesTableService.deleteFromTable(body)
              .pipe(first())
              .subscribe(() => {
                this.deleteModalRef.hide();
                this.clearCheckedItems();
              });
          }
        }
      });
  }

  weekViewTypeChanged(type) {
    if (type.selected.value) {
      this.tableAssignmentType = type.selected.value;

      this.updateWeeklySelectedFiltersType({
        type: this.tableAssignmentType
      });
    }

    this.feedFilterService.clearSelectedFilter();
  }

  visibleRoutesAmountChanged(tracksAmount: number) {
    this.visibleRoutesAmount = tracksAmount;

    this.cdRef.detectChanges();
  }

  changeRoutesDailyFilterType(filterType: any) {
    const { selected: { translateKey } } = filterType;
    const filterTypes = {
      dailyRoutes: 'dailyRoutes',
      showAllRoutes: 'showAllRoutes',
      routesWithChanges: 'routesWithChanges'
    };

    switch (translateKey) {
      case filterTypes.showAllRoutes: {
        this.trackingService.track('[Tracks] - filter all tracks');
        break;
      }
      case filterTypes.dailyRoutes: {
        this.trackingService.track('[Tracks] - filter daily tracks');
        break;
      }
      case filterTypes.routesWithChanges: {
        this.trackingService.track('[Tracks] - filter tracks with changes');
        break;
      }
    }

    this.updateDailySelectedFiltersType({
      type: filterType.selected.value
    });
  }

  closeNextYearPanel() {
    this.showNextYearPanel = false;
  }

  resetAllColumnsFilter() {
    this.resetColumnsFilter = true;
  }

  resetColumnsFilterChange(resetColumnsFilter: boolean) {
    this.resetColumnsFilter = resetColumnsFilter;
  }

  columnsFilteredChange(data: boolean) {
    this.columnsFiltered = data;
  }

  private getCitiesFilterLazyItems(name: string, nameToGetParams?: string): Observable<UInputCitiesCity[]> {
    return of(name)
      .pipe(
        switchMap(() => {
          const filterToGetParams = nameToGetParams && this.headerSearchFiltersService.getFilters()?.find(item => item.name === nameToGetParams);
          const params = filterToGetParams?.control?.value || [];

          return this.citiesStoreService.getCitiesBySelectedBranchIds$(params);
        }),
        tap(items => this.headerSearchFiltersService.updateFiltersItems({ [name]: items }))
      );
  }

  private getFilterLazyItems(name: MasterCustomerDataType, nameToGetParams?: string): Observable<UMultiselectItem[]> {
    return of(name)
      .pipe(
        switchMap(value => {
          const filterToGetParams = nameToGetParams
            && (this.headerSearchFiltersService.getFilters() || []).find(item => item.name === nameToGetParams);
          const params = filterToGetParams && filterToGetParams.control.value || [];

          switch (value) {
            case MasterCustomerDataType.Branches: {
              return this.masterCustomerService.getBranches(params);
            }

            case MasterCustomerDataType.Tags: {
              return this.masterCustomerService.getTags(params);
            }

            case MasterCustomerDataType.Departments: {
              return this.masterCustomerService.getDepartments(params);
            }

            case MasterCustomerDataType.SubCustomers: {
              return this.masterCustomerService.getSubCustomers();
            }

            default: {
              return of(null);
            }
          }
        }),
        map(items => items.map(
          (item: { id: number; name: string; }) => ({ name: item.name, value: item.id }))
        ),
        tap(items => this.headerSearchFiltersService.updateFiltersItems({ [name]: items }))
      );
  }

  setGlobalFilters(initialData: RoutesInitalData): void {
    const globalFilters = cloneDeep(routesConfig.globalFilters[this.authCustomer.type] || routesConfig.globalFilters.default)
      .filter(globalFilter =>
        globalFilter.feature ? this.authDataService.checkFeature(globalFilter.feature) :
          globalFilter.features ? this.authDataService.checkFeatures(globalFilter.features) :
            globalFilter.featureGroup ? this.authDataService.checkFeatureGroup(globalFilter.featureGroup) : true
      );

    if (!globalFilters.length) {
      return;
    }

    const filters = [];
    const appliedFilters = this.headerSearchFiltersService.getAppliedFilters();
    const appliedFiltersValues = appliedFilters?.reduce((acc, obj) => ({ ...acc, [obj.name]: obj.value }), {}) || {};
    const currentFilters: USearchFilter[] = this.headerSearchFiltersService.getFilters();
    const currentFiltersValues = this.passengersDataService.generateFiltersValues(currentFilters);
    const selectedFiltersValues = { ...appliedFiltersValues, ...currentFiltersValues };
    const currentFiltersItems = this.passengersDataService.generateFiltersItems(currentFilters);
    const branchesGlobalFilter = globalFilters.find(item => item.name === 'branches');

    if (branchesGlobalFilter) {
      const onlyDefaultFiltersSelected = !Object.keys(selectedFiltersValues)
        .filter(filterKey => filterKey !== 'branches')
        .some(filterKey => !!selectedFiltersValues[filterKey]?.length);

      if (this.defaultBranchIds.length && !selectedFiltersValues?.branches?.length && onlyDefaultFiltersSelected) {
        selectedFiltersValues.branches = this.defaultBranchIds;
      }
    }

    globalFilters.forEach(filterObj => {
      switch (filterObj.name) {
        case 'branches': {
          filterObj.lazyLoadItems = this.hasMasterCustomerFeature ? this.getFilterLazyItems(MasterCustomerDataType.Branches, 'subCustomers') : null;

          filterObj.items = !this.hasMasterCustomerFeature && initialData.branches ? initialData.branches.map(({ id, name }) => ({ value: id, name })) : [];

          break;
        }

        case 'passengers': {
          filterObj.lazyLoadItems = this.customerDataService.getCustomerData({ types: [ filterObj.name ] })
            .pipe(
              map(data => data.passengers.map(({ id, name }) => ({ value: id, name }))),
              tap(passengers => this.headerSearchFiltersService.updateFiltersItems({ passengers }))
            );

          break;
        }

        case 'citiesAreas': {
          filterObj.lazyLoadItems = this.getCitiesFilterLazyItems(filterObj.name, 'branches');

          break;
        }

        case 'bidNumbers': {
          filterObj.lazyLoadItems = this.customerDataService.getCustomerData({ types: [ filterObj.name ] })
            .pipe(
              map(data => [ { name: this.withoutContractCodeTranslation, value: null }, ...data.bidnumbers.map(bidNumber => ({ name: bidNumber, value: bidNumber })) ]),
              tap(bidNumbers => this.headerSearchFiltersService.updateFiltersItems({ bidNumbers }))
            );

          break;
        }

        case 'subCustomers': {
          filterObj.lazyLoadItems = this.hasMasterCustomerFeature ? this.getFilterLazyItems(MasterCustomerDataType.SubCustomers) : null;

          break;
        }

        case 'tags': {
          filterObj.lazyLoadItems = this.hasMasterCustomerFeature ? this.getFilterLazyItems(MasterCustomerDataType.Tags, 'subCustomers') : null;

          filterObj.items = initialData.tags && !this.hasMasterCustomerFeature ? initialData.tags.map(({ id, name }) => ({ value: id, name })) : null;

          break;
        }

        case 'departments': {
          filterObj.lazyLoadItems = this.hasMasterCustomerFeature ? this.getFilterLazyItems(MasterCustomerDataType.Departments, 'subCustomers') : null;

          filterObj.items = initialData.departments && !this.hasMasterCustomerFeature ? initialData.departments.map(({ id, name }) => ({ value: id, name })) : null;

          break;
        }

        case 'schools': {
          filterObj.items = initialData.schools.map(({ id, name, institutionCode }) => ({ value: id, name, subtitle: institutionCode }));

          break;
        }

        default: {
          if (initialData[filterObj.name]) {
            filterObj.items = initialData[filterObj.name].map(({ id, name }) => ({ value: id, name }));
          }

          break;
        }
      }

      const currentFilter = currentFilters.find(item => item.name === filterObj.name);

      filters.push(this.generateGlobalFilter(
        currentFilter?.items || filterObj.items,
        new UntypedFormControl(selectedFiltersValues[filterObj.name] || filterObj.value),
        filterObj.name,
        filterObj.type,
        filterObj.title,
        filterObj.lazyLoadItems,
        filterObj.resetFiltersByNames
      ));
    });

    if (Object.keys(selectedFiltersValues).some(filterKey => !!selectedFiltersValues[filterKey]?.length)) {
      this.headerSearchFiltersService.updateFiltersControls(filters);

      const applySelectedFilters = () => {
        this.headerSearchFiltersService.onFiltersApplied(Object.keys(selectedFiltersValues).map(key => ({
          name: key,
          value: selectedFiltersValues[key]
        })));
      };

      const branchesFilter = filters.find(item => item.name === 'branches');
      const loadDefaultBranches = !!this.defaultBranchIds.length && !currentFiltersItems.branches?.length;

      if (branchesFilter && loadDefaultBranches) {
        forkJoin([
          loadDefaultBranches && branchesFilter?.lazyLoadItems ? branchesFilter.lazyLoadItems.pipe(take(1)) : of(null)
        ])
          .pipe(
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe(() => {
            applySelectedFilters();
          });
      } else {
        applySelectedFilters();
      }
    } else {
      this.headerSearchFiltersService.applyFilters(filters);
    }
  }

  private addGlobalFilterResetting(control: UntypedFormControl, resetFiltersByNames: string[] = [], filterToGetParams?: string) {
    control.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const filters = (this.headerSearchFiltersService.getFilters() || []);

        resetFiltersByNames.forEach(name => {
          const currentFilter = filters.find(item => item.name === name);

          if (currentFilter) {
            currentFilter.items = [];
            currentFilter.lazyLoadItems = [ USearchFilterType.InputCitiesMultiselect, USearchFilterType.InputCitiesSelect ].includes(currentFilter.type) ?
              this.getCitiesFilterLazyItems(currentFilter.name, filterToGetParams) : this.getFilterLazyItems(<MasterCustomerDataType>currentFilter.name, filterToGetParams);
            currentFilter.control.patchValue([]);
          }
        });

        this.headerSearchFiltersService.updateFiltersControls(filters);
      });
  }

  generateGlobalFilter(
    items: any[],
    control: UntypedFormControl,
    name: string,
    type: string,
    title: string,
    lazyLoadItems?: Observable<UMultiselectItem[]>,
    resetFiltersByNames?: string[]
  ) {
    if (resetFiltersByNames) {
      this.addGlobalFilterResetting(control, resetFiltersByNames, name);
    }

    return {
      items: items,
      control: control,
      name: name,
      type: type,
      title: title,
      lazyLoadItems
    };
  }

  openBulkChangePopup(): void {
    if (!this.checkedRoutes.length) { return; }

    this.trackingService.track(`[${bulkChangeConfig.trackingId}] - open Bulk changes`);

    this.closeAllPopovers = true;

    this.modalRef = this.bulkChangeModalService.openModal(
      this.checkedRoutesFullInfo.map(({ id, routeId, routeStartDate, routeEndDate }) =>
        ({ routeId: this.selectedViewType.value === RoutesViewType.Daily ? routeId : id, routeStartDate, routeEndDate })
      )
    );

    this.modalRef.content.action
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ action, data }) => {
        switch (action) {
          case BulkChangeAction.Close: {
            this.modalRef.hide();

            break;
          }

          case BulkChangeAction.CloseStart: {
            if (data.length) {
              this.updateHighlightRouteIds(data);

              this.modalRef.hide();
            }

            break;
          }
        }

        this.closeAllPopovers = false;
      });
  }

  deleteRoute({ routeId, activeRoute, mode }): void {
    const routeIds: number[] = [ routeId ];

    this.deleteRoutes(routeIds, mode, activeRoute);
  }

  openNotes(route: RouteDailyRow) {
    this.commonService.updateVisibleComponent(VisibleComponent.RouteNotes, true);

    this.routeNotesService.getRouteNotes(route.routeId)
      .pipe(first())
      .subscribe(notes => {
        this.notesRouteData.next({
          route,
          notes: notes.map(note => ({
            id: note.noteId,
            text: note.message,
            createdBy: note.createdByUserName,
            isRead: !route.notes.unReadNoteIds.includes(note.noteId),
            allowEdit: note.createdByMemberId === this.authUser.person.memberId
          }))
        });
      });

    this.onRouteNoteAdded();
    this.onRouteNoteEdited();
    this.onRouteNoteRemoved();
  }

  createNewRouteNote(note: NotesListItem) {
    this.routeNotesService.createNote({ routeId: this.notesRouteData.value.route.routeId, message: note.text })
      .pipe(first())
      .subscribe(id => {
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: [
            {
              id,
              isRead: true,
              allowEdit: true,
              text: note.text,
              createdBy: note.createdBy
            },
            ...this.notesRouteData.value.notes
          ]
        });
      });
  }

  editRouteNote(editedNote: NotesListItem) {
    this.routeNotesService.editNoteMessage({ noteId: editedNote.id, message: editedNote.text })
      .pipe(first())
      .subscribe(() =>
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: this.notesRouteData.value.notes.map(note => note.id === editedNote.id ? { ...note, text: editedNote.text } : note)
        })
      );
  }

  removeRouteNote(removedNote: NotesListItem) {
    this.routeNotesService.markNoteAsDone(removedNote.id)
      .pipe(first())
      .subscribe(() =>
        this.notesRouteData.next({
          ...this.notesRouteData.value,
          notes: this.notesRouteData.value.notes.filter(note => note.id !== removedNote.id)
        })
      );
  }

  routeNotesRead(noteIds: number[]) {
    this.readNoteIds = noteIds;
  }

  markNotesAsRead(closeNotes?: boolean) {
    if (this.readNoteIds && this.readNoteIds.length) {
      this.routeNotesService.markNotesAsRead(this.readNoteIds.filter(id => id))
        .pipe(first())
        .subscribe(() => {
          this.routesFacade.routeNotesRead(this.notesRouteData.value.route.routeId, this.readNoteIds);

          if (closeNotes) {
            this.closeRouteNotes();
          } else {
            this.notesRouteData.next({
              ...this.notesRouteData.value,
              notes: cloneDeep(this.notesRouteData.value.notes.map(note => this.readNoteIds.includes(note.id) ? { ...note, isRead: true } : note))
            });
          }

          this.readNoteIds = [];
        });
    } else if (closeNotes) {
      this.closeRouteNotes();
    }
  }

  delete() {
    this.trackingService.track('[Tracks] - delete tracks');

    if (this.checkedRoutes !== null && this.checkedRoutes.length > 0) {
      this.deleteRoutes(
        this.checkedRoutes,
        this.selectedViewType.value === RoutesViewType.Daily ? RoutesViewTypeMode.DailyView : RoutesViewTypeMode.WeeklyView
      );
    }
  }

  exportToPdf() {
    this.trackingService.track('[Global Structure] - click on route PDF');

    if (this.checkedRoutes !== null && this.checkedRoutes.length > 0) {
      this.routesExportModalService.openModal(this.checkedRoutes, RoutesExportType.Pdf);
    }
  }

  onGridHeaderButtonClick(button: GridHeaderButton) {
    this.gridHeaderService.updateButtonClick(button);

    switch (button.value) {
      case GridHeaderButtonValue.ResetFilters: {
        this.resetAllColumnsFilter();

        break;
      }

      case GridHeaderButtonValue.Delete: {
        this.delete();

        break;
      }

      case GridHeaderButtonValue.BulkChange: {
        this.openBulkChangePopup();

        break;
      }

      case GridHeaderButtonValue.PdfRouteDetails: {
        this.exportToPdf();

        break;
      }

      case GridHeaderButtonValue.ReOptimization: {
        this.reOptimizeRoutes();

        break;
      }

      case GridHeaderButtonValue.Cleanup: {
        this.cleanupEmptyStations();

        break;
      }
    }
  }

  private reOptimizeRoutes() {
    this.reOptimizationService.reOptimize(this.checkedRoutesFullInfo.map(route => route.rideId))
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => this.showReOptimizationPopup(res?.status));

    this.trackingService.track(`[${bulkChangeConfig.trackingId}] - click on Re-Optimization`);
  }

  private cleanupEmptyStations() {
    this.reOptimizationService.cleanUp(this.checkedRoutesFullInfo.map(route => route.rideId))
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => this.showReOptimizationPopup(res?.status));

    this.trackingService.track(`[${bulkChangeConfig.trackingId}] - click on Rides with empty stations`);
  }

  private showReOptimizationPopup(status: ReOptimizationStatus) {
    if (status === ReOptimizationStatus.AnotherSessionAlreadyInProgress) {
      this.uPopupService.showMessage(
        {
          message: routesConfig.dictionary.reOptimization.alreadyRunningMessage,
          yes: routesConfig.dictionary.close
        },
        null
      );
    } else {
      this.uPopupService.showMessage(
        {
          message: routesConfig.dictionary.reOptimization.popupMessage,
          yes: routesConfig.dictionary.close
        },
        () => {
          this.feedFilterService.clearSelectedFilter();
          this.clearCheckedItems();
        });
    }
  }

  closeReOptimizationSummary() {
    this.commonService.updateVisibleComponent(VisibleComponent.ReOptimization, false);
    this.commonService.updateVisibleComponent(VisibleComponent.Feed, true);

    this.reOptimizationRouteIds = [];

    this.updateDailySelectedFiltersType({
      type: RoutesDailyFilterType.DailyRoutes
    });
  }

  onSelectReOptimizationSession(sessionId: string) {
    this.reOptimizationSessionId = sessionId;
  }

  onLoadReOptimizationChanges(routeIds: number[]) {
    this.reOptimizationRouteIds = routeIds;
  }

  private onShowReOptimization() {
    this.commonService.showReOptimization$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(showReOptimization => !!showReOptimization)
      )
      .subscribe(() => {
        this.reOptimizationRouteIds = [];
        this.reOptimizationSessionId = null;

        this.updateDailySelectedFiltersType({
          type: RoutesDailyFilterType.ShowAllRoutes
        });
      });
  }

  onExitAiSuggestions() {
    this.headerMenuIconsService.setMenuIconsByTemplate(this.config?.daily.headerMenuIconsTemplate);
    this.routesAiSuggestionsDataService.disableAiSuggestions();
  }
}
