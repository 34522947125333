<u-modal-entity
  class="u-modal-entity_app-passengers-generic"
  [title]="title"
  [buttons]="buttons"
  [isRtl]="isRtl"
  [formChanged]="addEditForm.dirty"
  (action)="checkActions($event)"
>
  <tabset
    class="u-tabset-pills u-tabset-pills_modal u-tabset-pills_app-contracts"
    type="pills"
  >
    <tab
      [id]="passengersTabName.Details"
      [heading]="'passengers.addEdit.details' | translate"
      [disabled]="addEditForm.invalid"
      (selectTab)="onTabSelect($event.id, addEditForm)"
    >
      <div
        *ngIf="passengersAddEditDataService.selectedTab() === passengersTabName.Details"
        class="u-modal__body"
        tabindex="-1"
      >
        <app-passengers-generic-add-edit-info
          [detailsForm]="addEditForm.get('details')"
          [editPassenger]="editPassenger"
          (updatePerson)="updatePersonExist($event)"
          (updatePassenger)="updatePassengerExist($event)"
        ></app-passengers-generic-add-edit-info>
      </div>
    </tab>
    <tab
      [id]="passengersTabName.Settings"
      [heading]="'passengers.addEdit.settings' | translate"
      [disabled]="addEditForm.invalid"
      (selectTab)="onTabSelect($event.id, addEditForm)"
    >
      <div
        *ngIf="passengersAddEditDataService.selectedTab() === passengersTabName.Settings"
        class="u-modal__body"
        tabindex="-1"
      >
        <app-passengers-settings
          [settingsForm]="addEditForm.get('settings')"
          [initialData]="editPassenger.initialData"
        ></app-passengers-settings>
      </div>
    </tab>
    <tab
      [id]="passengersTabName.Logs"
      [heading]="'passengers.addEdit.history' | translate"
      [disabled]="addEditForm.invalid && passengersAddEditDataService.selectedTab() !== passengersTabName.Logs"
      (selectTab)="onTabSelect($event.id, addEditForm)"
    >
      <div
        *ngIf="passengersAddEditDataService.selectedTab() === passengersTabName.Logs"
        class="u-modal__body"
        tabindex="-1"
      >
        <app-passengers-history
          [rows]="passengersAddEditDataService.passengersHistoryRows()"
          [initialData]="editPassenger.initialData"
        ></app-passengers-history>
      </div>
    </tab>
  </tabset>
</u-modal-entity>
