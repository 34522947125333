import { USelectSItem } from '@shift/ulib';

import {
  ActivityType,
  ActivityFiltersType,
  ActivityAdditionalStatus,
  ActivityFiltersStatusType,
  ActivityAdditionalDataReOptimizationType
} from '@app/activities/models';
import { RouteSaveStatus } from '@app/routes/models';

const DICTIONARY_PATH: string = 'activities';

const statusTypes: USelectSItem[] = [
  { value: ActivityFiltersStatusType.Active, name: 'activities.filters.statusTypes.active' },
  { value: ActivityFiltersStatusType.Archived, name: 'activities.filters.statusTypes.archived' }
];

const types: USelectSItem[] = [
  { value: ActivityFiltersType.TempComment, name: 'activities.filters.types.tempComment' },
  { value: ActivityFiltersType.BulkChanges, name: 'activities.filters.types.bulkAction' },
  { value: ActivityFiltersType.ChangeToRoute, name: 'activities.filters.types.changeToRoute' },
  { value: ActivityFiltersType.TemplateFromRoute, name: 'activities.filters.types.templateFromRoute' },
  { value: ActivityFiltersType.RoutesFromTemplates, name: 'activities.filters.types.routesFromTemplates' },
  { value: ActivityFiltersType.NewYear, name: 'activities.filters.types.newYear' },
  { value: ActivityFiltersType.PassengerRemoval, name: 'activities.filters.types.passengerRemoval' },
  { value: ActivityFiltersType.PassengerUpdate, name: 'activities.filters.types.passengerUpdate' },
  { value: ActivityFiltersType.ReOptimization, name: 'activities.filters.types.reOptimization' },
  { value: ActivityFiltersType.ReOptimizationCleanup, name: 'activities.filters.types.reOptimizationCleanup' },
  { value: ActivityFiltersType.ManualScRoutesSync, name: 'activities.filters.types.sendToSC' },
  { value: ActivityFiltersType.AiTransportationSuggestions, name: 'activities.filters.types.aiAssignments' }
];

const saveTypes: USelectSItem[] = [
  { value: ActivityAdditionalStatus.Success, name: 'activities.filters.saveTypes.savedSuccessfully' },
  { value: ActivityAdditionalStatus.Failed, name: 'activities.filters.saveTypes.savingFailed' },
  { value: ActivityAdditionalStatus.PartialSuccess, name: 'activities.filters.saveTypes.savingFailedPartially' }
];

export const activitiesConfig = {
  defaultDateVisibility: true,
  dictionary: {
    notAffectingTheRoute: `${DICTIONARY_PATH}.notAffectingTheRoute`,
    wasNotAppliedOnTheRoute: `${DICTIONARY_PATH}.wasNotAppliedOnTheRoute`,
    destinationWasAdded: `${DICTIONARY_PATH}.destinationWasAdded`
  },
  counterTypes: [
    ActivityType.TempComment,
    ActivityType.RouteBulkChange,
    ActivityType.RouteAuditCompleted,
    ActivityType.RouteSaveFail,
    ActivityType.TemplateFromRoute,
    ActivityType.RoutesFromTemplates,
    ActivityType.RouteRestored,
    ActivityType.RouteCancelled,
    ActivityType.RouteNewYearDuplicated,
    ActivityType.TemplateNewYearDuplicated,
    ActivityType.RoutePassengerUpdated,
    ActivityType.RoutePassengerRemoved,
    ActivityType.ReOptimization,
    ActivityType.ManualScRoutesSync,
    ActivityType.AiTransportationSuggestions
  ],
  saveStatusTypes: [ ActivityType.RouteSaveSuccess, ActivityType.RouteSaveFail, ActivityType.RouteBulkSaveFail ],
  saveStatusByType: {
    [ActivityType.RouteSaveSuccess]: RouteSaveStatus.Successful,
    [ActivityType.RouteSaveFail]: RouteSaveStatus.Failed,
    [ActivityType.RouteBulkSaveFail]: RouteSaveStatus.Failed
  },
  lockedTypes: [ ActivityType.RouteLocked, ActivityType.RouteUnlocked ],
  lockedByType: {
    [ActivityType.RouteLocked]: true,
    [ActivityType.RouteUnlocked]: false
  },
  filters: {
    types: {
      [ActivityFiltersType.TempComment]: [ ActivityType.TempComment ],
      [ActivityFiltersType.BulkChanges]: [ ActivityType.RouteBulkChange ],
      [ActivityFiltersType.ChangeToRoute]: [ ActivityType.RouteSaveFail, ActivityType.RouteAuditCompleted, ActivityType.RouteRestored, ActivityType.RouteCancelled ],
      [ActivityFiltersType.TemplateFromRoute]: [ ActivityType.TemplateFromRoute ],
      [ActivityFiltersType.RoutesFromTemplates]: [ ActivityType.RoutesFromTemplates ],
      [ActivityFiltersType.NewYear]: [ ActivityType.RouteNewYearDuplicated, ActivityType.TemplateNewYearDuplicated ],
      [ActivityFiltersType.PassengerRemoval]: [ ActivityType.RoutePassengerRemoved ],
      [ActivityFiltersType.PassengerUpdate]: [ ActivityType.RoutePassengerUpdated ],
      [ActivityFiltersType.ReOptimization]: [ ActivityAdditionalDataReOptimizationType.ReOptimization ],
      [ActivityFiltersType.ReOptimizationCleanup]: [ ActivityAdditionalDataReOptimizationType.Cleanup ],
      [ActivityFiltersType.ManualScRoutesSync]: [ ActivityType.ManualScRoutesSync ],
      [ActivityFiltersType.AiTransportationSuggestions]: [ ActivityType.AiTransportationSuggestions ]
    },
    availableSaveTypes: [ ActivityType.RouteBulkChange, ActivityType.RoutesFromTemplates, ActivityType.TemplateFromRoute ],
    availableSaveStatusTypes: {
      [ActivityType.RouteAuditCompleted]: ActivityAdditionalStatus.Success,
      [ActivityType.RouteSaveFail]: ActivityAdditionalStatus.Failed
    },
    options: {
      statusTypes,
      types,
      saveTypes
    }
  }
};
