import { AuthCustomerType } from '@app/auth/models';
import { ActivityAdditionalStatus, ActivityRouteFromTemplateCreationResult } from '@app/activities/models';

const dictionary = {
  types: 'activities.types',
  doneBy: 'general.doneBy',
  emailIsExpired: 'activities.emailIsExpired',
  notificationWillBeArchived: 'activities.notificationWillBeArchived',
  fileIsExpired: 'activities.fileIsExpired',
  routesSent: 'activities.routesSent',
  routesFailed: 'activities.routesFailed',
  statuses: {
    success: 'activities.statuses.success',
    failed: 'activities.statuses.failed',
    partialSuccess: 'activities.statuses.partialSuccess'
  },
  reOptimizationStatuses: 'activities.reOptimizationStatuses',
  bulk: {
    statuses: 'activities.bulk.statuses',
    changeSubject: {
      RideCancel: 'activities.bulk.changeSubject.rideCancel',
      RideRestore: 'activities.bulk.changeSubject.rideRestore',
      AccompanySet: 'activities.bulk.changeSubject.accompanySet',
      AccompanyRemove: 'activities.bulk.changeSubject.accompanyRemove',
      AccompanySwap: 'activities.bulk.changeSubject.accompanySwap',
      DriverChange: 'activities.bulk.changeSubject.driverChange',
      DriverRemove: 'activities.bulk.changeSubject.driverRemove',
      DriverSwap: 'activities.bulk.changeSubject.driverSwap',
      TimeChange: 'activities.bulk.changeSubject.timeChange',
      PassengerMoveToNewStation: 'activities.bulk.changeSubject.passengerMoveToNewStation',
      PassengerMoveToNewTarget: 'activities.bulk.changeSubject.passengerMoveToNewTarget',
      PassengerRemove: 'activities.bulk.changeSubject.passengerRemove',
      BranchNameChanged: 'activities.bulk.changeSubject.branchNameChanged',
      BranchAddressChanged: 'activities.bulk.changeSubject.branchAddressChanged',
      YitChange: 'activities.bulk.changeSubject.yitChange',
      PassengerUpdate: 'activities.bulk.changeSubject.passengerUpdate',
      AssignedAccompanyCost: 'activities.bulk.changeSubject.assignedAccompanyCost',
      CarChange: 'activities.bulk.changeSubject.carChange',
      CarRemove: 'activities.bulk.changeSubject.carRemove',
      ShuttleCompanyChange: 'activities.bulk.changeSubject.shuttleCompanyChange',
      ShuttleCompanyRemove: 'activities.bulk.changeSubject.shuttleCompanyRemove',
      ExecutionCostChange: 'activities.bulk.changeSubject.executionCostChange',
      AssignedShuttleCompanyCost: 'activities.bulk.changeSubject.assignedShuttleCompanyCost',
      ShuttleCompanyCloseRides: 'activities.bulk.changeSubject.shuttleCompanyCloseRides',
      ShuttleCompanyOpenRides: 'activities.bulk.changeSubject.shuttleCompanyOpenRides'
    },
    description: 'activities.bulk.description',
    routes: 'activities.bulk.routes'
  },
  templateFromRoute: {
    statuses: 'activities.templateFromRoute.statuses'
  },
  routesFromTemplates: {
    statuses: 'activities.routesFromTemplates.statuses',
    shuttleCompanyHasMoreThanSingleContractForSelectedPeriod: 'activities.routesFromTemplates.shuttleCompanyHasMoreThanSingleContractForSelectedPeriod'
  },
  routeRestoredAndRouteCancelledStatuses: {
    [ActivityAdditionalStatus.Success]: 'activities.statuses.success',
    [ActivityAdditionalStatus.Failed]: 'activities.statuses.failed',
    [ActivityAdditionalStatus.PartialSuccess]: 'activities.statuses.partialSuccess'
  },
  rideRestoration: 'activities.rideRestoration',
  rideCancelation: 'activities.rideCancelation',
  routeDuplicated: 'activities.routeDuplicated',
  templateDuplicated: 'activities.templateDuplicated',
  somePassengersWereRemoved: 'activities.somePassengersWereRemoved',
  passengerChanges: 'activities.passengerChanges',
  aiSuggestions: {
    statuses: 'activities.aiSuggestions.statuses',
    changeSubject: {
      VehicleAssignment: 'activities.aiSuggestions.changeSubject.vehicleAssignment',
      DriverAssignment: 'activities.aiSuggestions.changeSubject.driverAssignment',
      ShuttleCompanyAssignment: 'activities.aiSuggestions.changeSubject.shuttleCompanyAssignment'
    },
    description: 'activities.aiSuggestions.description',
    routes: 'activities.aiSuggestions.routes'
  }
};

const activitiesItemComponent = {
  dictionary,
  processedTemplatesSuccessCreationResults: [
    ActivityRouteFromTemplateCreationResult.Success,
    ActivityRouteFromTemplateCreationResult.ShuttleCompanyHasMoreThanSingleContractForSelectedPeriod
  ]
};

const activitiesItemComponentArmy = {
  ...activitiesItemComponent,
  dictionary: {
    ...activitiesItemComponent.dictionary,
    bulk: {
      ...activitiesItemComponent.dictionary.bulk,
      changeSubject: {
        ...activitiesItemComponent.dictionary.bulk.changeSubject,
        BranchNameChanged: 'activities.bulk.changeSubject.branchNameChangedArmy',
        BranchAddressChanged: 'activities.bulk.changeSubject.branchAddressChangedArmy'
      }
    }
  }
};

export const activitiesItemComponentConfig = {
  default: activitiesItemComponent,
  [AuthCustomerType.Army]: activitiesItemComponentArmy
};
