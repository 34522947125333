import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { AddNewTempCommentBody, GetTempCommentApplicableToParams, RouteTempCommentApplicableTo } from '@app/routes/models';
import {
  Activity,
  ActivityBase,
  ActivitiesAllParams,
  ActivitiesLiveParams,
  ActivitiesShuttleCompanyChanges,
  ActivityLive,
  ActivityRoute,
  ActivitiesRouteDailyParams
} from '@app/activities/models';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  private BASE_PATH: string = 'Activities';

  constructor (
    private apiService: ApiService
  ) {}

  getTempCommentApplicableTo(params: GetTempCommentApplicableToParams): Observable<RouteTempCommentApplicableTo[]> {
    return this.apiService.get(`${this.BASE_PATH}/TempComment/ApplicableTo`, params);
  }

  addNewTempComment(body: AddNewTempCommentBody): Observable<void> {
    return this.apiService.post(`${this.BASE_PATH}/TempComment`, body);
  }

  getAllActivities(params: ActivitiesAllParams): Observable<ActivityBase[]> {
    return this.apiService.get(`${this.BASE_PATH}/All`, params);
  }

  getRouteTemplateActivities(routeId: number): Observable<ActivityBase[]> {
    return this.apiService.get(`${this.BASE_PATH}/RouteTemplates/All`, { routeId });
  }

  getActivity(activityId: number): Observable<Activity> {
    return this.apiService.get(`${this.BASE_PATH}`, { activityId });
  }

  getRouteDailyActivities(params: ActivitiesRouteDailyParams): Observable<ActivityRoute[]> {
    return this.apiService.post(`${this.BASE_PATH}/GetDaily`, params);
  }

  getRouteTemplatesActivitiesState(routeIds: number[]): Observable<ActivityRoute[]> {
    return this.apiService.post(`${this.BASE_PATH}/RouteTemplates/GetReadStates`, { routeIds });
  }

  getLiveActivities(params: ActivitiesLiveParams): Observable<ActivityLive[]> {
    return this.apiService.get(`${this.BASE_PATH}/List`, params);
  }

  deleteActivity(activityId: number): Observable<void> {
    return this.apiService.delete(`${this.BASE_PATH}`, { activityId });
  }

  archiveActivity(activityId: number): Observable<void> {
    return this.apiService.put(`${this.BASE_PATH}/Archive`, { activityId });
  }

  getShuttleCompanyChanges(activityId: number, cacheKey: string): Observable<ActivitiesShuttleCompanyChanges> {
    return this.apiService.get(`${this.BASE_PATH}/ShuttleCompanyChanges`, { activityId, cacheKey });
  }

  downloadErrorsExcel(params: { cacheKey: string; }) {
    return this.apiService.getBlob(`${this.BASE_PATH}/ErrorsExcel`, 'blob', params);
  }
}
