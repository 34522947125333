import { ChangeDetectionStrategy, Component, computed, EventEmitter, HostBinding, inject, OnInit, Output, signal } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { cloneDeep } from 'lodash';

import { RoutesOptionsCheckbox, RoutesTransportationAiSuggestionsType } from '@app/routes/models';
import { AuthCustomerType, AuthModuleName, AuthModuleRoutesTableFeature, AuthModuleRoutesTableFeatureType } from '@app/auth/models';
import { AuthDataSnapshotService } from '@app/auth/services';
import { routesAiSuggestionsConfirmComponentConfig } from './routes-ai-suggestions-confirm.component.config';

@Component({
  selector: 'app-routes-ai-suggestions-confirm',
  templateUrl: './routes-ai-suggestions-confirm.component.html',
  styleUrls: [ './routes-ai-suggestions-confirm.component.scss', './routes-ai-suggestions-confirm.component.rtl.scss' ],
  providers: [ AuthDataSnapshotService ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoutesAiSuggestionsConfirmComponent implements OnInit {
  @Output() action = new EventEmitter<RoutesTransportationAiSuggestionsType[] | null>();

  @HostBinding('class') hostClasses: string = 'routes-ai-suggestions-confirm';

  private readonly bsModalRef = inject(BsModalRef);
  private readonly authDataSnapshotService = inject(AuthDataSnapshotService);

  readonly #config = signal(cloneDeep(routesAiSuggestionsConfirmComponentConfig));
  readonly #checkboxes = signal<RoutesOptionsCheckbox[]>([]);
  readonly #showExplanation = signal(false);

  readonly config = this.#config.asReadonly();
  readonly checkboxes = this.#checkboxes.asReadonly();
  readonly showExplanation = this.#showExplanation.asReadonly();
  readonly buttons = computed(() => {
    const button = this.#config().continueButton;

    if (this.#checkboxes().every(item => !item.checked)) {
      return [ { ...button, disabled: true } ];
    }

    return [ button ];
  });

  ngOnInit() {
    this.init();
  }

  private init() {
    const userInfo = this.authDataSnapshotService.userInfo();
    const customerType = (
      userInfo.modules[AuthModuleName.RoutesTable]?.[AuthModuleRoutesTableFeature.Type] === AuthModuleRoutesTableFeatureType.ShuttleCompany
        ? AuthCustomerType.ShuttleCompany
        : userInfo.customer.selfShuttleCompanyId ? 'selfShuttleCompany' : 'default'
    );

    this.#checkboxes.set(cloneDeep(this.#config().aiAssignmentOptions[customerType]));
  }

  save() {
    this.bsModalRef.hide();

    this.action.emit(this.checkboxes().filter(item => item.checked).map(item => item.value));
  }

  close() {
    this.bsModalRef.hide();

    this.action.emit(null);
  }

  updateCheckbox(index: number, checked: boolean) {
    this.#checkboxes.update((checkboxes) => checkboxes.map((checkbox, i) => i === index ? { ...checkbox, checked } : checkbox));
  }

  onShowExplanationChange() {
    this.#showExplanation.set(!this.showExplanation());
  }
}

