import { ContactType } from '@app/shared/models';

export class InputContact {
  role: string = '';
  name: string = '';
  groupId: number = null;
  includeForUpdateEmails?: boolean;
  sendAccountingDocuments?: boolean;
  contacts: InputContactItem[] = [];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class InputContactItem {
  id?: number;
  type: ContactType;
  contact: string = '';
  isConfirmed?: boolean;
  validationErrors?: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
}
