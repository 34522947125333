import { inject, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { RouteDailyRow } from '@app/routes/models';
import { RoutesDailyFineComponent } from '@app/routes/components';

@Injectable()
export class RoutesDailyFineModalService {
  private readonly bsModalService = inject(BsModalService);

  openModal(data: RouteDailyRow) {
    this.bsModalService.show(
      RoutesDailyFineComponent,
      {
        class: 'u-modal u-modal_app-routes-daily-fine',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          data
        }
      }
    );
  }
}
