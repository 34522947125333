const DICTIONARY_PATH: string = 'shared.inputContacts';

export const inputContactsComponentConfig = {
  dictionary: {
    title: `${DICTIONARY_PATH}.title`,
    newContact: `${DICTIONARY_PATH}.newContact`,
    contact: `${DICTIONARY_PATH}.contact`,
    role: `${DICTIONARY_PATH}.role`,
    name: `${DICTIONARY_PATH}.name`,
    includeForUpdateEmails: `${DICTIONARY_PATH}.includeForUpdateEmails`,
    sendAccountingDocuments: `${DICTIONARY_PATH}.sendAccountingDocuments`
  }
};
