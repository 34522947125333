import { Moment } from 'moment';
import { UInputCitiesCityLocation, UThreeDotsPopoverItem } from '@shift/ulib';

import { ApiBaseResponse, GridColumn } from '@app/shared/models';
import { AuthCustomerType, AuthModuleFeature, AuthOptionalModule } from '@app/auth/models';
import { RouteLockState } from '@app/route-lock-state/models';
import { ActivityLive } from '@app/activities/models';
import {
  RouteDirection,
  RouteSaveStatus,
  RoutesRidePassengerDetails,
  RoutesRidePassengersCustomer,
  RouteType,
  RideCostType,
  RideStationEntranceStatus,
  RouteNoteUpdated,
  RouteNoteAdded,
  RoutesChangesAccompanySourceStationType,
  RoutesChangeAccompanyCostCalculationCostType
} from '@app/routes/models';

export enum RouteDailyRideStatus {
  New = 'New',
  Ongoing = 'Ongoing',
  OngoingMonitored = 'OngoingMonitored',
  Finished = 'Finished',
  FinishedMonitored = 'FinishedMonitored',
  Cancelled = 'Cancelled'
}

export enum RouteDailyYitCtrlStatus {
  NotSent,
  Sent,
  SendingFailed,
  DriverOrVehicleAssigned,
  FailedToAssignDriverOrCar
}

export enum RoutesDailyFilterType {
  ShowAllRoutes,
  RoutesWithChanges,
  RoutesWithoutShuttleCompany,
  DailyRoutes,
  RoutesWithAlerts,
  RegularEducationDepartmentRoutes,
  SpecialEducationDepartmentRoutes
}

export enum RouteDailyRowProp {
  PassengerDetails = 'passengerDetails',
  PassengersCustomers = 'passengersCustomers',
  Drivers = 'drivers',
  DisplayShuttleCompany = 'displayShuttleCompany',
  DisplayAccompanyName = 'displayAccompanyName',
  ThreeDotsMenuItems = 'threeDotsMenuItems',
  CostType = 'costType',
  Cost = 'cost',
  Disabled = 'disabled'
}

export enum RoutesDailyApprovalsStatus {
  Approved = 'approved',
  NotApproved = 'notApproved',
  PartlyApproved = 'partlyApproved'
}

export interface RoutesDailyFilters {
  type: RoutesDailyFilterType;
}

export interface RoutesDailyParams {
  date: string;
  direction: RouteDirection;
  customerId?: number;
  isNextYear?: boolean;
  filterType?: RoutesDailyFilterType;
  searchText?: string;
  schoolIds?: number[];
  tagIds?: number[];
  classTypeIds?: number[];
  passengerIds?: number[];
  departmentIds?: number[];
  shiftIds?: number[];
  routeIds?: number[];
  branchIds?: number[];
  bidNumbers?: string[];
  cities?: UInputCitiesCityLocation[];
  masterSubCustomerIds?: number[];
}

export interface RoutesDaily extends ApiBaseResponse {
  value: RouteDaily[];
}

export interface RouteDailyPlannedAccompany {
  value: number;
  name: string;
  costType: RoutesChangeAccompanyCostCalculationCostType;
  costPerHour: number;
  hours: number;
  sourceType: RoutesChangesAccompanySourceStationType;
  returnHome: boolean;
}

export interface RouteDailyAccompany extends RouteDailyPlannedAccompany {
  cost: number;
}

export interface RouteDaily {
  accompany: RouteDailyAccompany;
  active: boolean;
  cancelled: boolean;
  car: {
    carTypeId: number;
    carTypeName: string;
    value: number;
    name: string;
  };
  carType: {
    seatsCount: number;
    keyword: string;
    value: number;
    name: string;
  };
  carTypeName: string;
  code: number;
  days: number[];
  daysCount: number;
  department: {
    value: number;
    name: string;
  };
  direction: number;
  enabledCancelBtn: boolean;
  gotToDestinationHour: string;
  hideButtons: boolean;
  isFinished: boolean;
  isFullManualRide: boolean;
  isSelfShuttleCompany: boolean;
  isStartTimePast: boolean;
  monitoringStatus: string;
  name: string;
  needAccompany: boolean;
  ownerName: string;
  plannedAccompany: RouteDailyPlannedAccompany;
  plannedNeedAccompany: boolean;
  plannedRideEndDateTime: string;
  plannedRideStartDateTime: string;
  plannedTotalPassengers: number;
  requiredRecalculation: boolean;
  rideEndDateTime: string;
  rideId: number;
  rideStartDateTime: string;
  rideStatus: string;
  routeCanceled: boolean;
  routeEndDate: string;
  routeId: number;
  routeStartDate: string;
  routeStartTime: Moment;
  routeCustomerId: number;
  shuttleCompany: {
    hasYit: boolean;
    value: number;
    name: string;
    costType: RideCostType;
    cost: number;
    executionCost: number;
    profit: number;
    profitPercentage: number;
    rideIsClosed: boolean;
  };
  subShuttleCompany: {
    value: number;
    name: string;
    executionCost: number;
  };
  supervisor: {
    value: number;
    name: string;
  };
  totalPassengers: number;
  totalPassengerCustomers: number;
  totalStations: number;
  yitLastUpdate: string;
  yitStatus: RouteDailyYitCtrlStatus;
  ctrlLastUpdate: string;
  ctrlStatus: RouteDailyYitCtrlStatus;
  locked: boolean;
  lockedByUserId: number;
  distance: number;
  duration: string;
  comments: string;
  routeCustomerName: string;
  driver: {
    approvals?: {
      actual: number;
      expected: number;
      rejected: boolean;
    };
    value: number;
    name: string;
  };
  region: string;
  orderedBy: string;
  purchaseOrder: string;
  timeTrackingStartDateTime: string;
  timeTrackingEndDateTime: string;
  timeTrackingDuration: string;
  bidNumber: string;
  regulationNumber: string;
  routeType: RouteType;
  orderPurpose: string;
  allowEmptyStations: boolean;
  isOwnedBySc: boolean;
}

export interface RouteDailyItem extends RouteDaily {
  stationEntranceActualArriveDateTime?: string;
  stationEntrance?: number;
  activities?: ActivityLive[];
  stationEntranceStatus?: RideStationEntranceStatus;
  gapInMinutes?: number;
  actualArriveDateTime?: string;
  saveStatus?: RouteSaveStatus;
  dateCountShow?: boolean;
  lockState?: RouteLockState;
  notes?: {
    anyNote: boolean;
    unReadNoteIds: number[];
  };
}

export interface RouteDailyRow extends RouteDailyItem {
  [RouteDailyRowProp.PassengerDetails]?: RoutesRidePassengerDetails[];
  [RouteDailyRowProp.DisplayShuttleCompany]: string;
  [RouteDailyRowProp.DisplayAccompanyName]: string;
  [RouteDailyRowProp.ThreeDotsMenuItems]: UThreeDotsPopoverItem[];
  [RouteDailyRowProp.CostType]: string;
  [RouteDailyRowProp.Cost]: number;
  [RouteDailyRowProp.PassengersCustomers]?: RoutesRidePassengersCustomer[];
  [RouteDailyRowProp.Disabled]?: boolean;
}

export interface RouteDailyActivitiesUpdateItem {
  routeId: number;
  activities: ActivityLive[];
  saveStatus: RouteSaveStatus;
  lockState: RouteLockState;
  locked: boolean;
}

export interface RouteDailyUpdateRouteNoteAdded extends RouteNoteAdded {
  createdByDifferentUser?: boolean;
}

export interface RouteDailyUpdateRouteNotesRead {
  routeId: number;
  noteIds: number[];
}

export interface RouteDailyUpdateRouteNoteUpdated extends RouteNoteUpdated {
  modifiedByDifferentUser?: boolean;
}

export interface RoutesDailyColumn extends GridColumn {
  exportPropNameAlias?: string;
  hideColumn?: boolean;
  filterType?: string;
  trackingName?: string;
  dataFormat?: string;
  displayFormat?: string;
  exportPropName?: string;
  hideable?: boolean;
  custom?: boolean;
  filterEmptyValue?: string;
  cellTemplateName?: string;
  filterTemplateName?: string;
  filterTypeItemsFnName?: string;
  filterTypeItemsSearchFnName?: string;
  filterSortingFunctionName?: string;
  comparatorName?: string;
  filterEmptyValueName?: string;
  initialVisibilityFeature?: AuthModuleFeature;
  optionalModule?: AuthOptionalModule;
  exceptCustomerType?: AuthCustomerType;
  customerType?: AuthCustomerType;
  maxWidthRtl?: number;
  minWidthRtl?: number;
  requiredRecalculationWidth?: number;
  requiredRecalculationMinWidth?: number;
}
