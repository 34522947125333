import { inject, Injectable, signal, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart, ResolveStart, Router } from '@angular/router';
import { toObservable } from '@angular/core/rxjs-interop';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { USidebarMenuService } from '@shift/ulib';

import { AppConstants } from '@app/shared/constants';
import {
  HeaderDashboard,
  HeaderDashboardDetails,
  HeaderMenuIcon,
  HeaderMenuIconChild,
  HeaderMenuIconValue,
  NavigationPaths,
  VisibleComponent,
  WeekSwitch,
  WeekSwitchStartEnd
} from '@app/shared/models';
import { CommonService } from '@app/shared/services/common.service';
import { TrackingService } from '@app/shared/services/tracking.service';
import { routesConfig } from '@app/routes/configs';
import { ActivitiesDataService } from '@app/activities/services/activities-data.service';
import { HeaderSearchFiltersService } from '@app/shared/services/header-search-filters.service';
import { HeaderMenuIconsService } from '@app/shared/services/header-menu-icons.service';
import { BuilderCommonService } from '@app/builder/services/builder-common.service';
import { ReminderDataService } from '@app/reminder/services';

@Injectable({
  providedIn: 'root'
})
export class HeaderDataService {
  private readonly builderCommonService = inject(BuilderCommonService);

  private weekSwitchChange: BehaviorSubject<WeekSwitchStartEnd> = new BehaviorSubject({
    startDate: moment().startOf('week').format(AppConstants.DATE_FORMAT_ISO),
    endDate: moment().endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
  });
  private showDate: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private showRoutesViewType: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private date: BehaviorSubject<string> = new BehaviorSubject(moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO));
  private previousFullUrl: string;
  private currentFullUrl: string;

  weekSwitchChange$ = this.weekSwitchChange.asObservable();
  showDate$: Observable<boolean> = this.showDate.asObservable();
  showRoutesViewType$: Observable<boolean> = this.showRoutesViewType.asObservable();
  date$: Observable<string> = this.date.asObservable();

  readonly #headerTemplate = signal(null);
  readonly #showWeekSwitch = signal(false);
  readonly #showTodayButton = signal(true);
  readonly #showDateRange = signal(false);
  readonly #isRoutesViewTypeDisabled = signal(false);
  readonly #dateRange = signal<{ startDate: string; endDate: string; }>({
    startDate: moment().startOf('month').format(AppConstants.DATE_FORMAT_ISO),
    endDate: moment().endOf('month').format(AppConstants.DATE_FORMAT_ISO)
  });
  readonly #isDateDisabled = signal(false);

  readonly headerTemplate = this.#headerTemplate.asReadonly();
  readonly showDateRange = this.#showDateRange.asReadonly();
  readonly isRoutesViewTypeDisabled = this.#isRoutesViewTypeDisabled.asReadonly();
  readonly showWeekSwitch = this.#showWeekSwitch.asReadonly();
  readonly showTodayButton = this.#showTodayButton.asReadonly();
  readonly dateRange = this.#dateRange.asReadonly();
  readonly isDateDisabled = this.#isDateDisabled.asReadonly();

  readonly dateRangeChange$ = toObservable(this.dateRange);

  headerForm: UntypedFormGroup;
  showGlobalSearch: boolean;

  weekSwitch: WeekSwitch = {
    initDate: this.date.value,
    startDateLimit: null,
    endDateLimit: null
  };

  dashboardDetails: HeaderDashboardDetails = {
    activeRideId: null,
    isUpdate: false,
    isShowPassengers: false,
    tracksTraveling: 0,
    passed: 0
  };

  constructor(
    private router: Router,
    private uSidebarMenuService: USidebarMenuService,
    private commonService: CommonService,
    private headerSearchFiltersService: HeaderSearchFiltersService,
    private headerMenuIconsService: HeaderMenuIconsService,
    private fb: UntypedFormBuilder,
    private trackingService: TrackingService,
    private reminderDataService: ReminderDataService,
    private activitiesDataService: ActivitiesDataService
  ) {
    this.router.events.subscribe(event => {
      let nextUrl = '';
      const [ previousUrl ] = this.commonService.previousUrl().split('?');

      if (event instanceof ResolveStart) {
        nextUrl = event.url.split('?')[0];

        if (previousUrl !== nextUrl) {
          this.updateShowWeekSwitch(false);

          if (this.commonService.visibleComponents()[VisibleComponent.MonitoringDashboard]) {
            this.closeDashboard();
          }

          this.commonService.updateVisibleComponent(VisibleComponent.Notes, false);

          if (!nextUrl.startsWith(`/${NavigationPaths.Settings}`)) {
            this.commonService.updatePreviousUrl(nextUrl);
          }

          this.updateShowGlobalSearch(false);
          this.disableOnLoad(previousUrl, nextUrl);
        }
      }

      if (event instanceof NavigationStart) {
        this.reminderDataService.checkForReminders();
      }

      if (event instanceof NavigationEnd) {
        this.previousFullUrl = this.currentFullUrl;
        this.currentFullUrl = event.url;

        this.headerSearchFiltersService.reset();

        if (this.previousFullUrl) {
          this.resetSearchConfigByUrl(this.previousFullUrl.replace('/', ''));
        }

        this.commonService.updatePreviousFullUrl(this.previousFullUrl);

        this.headerSearchFiltersService.loadCurrentUrlSearchConfig();
      }
    });

    this.generateHeaderForm();
  }

  get dateForm(): UntypedFormControl {
    return this.headerForm.get('date') as UntypedFormControl;
  }

  private resetSearchConfigByUrl(urlToCheck: string) {
    const urls = [ NavigationPaths.BuilderFull ];

    urls.forEach(url => {
      if (urlToCheck === url) {
        this.headerSearchFiltersService.resetSearchConfigByUrl(url);
      }
    });
  }

  private disableOnLoad(previousUrl: string, nextUrl: string) {
    const nextUrls = [ `/${NavigationPaths.Builder}` ];
    const previousUrls = [ `/${NavigationPaths.Rides}` ];

    if (previousUrls.includes(previousUrl) && nextUrls.includes(nextUrl)) {
      this.commonService.updateShowReOptimizationOnLoad(this.commonService.visibleComponents()[VisibleComponent.ReOptimization]);
    }
  }

  private checkClickedIcon(name: HeaderMenuIconValue) {
    switch (name) {
      case HeaderMenuIconValue.NewBuilder:
      case HeaderMenuIconValue.AddNewTemplate:
      case HeaderMenuIconValue.RegularRoute:
      case HeaderMenuIconValue.FixedRoute:
      case HeaderMenuIconValue.ShiftsRoute:
      case HeaderMenuIconValue.SpecialRoute:
      case HeaderMenuIconValue.ShuttleRoute:
      case HeaderMenuIconValue.DeliveryRoute: {
        if (this.commonService.visibleComponents()[VisibleComponent.AiAutoAssignment]) {
          break;
        }

        this.builderCommonService.updateRouteType(routesConfig.routeTypeByHeaderMenuIconValue[name]);

        this.trackingService.track(`[Route Add] - Click on Add ${name}`);

        if (this.router.url === `/${NavigationPaths.Builder}` || this.router.url === `/${NavigationPaths.BuilderRouteTemplates}`) {
          const path = this.router.url === `/${NavigationPaths.Builder}` ? NavigationPaths.Builder : NavigationPaths.BuilderRouteTemplates;

          this.router.navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(path));
        }

        if (this.router.url === `/${NavigationPaths.Rides}`) {
          this.router.navigateByUrl(NavigationPaths.Builder);
        }

        if (this.router.url === `/${NavigationPaths.RouteTemplates}`) {
          this.router.navigateByUrl(NavigationPaths.BuilderRouteTemplates);
        }

        break;
      }

      case HeaderMenuIconValue.NewBuilderFull: {
        this.router.navigateByUrl(NavigationPaths.BuilderFull);

        break;
      }

      case HeaderMenuIconValue.MonitoringDashboard: {
        this.openDashboard({ rideId: null });

        break;
      }

      case HeaderMenuIconValue.Notes: {
        this.commonService.updateVisibleComponent(
          VisibleComponent.Notes,
          !this.commonService.visibleComponents()[VisibleComponent.Notes]
        );

        if (this.commonService.visibleComponents()[VisibleComponent.Notes]) {
          this.closeDashboard();
        }

        break;
      }

      case HeaderMenuIconValue.Feed: {
        this.trackingService.track('[Feed] - click on icon');
        this.router.navigate([ NavigationPaths.Rides ]);

        this.commonService.updateVisibleComponent(
          VisibleComponent.Feed,
          !this.commonService.visibleComponents()[VisibleComponent.Feed]
        );

        if (this.commonService.visibleComponents().feed) {
          this.closeDashboard();
        }

        break;
      }

      case HeaderMenuIconValue.Activities: {
        this.commonService.updateVisibleComponent(
          VisibleComponent.Activities,
          !this.commonService.visibleComponents()[VisibleComponent.Activities]
        );

        if (this.commonService.visibleComponents()[VisibleComponent.Activities]) {
          this.closeDashboard();
          this.activitiesDataService.loadActivities(this.getDateRange());
        }

        break;
      }
    }
  }

  updateShowWeekSwitch(value: boolean) {
    this.#showWeekSwitch.set(value);
  }

  updateShowDate(value: boolean) {
    this.showDate.next(value);
  }

  updateHeaderTemplate(template: TemplateRef<any>) {
    this.#headerTemplate.set(template);
  }

  updateShowDateRange(value: boolean) {
    this.#showDateRange.set(value);
  }

  updateShowTodayButton(value: boolean) {
    this.#showTodayButton.set(value);
  }

  generateHeaderForm() {
    const today = moment().format();

    this.headerForm = this.fb.group({
      date: [ today, Validators.required ],
      dateRange: [
        [
          moment(today).startOf('month'),
          moment(today).endOf('month').startOf('day')
        ]
      ]
    });

    this.dateForm.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.date.next(value);

        if (!this.showWeekSwitch()) {
          this.activitiesDataService.loadActivitiesLive(this.getDateRange());

          this.updateDateWeekSwitch(value);
        }
      });

    this.headerForm.get('dateRange').valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => this.updateDateRange(value));
  }

  dateSet(value: string, emitEvent: boolean = true) {
    this.dateForm.patchValue(value, { emitEvent });
  }

  menuIconClick(item: HeaderMenuIcon | HeaderMenuIconChild, selectIcon: boolean = false) {
    this.headerMenuIconsService.selectIcon(item as HeaderMenuIcon, selectIcon);

    this.checkClickedIcon(item.value as HeaderMenuIconValue);
  }

  weekSwitchSet(data: WeekSwitch) {
    this.weekSwitch = {
      ...this.weekSwitch,
      ...data
    };
  }

  weekSwitchChangeSet(data: WeekSwitchStartEnd) {
    this.weekSwitch.initDate = data.startDate;
    this.weekSwitchChange.next(data);
  }

  updateDateWeekSwitch(date) {
    const weekSwitch: WeekSwitch  = {
      initDate: date,
      startDateLimit: null,
      endDateLimit: null
    };

    const weekSwitchRange: WeekSwitchStartEnd = {
      startDate: moment(date).startOf('week').format(AppConstants.DATE_FORMAT_ISO),
      endDate: moment(date).endOf('week').startOf('day').format(AppConstants.DATE_FORMAT_ISO)
    };

    this.weekSwitchSet(weekSwitch);
    this.weekSwitchChangeSet(weekSwitchRange);
  }

  updateDateRange(dates: string[]) {
    this.#dateRange.set({ startDate: dates[0], endDate: dates[dates.length - 1] });
  }

  openDashboard(data: HeaderDashboard): void {
    this.trackingService.track('[Dashboard] - click on icon');

    this.commonService.updateVisibleComponent(
      VisibleComponent.MonitoringDashboard,
      !this.commonService.visibleComponents()[VisibleComponent.MonitoringDashboard]
    );

    this.uSidebarMenuService.updateCollapsed(this.commonService.visibleComponents()[VisibleComponent.MonitoringDashboard]);

    this.dashboardDetails.activeRideId = data ? data.rideId : null;
    this.dashboardDetails.isShowPassengers = false;
  }

  closeDashboard() {
    this.commonService.updateVisibleComponent(VisibleComponent.MonitoringDashboard, false);

    this.dashboardDetails.isShowPassengers = false;

    this.uSidebarMenuService.updateCollapsed(false);
  }

  isTodayActiveDate(): boolean {
    return moment().startOf('day').isSame(moment(this.date.value, AppConstants.DATE_FORMAT_ISO).startOf('day'));
  }

  getActiveDate(): string {
    return this.isTodayActiveDate() ? moment().startOf('day').format(AppConstants.DATE_FORMAT_ISO) : this.date.value;
  }

  getDate(): string {
    return this.date.value;
  }

  getDateRange(): { startDate: string; endDate: string; } {
    return {
      startDate: moment(this.date.value, AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE),
      endDate: moment(this.date.value, AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE)
    };
  }

  getWeekDatesRange(): { startDate: string; endDate: string; } {
    return {
      startDate: moment(this.weekSwitchChange.value.startDate, AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE),
      endDate: moment(this.weekSwitchChange.value.endDate, AppConstants.DATE_FORMAT_ISO).format(AppConstants.DATE_FORMAT_BASE_LINE)
    };
  }

  updateShowRoutesViewType(value: boolean) {
    this.showRoutesViewType.next(value);
  }

  updateShowGlobalSearch(value: boolean) {
    this.showGlobalSearch = value;
  }

  getWeekSwitchChangeValue() {
    return this.weekSwitchChange.getValue();
  }

  updateIsDateDisabled(value: boolean) {
    this.#isDateDisabled.set(value);
  }

  updateIsRoutesViewTypeDisabled(value: boolean) {
    this.#isRoutesViewTypeDisabled.set(value);
  }
}
