import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { UDroplistItem, UPopoverDirective, UPopupService } from '@shift/ulib';

import { AddEditModalService, LocalizationService } from '@app/shared/services';
import { AddEditModalAction, AddEditModalButton, AddEditModalConfig, AddEditModalTab, TablePageRowEditMode } from '@app/shared/models';
import { addEditModalConfig } from '@app/shared/configs';
import { AuthDataSnapshotService } from '@app/auth/services';

@Component({
  selector: 'app-add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: [ './add-edit-modal.component.scss', './add-edit-modal.component.rtl.scss' ],
  providers: [ AuthDataSnapshotService ]
})
export class AddEditModalComponent implements OnInit, OnChanges, OnDestroy {
  @Input() titleTooltip: TemplateRef<any> | string;
  @Input() data: any;
  @Input() form: UntypedFormGroup;
  @Input() isLoading: boolean = false;
  @Input() config: AddEditModalConfig;
  @Input() isValid: (data: any) => boolean;
  @Input() customDeleteConfirm: () => boolean;
  @Input() closeModalOnSubmit: boolean = false;
  @Input() addEditModalCustomService: AddEditModalService;

  add = output<any>();
  edit = output<any>();
  delete = output<any>();
  shutDown = output<void>();
  tabSelect = output<AddEditModalTab>();
  actionSelect = output<UDroplistItem>();

  @HostBinding('class') hostClasses: string = 'add-edit-modal';

  private unsubscribe: Subject<void> = new Subject<void>();
  private subscription?: Subscription;

  modalButtons: AddEditModalButton[] = [];
  tablePageRowEditMode = TablePageRowEditMode;
  isRtl: boolean = this.localizationService.isRtl();
  activeTabName: string;
  activeSubTabName: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private localizationService: LocalizationService,
    private uPopupService: UPopupService,
    private translate: TranslateService,
    private authDataSnapshotService: AuthDataSnapshotService,
    public addEditModalService: AddEditModalService
  ) {}

  ngOnInit(): void {
    if (this.addEditModalCustomService) {
      this.addEditModalService = this.addEditModalCustomService;
    }

    this.addEditModalService.form = this.form;
    this.addEditModalService.isValidFn = this.isValid;

    this.addEditModalService.action
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(action => this.checkActions(action));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.disableButtons();
    }

    if (changes.form) {
      this.addEditModalService.form = this.form;

      this.setActionButtons();
    }

    if (changes.config) {
      this.addEditModalService.updateDictionary(this.config.dictionary);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.addEditModalService.reset();
  }

  private setActionButtons(): void {
    this.modalButtons = cloneDeep(this.config.buttons || addEditModalConfig.defaultButtons).filter((button: AddEditModalButton) =>
      !button.permission || this.authDataSnapshotService.checkPermission(button.permission)
    );

    if (!this.addEditModalService.isEditMode) {
      this.modalButtons = this.modalButtons.filter(button => button.action !== AddEditModalAction.Delete);
    }

    this.modalButtons.forEach((button: AddEditModalButton) =>
      this.translate.get(button.name).pipe(first())
        .subscribe(translatedName => button.name = translatedName));

    this.disableButtons();
    this.onFormChanges();
  }

  private onFormChanges() {
    this.subscription?.unsubscribe();

    this.subscription = this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.disableButtons());
  }

  private disableButtons() {
    this.modalButtons = this.modalButtons.map(button => ({
      ...button,
      disabled: button.action === AddEditModalAction.Delete ?
        this.isLoading : button.action === AddEditModalAction.ShutDown ?
          button.disabled : !this.form.valid || !this.form.dirty || this.isLoading
    }));

    this.cdRef.markForCheck();
  }

  checkActions(modalAction: AddEditModalAction): void {
    switch (modalAction) {
      case AddEditModalAction.Submit: {
        this.addEditModalService.submit(this.closeModalOnSubmit)
          .subscribe(res => {
            this[res.action].emit(res.formValue);
          });

        break;
      }

      case AddEditModalAction.Delete: {
        this.deleteConfirm();

        break;
      }

      case AddEditModalAction.ShutDown: {
        this.addEditModalService.closeConfirmModal()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => {
            if (res.action) {
              this.shutDown.emit();
            }
          });

        break;
      }
    }
  }

  deleteConfirm(): void {
    if (this.customDeleteConfirm ? !this.customDeleteConfirm() : true) {
      this.uPopupService.showMessage({
        message: this.config.dictionary.deleteConfirm,
        yes: this.config.dictionary.yes || addEditModalConfig.dictionary.yes,
        no: this.config.dictionary.no || addEditModalConfig.dictionary.no
      }, () => {
        this.delete.emit(this.addEditModalService.getModalFormValue(this.form));

        if (this.addEditModalService.modalRef) {
          this.addEditModalService.modalRef.hide();
        }
      });
    }
  }

  onSelectTab(tab: AddEditModalTab) {
    this.activeTabName = tab.dataName;
    this.activeSubTabName = null;

    this.tabSelect.emit(tab);
  }

  tabHeaderMouseEnter(tab: AddEditModalTab, popover: UPopoverDirective) {
    if (tab.subTabs && !popover.isOpen()) {
      popover.open();
    }
  }

  tabHeaderMouseLeave(tab: AddEditModalTab, popover: UPopoverDirective) {
    if (tab.subTabs && popover.isOpen()) {
      popover.close();
    }
  }

  tabHeaderDropdownItemClick(tab: AddEditModalTab, subTab: AddEditModalTab, popover: UPopoverDirective) {
    this.activeTabName = tab.dataName;
    this.activeSubTabName = subTab.dataName;

    this.tabSelect.emit(subTab);

    if (popover.isOpen()) {
      popover.close();
    }
  }

  onActionChange(action: { selected: UDroplistItem; }) {
    this.actionSelect.emit(action.selected);
  }
}
