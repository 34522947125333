<div class="input-contacts__main">
  <div class="input-contacts__header">
    @if (showTitle) {
      <div class="u-text-line u-text-line_app-title input-contacts__header-title">
        {{config.dictionary.title | translate}}
      </div>
    }

    @if (!form.disabled) {
      <button
        uButton
        uId="add-contact"
        class="u-font-weight-medium input-contacts__header-action"
        [withPlus]="true"
        (click)="inputContactsService.addContact()"
      >
        {{config.dictionary.newContact | translate}}
      </button>
    }
  </div>
  <div class="input-contacts__items">
    @for (contactForm of form.controls; track contactForm; let index = $index) {
      <div
        [formGroup]="contactForm"
        class="input-contacts__item"
      >
        <u-group
          class="u-group_large-field u-group_flex u-group_app-input-contacts"
          [name]="config.dictionary.contact | translate"
        >
          <div class="input-contacts__double">
            <div class="input-contacts__double-item">
              <input
                uInput
                type="text"
                uId="contact-role"
                [valid]="!contactForm.disabled ? contactForm.get('role').valid : true"
                [placeholder]="config.dictionary.role | translate"
                formControlName="role"
              >
            </div>
            <div class="input-contacts__double-item">
              <input
                uInput
                type="text"
                uId="contact-name"
                formControlName="name"
                [valid]="!contactForm.disabled ? contactForm.get('name').valid : true"
                [placeholder]="config.dictionary.name | translate"
              >
            </div>
          </div>
          @if (!contactForm.disabled) {
            <div class="input-contacts__actions">
              <div
                class="input-contacts__actions-btn input-contacts__actions-btn_remove"
                uId="remove-contact"
                (click)="inputContactsService.removeContact({ index: index })"
              ></div>
            </div>
          }
        </u-group>
        <app-input-communications
          [ngClass]="{ 'input-communications_contacts': contactForm.get('includeForUpdateEmails') }"
          [dumbComponent]="true"
          [form]="contactForm.get('contacts')"
          [showTitle]="false"
          [showAddIcon]="!contactForm.disabled"
          (addContactAction)="inputContactsService.addContactItem({ index: index })"
          (removeContactAction)="inputContactsService.removeContactItem({ index: index, indexItem: $event.index })"
        ></app-input-communications>
        @if (contactForm.get('includeForUpdateEmails')) {
          <div
            class="input-contacts__item-checkbox"
            [ngClass]="{ 'input-contacts__item-checkbox_disabled': contactForm.get('includeForUpdateEmails').disabled }"
          >
            <div class="input-contacts__item-checkbox-field">
              <u-checkbox class="u-checkbox_box u-checkbox_app-round-checkbox" [formControl]="contactForm.get('includeForUpdateEmails')"></u-checkbox>
            </div>
            <div class="input-contacts__item-checkbox-name">{{config.dictionary.includeForUpdateEmails | translate}}</div>
          </div>
        }

        @if (contactForm.get('sendAccountingDocuments')) {
          <div
            class="input-contacts__item-checkbox"
            [ngClass]="{ 'input-contacts__item-checkbox_disabled': contactForm.get('sendAccountingDocuments').disabled }"
          >
            <div class="input-contacts__item-checkbox-field">
              <u-checkbox
                class="u-checkbox_box u-checkbox_app-round-checkbox"
                [formControl]="contactForm.get('sendAccountingDocuments')"
              ></u-checkbox>
            </div>
            <div class="input-contacts__item-checkbox-name">
              {{config.dictionary.sendAccountingDocuments | translate}}
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
