import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type FormType<T> = {
  [K in keyof T]: T[K] extends object
      ? T[K] extends Date
          ? FormControl<T[K] | null>
          : T[K] extends unknown[]
              ? FormArray<FormTypeGroup<T[K] extends (infer V)[] ? V : T[K]>>
              : FormTypeGroup<T[K]>
      : FormControl<T[K] | null>;
}

export type FormTypeGroup<T> = FormGroup<FormType<T>>;
