import { Languages } from '@app/shared/models';

export enum AuthError {
  InvalidRefreshToken = 'invalid_grant'
}

export enum AuthModuleName {
  Activities = 'activities',
  Accounting = 'accounting',
  Feed = 'feed',
  Accompany = 'accompany',
  Branches = 'branches',
  BI = 'bi',
  KPI = 'kpi',
  Tags = 'tags',
  Departments = 'departments',
  Contracts = 'contracts',
  CtrlClient = 'ctrlClient',
  CtrlServer = 'ctrlServer',
  CustomerStations = 'customerStations',
  CustomerSettings = 'customerSettings',
  EducationInstitutions = 'educationInstitutions',
  Passengers = 'passengers',
  Reports = 'reports',
  BulkChanges = 'bulkChanges',
  Reservations = 'reservations',
  RideOrders = 'rideOrders',
  Routes = 'routes',
  RoutePlanner = 'routePlanner',
  RoutePlannerAi = 'routePlannerAi',
  RouteBuilder = 'routeBuilder',
  RouteSuggestions = 'routeSuggestions',
  RoutesTable = 'routesTable',
  RouteTemplates = 'routeTemplates',
  DemandsPassengersView = 'demandsPassengersView',
  DemandsShiftsView = 'demandsShiftsView',
  ShuttleCompanyCustomers = 'shuttleCompanyCustomers',
  Supervisors = 'supervisors',
  Transportation = 'transportation',
  ShiftVsWorld = 'shiftVsWorld',
  NextYear = 'nextYear',
  ReOptimization = 'reOptimization',
  RoutesTransportAi = 'routesTransportAi'
}

export enum AuthModuleFeatureGroupCondition {
  Or = 'or',
  And = 'and'
}

export enum AuthModulePassengersFeature {
  Type = 'type',
  Import = 'import',
  Export = 'export',
  EntityManager = 'entityManager',
  RideReservationEnabledByDefault = 'rideReservationEnabledByDefault',
  Permissions = 'permissions'
}

export enum AuthModulePassengersFeatureType {
  Generic = 'Generic',
  Student = 'Student',
  Soldier = 'Soldier',
  SoldierMaster = 'SoldierMaster',
  Iec = 'Iec'
}

export enum AuthModulePassengersFeatureImport {
  Student = 'Student',
  Soldier = 'Soldier'
}

export enum AuthModulePassengersFeatureExport {
  Student = 'Student'
}

export enum AuthModulePassengersFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModulePassengersFeatureRideReservationEnabledByDefault {
  RideReservationEnabledByDefaultFeature = 'RideReservationEnabledByDefaultFeature'
}

export enum AuthModulePassengersFeaturePermission {
  RideReservations = 'RideReservations',
  JoinRide = 'JoinRide',
  CreateRide = 'CreateRide'
}

export enum AuthModuleBranchesFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleBranchesFeatureType {
  Generic = 'Generic',
  Base = 'Base',
  ActivityCenter = 'ActivityCenter',
  Iec = 'Iec'
}

export enum AuthModuleBranchesFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleDepartmentsFeature {
  Type = 'type',
  Predefined = 'predefined',
  EntityManager = 'entityManager',
  UpdateDepartments = 'updateDepartments'
}

export enum AuthModuleDepartmentsFeatureType {
  Generic = 'Generic',
  Section = 'Section',
  Division = 'Division',
  Iec = 'Iec'
}

export enum AuthModuleDepartmentsFeaturePredefined {
  Municipality = 'Municipality'
}

export enum AuthModuleDepartmentsFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleDepartmentsFeatureUpdateDepartments {
  UpdateDepartmentsFeature = 'UpdateDepartmentsFeature'
}

export enum AuthModuleTagsFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleTagsFeatureType {
  TypeFeature = 'TypeFeature'
}

export enum AuthModuleTagsFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleRoutesFeature {
  BidNumber = 'bidNumber',
  DeleteExitingRoutes = 'deleteExitingRoutes',
  RegulationNumber = 'regulationNumber',
  MandatoryFields = 'mandatoryFields'
}

export enum AuthModuleRoutesFeatureBidNumber {
  BidNumberFeature = 'BidNumberFeature'
}

export enum AuthModuleRoutesFeatureDeleteExitingRoutes {
  DeleteExitingRoutesFeature = 'DeleteExitingRoutesFeature'
}

export enum AuthModuleRoutesFeatureRegulationNumber {
  RegulationNumberFeature = 'RegulationNumberFeature'
}

export enum AuthModuleRoutesFeatureMandatoryField {
  ShuttleCompany = 'ShuttleCompany',
  PlannedVehicleType = 'PlannedVehicleType',
  Department = 'Department',
  Region = 'Region',
  PurchaseOrder = 'PurchaseOrder',
  PurchaseOrderRow = 'PurchaseOrderRow',
  OrderPurpose = 'OrderPurpose',
  ManualCost = 'ManualCost'
}

export enum AuthModuleRideOrdersFeature {
  Type = 'type'
}

export enum AuthModuleRideOrdersFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleRouteBuilderFeature {
  Type = 'type',
  Filters = 'filters',
  Details = 'details',
  Settings = 'settings',
  DefaultReportMode = 'defaultReportMode',
  MasterCustomer = 'masterCustomer',
  DeleteRoute = 'deleteRoute',
  OrderPurpose = 'orderPurpose',
  NewRoute = 'newRoute',
  TransportAdditionalFields = 'transportAdditionalFields',
  RouteHistory = 'routeHistory'
}

export enum AuthModuleRouteBuilderFeatureType {
  Generic = 'Generic',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleRouteBuilderFeatureFilter {
  Shifts = 'Shifts',
  Gender = 'Gender',
  EligibleShuttle = 'EligibleShuttle'
}

export enum AuthModuleRouteBuilderFeatureDetail {
  Customers = 'Customers',
  BudgetItem = 'BudgetItem',
  BidNumber = 'BidNumber',
  PurchaseOrder = 'PurchaseOrder',
  Region = 'Region',
  RegulationNumber = 'RegulationNumber',
  RequiresApproval = 'RequiresApproval'
}

export enum AuthModuleRouteBuilderFeatureSettings {
  UseTraffic = 'UseTraffic'
}

export enum AuthModuleRouteBuilderFeatureDefaultReportMode {
  BackgroundEmail = 'BackgroundEmail'
}

export enum AuthModuleRouteBuilderFeatureMasterCustomer {
  MasterCustomerFeature = 'MasterCustomerFeature'
}

export enum AuthModuleRouteBuilderFeatureDeleteRoute {
  DeleteRouteFeature = 'DeleteRouteFeature'
}

export enum AuthModuleRouteBuilderFeatureOrderPurpose {
  Generic = 'Generic',
  Police = 'Police'
}

export enum AuthModuleRouteBuilderFeatureNewRoute {
  NewRouteFeature = 'NewRouteFeature'
}

export enum AuthModuleRouteBuilderFeatureTransportAdditionalField {
  DriverHours = 'DriverHours'
}

export enum AuthModuleRouteBuilderFeatureRouteHistory {
  RouteHistoryFeature = 'RouteHistoryFeature'
}

export enum AuthModuleRoutePlannerFeature {
  Type = 'type',
  Directions = 'directions',
  SkippedPassengersExport = 'skippedPassengersExport',
  MasterCustomer = 'masterCustomer',
  ShuttleCompanyDefaultCostType = 'shuttleCompanyDefaultCostType'
}

export enum AuthModuleRoutePlannerFeatureType {
  Generic = 'Generic',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleRoutePlannerFeatureSkippedPassengersExport {
  Excel = 'Excel'
}

export enum AuthModuleRoutePlannerFeatureDirections {
  PickupDropOff = 'PickupDropOff'
}

export enum AuthModuleRoutePlannerFeatureMasterCustomer {
  MasterCustomerFeature = 'MasterCustomerFeature'
}

export enum AuthModuleRoutePlannerFeatureShuttleCompanyDefaultCostType {
  FixedCost = 'FixedCost',
  AccordingToContract = 'AccordingToContract'
}

export enum AuthModuleRoutePlannerAiFeature {
  Type = 'type'
}

export enum AuthModuleRoutePlannerAiFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleDemandsPassengersViewFeature {
  Type = 'type',
  RemoveFromRoutesOnShiftChanged = 'removeFromRoutesOnShiftChanged',
  Export = 'export',
  EntityManager = 'entityManager'
}

export enum AuthModuleDemandsShiftsViewFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleDemandsShiftsViewFeatureType {
  Generic = 'Generic',
  Student = 'Student',
  Soldier = 'Soldier'
}

export enum AuthModuleDemandsPassengersViewFeatureType {
  Generic = 'Generic',
  Student = 'Student',
  Soldier = 'Soldier'
}

export enum AuthModuleDemandsPassengersViewFeatureRemoveFromRoutesOnShiftChanged {
  RemoveFromRoutesOnShiftChangedFeature = 'RemoveFromRoutesOnShiftChangedFeature'
}

export enum AuthModuleDemandsPassengersViewFeatureExport {
  Generic = 'Generic',
  Student = 'Student',
  Soldier = 'Soldier'
}

export enum AuthModuleDemandsPassengersViewFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleDemandsShiftsViewFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleCustomerSettingsFeature {
  Type = 'type'
}

export enum AuthModuleCustomerSettingsFeatureType {
  Generic = 'Generic',
  ShuttleCompany = 'ShuttleCompany',
  Municipality = 'Municipality',
  Army = 'Army',
  Iec = 'Iec',
  Police = 'Police'
}

export enum AuthModuleSupervisorsFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleSupervisorsFeatureType {
  Generic = 'Generic',
  Commander = 'Commander'
}

export enum AuthModuleSupervisorsFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleActivitiesFeature {
  TempComments = 'tempComments'
}

export enum AuthModuleActivitiesFeatureTempComments {
  TempCommentsFeature = 'TempCommentsFeature'
}

export enum AuthModuleFeedFeature {
  Type = 'type',
  Export = 'export'
}

export enum AuthModuleFeedFeatureType {
  Generic = 'Generic',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleFeedFeatureExport {
  DuplicatedPassengers = 'DuplicatedPassengers'
}

export enum AuthModuleAccompanyFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleAccompanyFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleAccompanyFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleTransportationFeature {
  Type = 'type',
  SelfShuttleCompany = 'selfShuttleCompany',
  ShuttleCompanyFines = 'shuttleCompanyFines',
  ShuttleCompanyContracts = 'shuttleCompanyContracts',
  ShuttleCompanyContractPriceType = 'shuttleCompanyContractPriceType',
  ShuttleCompanyContractsSpecialRates = 'shuttleCompanyContractsSpecialRates',
  ShuttleCompanyCancellationAndCloses = 'shuttleCompanyCancellationAndCloses',
  ShuttleCompanyContractImport = 'shuttleCompanyContractImport',
  ShuttleCompanyPermissions = 'shuttleCompanyPermissions',
  DriverAdditionalFields = 'driverAdditionalFields',
  ExecutorType = 'executorType'
}

export enum AuthModuleTransportationFeatureSelfShuttleCompany {
  SelfShuttleCompanyFeature = 'SelfShuttleCompanyFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyFines {
  ShuttleCompanyFinesFeature = 'ShuttleCompanyFinesFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyContracts {
  ShuttleCompanyContractsFeature = 'ShuttleCompanyContractsFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyContractPriceType {
  KmBasePrice = 'KmBasePrice',
  CitiesPrice = 'CitiesPrice',
  KmRangePrice = 'KmRangePrice',
  HashcalPrice = 'HashcalPrice'
}

export enum AuthModuleTransportationFeatureShuttleCompanyContractsSpecialRates {
  ShuttleCompanyContractsSpecialRatesFeature = 'ShuttleCompanyContractsSpecialRatesFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyCancellationAndCloses {
  ShuttleCompanyCancellationAndClosesFeature = 'ShuttleCompanyCancellationAndClosesFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyContractImport {
  ShuttleCompanyContractImportFeature = 'ShuttleCompanyContractImportFeature'
}

export enum AuthModuleTransportationFeatureShuttleCompanyPermissions {
  ShuttleCompanyPermissionsFeature = 'ShuttleCompanyPermissionsFeature'
}

export enum AuthModuleTransportationFeatureDriverAdditionalField {
  PositionScopeHours = 'PositionScopeHours'
}

export enum AuthModuleTransportationFeatureExecutorType {
  SubContractor = 'Subcontractor',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleTransportationFeatureType {
  Generic = 'Generic',
  Iec = 'Iec',
  Police = 'Police'
}

export enum AuthModuleEducationInstitutionsFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleEducationInstitutionsFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleEducationInstitutionsFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleRouteSuggestionsFeature {
  Type = 'type'
}

export enum AuthModuleRouteSuggestionsFeatureType {
  Daily = 'Daily',
  WeeklyStudent = 'WeeklyStudent'
}

export enum AuthModuleContractsFeature {
  Type = 'type'
}

export enum AuthModuleContractsFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleCtrlClientFeature {
  Type = 'type'
}

export enum AuthModuleCtrlClientFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleCtrlServerFeature {
  Type = 'type'
}

export enum AuthModuleCtrlServerFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleCustomerStationsFeature {
  Type = 'type',
  EntityManager = 'entityManager',
  NameLocalization = 'nameLocalization'
}

export enum AuthModuleCustomerStationsFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleCustomerStationsFeatureEntityManager {
  EntityManagerFeature = 'EntityManagerFeature'
}

export enum AuthModuleCustomerStationsFeatureNameLocalization {
  AR = 'ar'
}

export enum AuthModuleBIFeature {
  Type = 'type',
  External = 'external'
}

export enum AuthModuleBIFeatureType {
  Generic = 'Generic',
  Army = 'Army',
  Traffical = 'Traffical',
  ArmyMaster = 'ArmyMaster',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleBIFeatureExternal {
  External = 'ExternalFeature'
}

export enum AuthModuleShuttleCompanyCustomersFeature {
  Type = 'type',
  EntityManager = 'entityManager'
}

export enum AuthModuleShuttleCompanyCustomersFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleShuttleCompanyCustomersFeatureEntityManager {
  Generic = 'Generic',
  SelfShuttleCompany = 'SelfShuttleCompany'
}

export enum AuthModuleBulkChangesFeature {
  Type = 'type'
}

export enum AuthModuleBulkChangesFeatureType {
  Generic = 'Generic',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleReportsFeature {
  ServiceSheet = 'serviceSheet',
  ActualExpenses = 'actualExpenses',
  AgendaInlay = 'agendaInlay',
  RouteDetails = 'routeDetails',
  RoutesChangesHistory = 'routesChangesHistory',
  TravelTime = 'travelTime',
  MealsPlansSummary = 'mealsPlansSummary',
  Income = 'income',
  ScExpenses = 'scExpenses',
  ActualExpensesExtraColumns = 'actualExpensesExtraColumns',
  ActualExpensesCancellationBilling = 'actualExpensesCancellationBilling',
  ActualExpensesPdfBilling = 'actualExpensesPdfBilling'
}

export enum AuthModuleReportsFeatureActualExpenses {
  ActualExpensesFeature = 'ActualExpensesFeature'
}

export enum AuthModuleReportsFeatureAgendaInlay {
  AgendaInlayFeature = 'AgendaInlayFeature'
}

export enum AuthModuleReportsFeatureRouteDetails {
  RouteDetailsFeature = 'RouteDetailsFeature'
}

export enum AuthModuleReportsFeatureRoutesChangesHistory {
  Generic = 'Generic',
  Municipality = 'Municipality',
  Army = 'Army',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleReportsFeatureTravelTime {
  TravelTimeFeature = 'TravelTimeFeature'
}

export enum AuthModuleReportsFeatureMealsPlansSummary {
  MealsPlansSummaryFeature = 'MealsPlansSummaryFeature'
}

export enum AuthModuleReportsFeatureIncome {
  IncomeFeature = 'IncomeFeature'
}

export enum AuthModuleReportsFeatureActualExpensesExtraColumn {
  OrderedBy = 'OrderedBy',
  OrderPurpose = 'OrderPurpose',
  PurchaseOrder = 'PurchaseOrder',
  Region = 'Region',
  PurchaseOrderRow = 'PurchaseOrderRow',
  HashcalRideType = 'HashcalRideType',
  RegulationNumber = 'RegulationNumber',
  ContractNumber = 'ContractNumber'
}

export enum AuthModuleReportsFeatureActualExpensesCancellationBilling {
  ActualExpensesCancellationBillingFeature = 'ActualExpensesCancellationBillingFeature'
}

export enum AuthModuleReportsFeatureActualExpensesPdfBilling {
  ActualExpensesPdfBillingFeature = 'ActualExpensesPdfBillingFeature'
}

export enum AuthModuleReservationsFeature {
  WebType = 'webType'
}

export enum AuthModuleReservationsFeatureWebType {
  Generic = 'Generic'
}

export enum AuthModuleRoutesTableFeature {
  Type = 'type',
  Columns = 'columns',
  DefaultColumns = 'defaultColumns',
  ExportExcel = 'exportExcel',
  PassengersInfo = 'passengersInfo',
  PassengerChange = 'passengerChange',
  MasterCustomer = 'masterCustomer',
  MovePassengersDefaultOptions = 'movePassengersDefaultOptions',
  ShuttleCompanyChange = 'shuttleCompanyChange'
}

export enum AuthModuleRoutesTableFeatureType {
  Generic = 'Generic',
  Municipality = 'Municipality',
  Army = 'Army',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleRoutesTableFeatureExportExcel {
  Generic = 'Generic',
  Municipality = 'Municipality',
  Army = 'Army'
}

export enum AuthModuleRoutesTableFeatureColumn {
  Region = 'Region',
  CreatedBy = 'CreatedBy',
  OrderPurpose = 'OrderPurpose',
  PurchaseOrder = 'PurchaseOrder',
  RouteEndsInDays = 'RouteEndsInDays',
  ExecutionCost = 'ExecutionCost'
}

export enum AuthModuleRoutesTableFeatureDefaultColumn {
  ExecutingSc = 'ExecutingSc'
}

export enum AuthModuleRoutesTableFeaturePassengersInfo {
  PassengersInfoFeature = 'PassengersInfoFeature'
}

export enum AuthModuleRoutesTableFeaturePassengersChange {
  PassengerChangeFeature = 'PassengerChangeFeature'
}

export enum AuthModuleRoutesTableFeatureMasterCustomer {
  MasterCustomerFeature = 'MasterCustomerFeature'
}

export enum AuthModuleRoutesTableFeatureMovePassengersDefaultOption {
  ChangeTypePermanent = 'ChangeTypePermanent',
  PeriodToday = 'PeriodToday'
}

export enum AuthModuleRoutesTableFeatureShuttleCompanyChange {
  ShuttleCompanyChangeFeature = 'ShuttleCompanyChangeFeature'
}

export enum AuthModuleKPIFeature {
  Type = 'type',
  DemandsCount = 'demandsCount'
}

export enum AuthModuleKPIFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleKPIFeatureDemandsCount {
  DemandsCountFeature = 'DemandsCountFeature'
}

export enum AuthModuleRouteTemplatesFeature {
  Type = 'type'
}

export enum AuthModuleRouteTemplatesFeatureType {
  Generic = 'Generic',
  Army = 'Army',
  Municipality = 'Municipality',
  ShuttleCompany = 'ShuttleCompany'
}

export enum AuthModuleShiftVsWorldFeature {
  Type = 'type'
}

export enum AuthModuleShiftVsWorldFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleNextYearFeature {
  Type = 'type'
}

export enum AuthModuleNextYearFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleReOptimizationFeature {
  Type = 'type',
  Cleanup = 'cleanup',
  ReOptimize = 'reOptimize'
}

export enum AuthModuleReOptimizationFeatureType {
  Generic = 'Generic'
}

export enum AuthModuleReOptimizationFeatureCleanup {
  CleanupFeature = 'CleanupFeature'
}

export enum AuthModuleReOptimizationFeatureReOptimize {
  ReOptimizeFeature = 'ReOptimizeFeature'
}

export enum AuthModuleAccountingFeature {
  Type = 'type'
}

export enum AuthModuleAccountingFeatureType {
  Generic = 'Generic'
}

export enum AuthCustomerType {
  ShuttleCompany = 27,
  Municipality = 28,
  Business = 219,
  Army = 262
}

export enum AuthUserCustomerRoleType {
  Relative = 17,
  SafetyOfficer = 2,
  Driver = 27,
  Accompany = 10,
  Passenger = 16,
  ManagerSystem = 34,
  Bouncer = 1
}

export enum AuthOptionalModule {
  AfterSchoolActivities = 'AfterSchoolActivities',
  BI = 'Bi',
  Supervisor = 'Supervisor'
}

export enum AuthPolicyName {
  Routes = 'routes'
}

export enum AuthPolicyRoute {
  Cancellation = 'cancellation',
  ChangePassengersAmount = 'changePassengersAmount',
  DirectRoute = 'directRoute',
  UseTraffic = 'useTraffic'
}

export enum AuthPermissionGroupCondition {
  Or = 'or',
  And = 'and'
}

export enum AuthPermission {
  UsersManagement = 'usersManagement',
  CreateRoutes = 'createRoutes',
  EditRoutes = 'editRoutes',
  DeleteRoutes = 'deleteRoutes',
  AssignAccompanyToRides = 'assignAccompanyToRides',
  ApproveRideOrders = 'approveRideOrders',
  PoliciesAndSettingsManagement = 'policiesAndSettingsManagement',
  ManagePassengers = 'managePassengers',
  ManageAccompanies = 'manageAccompanies',
  ManageShuttleCompanies = 'manageShuttleCompanies'
}

export interface AuthModules {
  [AuthModuleName.Activities]?: {
    [AuthModuleActivitiesFeature.TempComments]?: AuthModuleActivitiesFeatureTempComments;
  };
  [AuthModuleName.Feed]?: {
    [AuthModuleFeedFeature.Type]?: AuthModuleFeedFeatureType;
    [AuthModuleFeedFeature.Export]?: AuthModuleFeedFeatureExport[];
  };
  [AuthModuleName.Accompany]?: {
    [AuthModuleAccompanyFeature.Type]: AuthModuleAccompanyFeatureType;
    [AuthModuleAccompanyFeature.EntityManager]?: AuthModuleAccompanyFeatureEntityManager;
  };
  [AuthModuleName.BI]?: {
    [AuthModuleBIFeature.Type]: AuthModuleBIFeatureType;
    [AuthModuleBIFeature.External]?: AuthModuleBIFeatureExternal;
  };
  [AuthModuleName.Passengers]?: {
    [AuthModulePassengersFeature.Type]: AuthModulePassengersFeatureType;
    [AuthModulePassengersFeature.Import]?: AuthModulePassengersFeatureImport;
    [AuthModulePassengersFeature.Export]?: AuthModulePassengersFeatureExport;
    [AuthModulePassengersFeature.EntityManager]?: AuthModulePassengersFeatureEntityManager;
    [AuthModulePassengersFeature.RideReservationEnabledByDefault]?: AuthModulePassengersFeatureRideReservationEnabledByDefault;
    [AuthModulePassengersFeature.Permissions]?: AuthModulePassengersFeaturePermission[];
  };
  [AuthModuleName.DemandsPassengersView]?: {
    [AuthModuleDemandsPassengersViewFeature.Type]: AuthModuleDemandsPassengersViewFeatureType;
    [AuthModuleDemandsPassengersViewFeature.Export]?: AuthModuleDemandsPassengersViewFeatureExport;
    [AuthModuleDemandsPassengersViewFeature.RemoveFromRoutesOnShiftChanged]?: AuthModuleDemandsPassengersViewFeatureRemoveFromRoutesOnShiftChanged;
    [AuthModuleDemandsPassengersViewFeature.EntityManager]?: AuthModuleDemandsPassengersViewFeatureEntityManager;
  };
  [AuthModuleName.DemandsShiftsView]?: {
    [AuthModuleDemandsShiftsViewFeature.Type]: AuthModuleDemandsShiftsViewFeatureType;
    [AuthModuleDemandsShiftsViewFeature.EntityManager]?: AuthModuleDemandsShiftsViewFeatureEntityManager;
  },
  [AuthModuleName.CustomerSettings]?: {
    [AuthModuleCustomerSettingsFeature.Type]: AuthModuleCustomerSettingsFeatureType;
  };
  [AuthModuleName.Supervisors]?: {
    [AuthModuleSupervisorsFeature.Type]: AuthModuleSupervisorsFeatureType;
    [AuthModuleSupervisorsFeature.EntityManager]?: AuthModuleSupervisorsFeatureEntityManager;
  };
  [AuthModuleName.ShuttleCompanyCustomers]?: {
    [AuthModuleShuttleCompanyCustomersFeature.Type]: AuthModuleShuttleCompanyCustomersFeatureType;
    [AuthModuleShuttleCompanyCustomersFeature.EntityManager]?: AuthModuleShuttleCompanyCustomersFeatureEntityManager;
  };
  [AuthModuleName.Branches]?: {
    [AuthModuleBranchesFeature.Type]: AuthModuleBranchesFeatureType;
    [AuthModuleBranchesFeature.EntityManager]?: AuthModuleBranchesFeatureEntityManager;
  };
  [AuthModuleName.Reports]?: {
    [AuthModuleReportsFeature.ActualExpenses]?: AuthModuleReportsFeatureActualExpenses;
    [AuthModuleReportsFeature.RouteDetails]?: AuthModuleReportsFeatureRouteDetails;
    [AuthModuleReportsFeature.AgendaInlay]?: AuthModuleReportsFeatureAgendaInlay;
    [AuthModuleReportsFeature.RoutesChangesHistory]?: AuthModuleReportsFeatureRoutesChangesHistory;
    [AuthModuleReportsFeature.TravelTime]?: AuthModuleReportsFeatureTravelTime;
    [AuthModuleReportsFeature.MealsPlansSummary]?: AuthModuleReportsFeatureMealsPlansSummary;
    [AuthModuleReportsFeature.ActualExpensesExtraColumns]?: AuthModuleReportsFeatureActualExpensesExtraColumn[];
    [AuthModuleReportsFeature.ActualExpensesCancellationBilling]?: AuthModuleReportsFeatureActualExpensesCancellationBilling;
    [AuthModuleReportsFeature.ActualExpensesPdfBilling]?: AuthModuleReportsFeatureActualExpensesPdfBilling;
  };
  [AuthModuleName.Reservations]?: {
    [AuthModuleReservationsFeature.WebType]?: AuthModuleReservationsFeatureWebType;
  };
  [AuthModuleName.Departments]?: {
    [AuthModuleDepartmentsFeature.Type]: AuthModuleDepartmentsFeatureType;
    [AuthModuleDepartmentsFeature.Predefined]?: AuthModuleDepartmentsFeaturePredefined;
    [AuthModuleDepartmentsFeature.EntityManager]?: AuthModuleDepartmentsFeatureEntityManager;
    [AuthModuleDepartmentsFeature.UpdateDepartments]?: AuthModuleDepartmentsFeatureUpdateDepartments;
  };
  [AuthModuleName.RideOrders]?: {
    [AuthModuleRideOrdersFeature.Type]: AuthModuleRideOrdersFeatureType;
  };
  [AuthModuleName.Routes]?: {
    [AuthModuleRoutesFeature.BidNumber]?: AuthModuleRoutesFeatureBidNumber;
    [AuthModuleRoutesFeature.DeleteExitingRoutes]?: AuthModuleRoutesFeatureDeleteExitingRoutes;
    [AuthModuleRoutesFeature.RegulationNumber]?: AuthModuleRoutesFeatureRegulationNumber;
    [AuthModuleRoutesFeature.MandatoryFields]?: AuthModuleRoutesFeatureMandatoryField[];
  };
  [AuthModuleName.RouteBuilder]?: {
    [AuthModuleRouteBuilderFeature.Type]: AuthModuleRouteBuilderFeatureType;
    [AuthModuleRouteBuilderFeature.Details]?: AuthModuleRouteBuilderFeatureDetail[];
    [AuthModuleRouteBuilderFeature.Filters]?: AuthModuleRouteBuilderFeatureFilter[];
    [AuthModuleRouteBuilderFeature.Settings]?: AuthModuleRouteBuilderFeatureSettings;
    [AuthModuleRouteBuilderFeature.DefaultReportMode]?: AuthModuleRouteBuilderFeatureDefaultReportMode;
    [AuthModuleRouteBuilderFeature.MasterCustomer]?: AuthModuleRouteBuilderFeatureMasterCustomer;
    [AuthModuleRouteBuilderFeature.DeleteRoute]?: AuthModuleRouteBuilderFeatureDeleteRoute;
    [AuthModuleRouteBuilderFeature.OrderPurpose]?: AuthModuleRouteBuilderFeatureOrderPurpose;
    [AuthModuleRouteBuilderFeature.NewRoute]?: AuthModuleRouteBuilderFeatureNewRoute;
    [AuthModuleRouteBuilderFeature.TransportAdditionalFields]?: AuthModuleRouteBuilderFeatureTransportAdditionalField[];
    [AuthModuleRouteBuilderFeature.RouteHistory]?: AuthModuleRouteBuilderFeatureRouteHistory;
  };
  [AuthModuleName.RoutePlanner]?: {
    [AuthModuleRoutePlannerFeature.Type]: AuthModuleRoutePlannerFeatureType;
    [AuthModuleRoutePlannerFeature.Directions]?: AuthModuleRoutePlannerFeatureDirections;
    [AuthModuleRoutePlannerFeature.SkippedPassengersExport]?: AuthModuleRoutePlannerFeatureSkippedPassengersExport;
    [AuthModuleRoutePlannerFeature.ShuttleCompanyDefaultCostType]?: AuthModuleRoutePlannerFeatureShuttleCompanyDefaultCostType;
    [AuthModuleRoutePlannerFeature.MasterCustomer]?: AuthModuleRoutePlannerFeatureMasterCustomer;
  };
  [AuthModuleName.RoutePlannerAi]?: {
    [AuthModuleRoutePlannerAiFeature.Type]: AuthModuleRoutePlannerAiFeatureType;
  };
  [AuthModuleName.RouteSuggestions]?: {
    [AuthModuleRouteSuggestionsFeature.Type]: AuthModuleRouteSuggestionsFeatureType;
  };
  [AuthModuleName.RoutesTable]?: {
    [AuthModuleRoutesTableFeature.Type]: AuthModuleRoutesTableFeatureType;
    [AuthModuleRoutesTableFeature.ExportExcel]?: AuthModuleRoutesTableFeatureExportExcel;
    [AuthModuleRoutesTableFeature.Columns]?: AuthModuleRoutesTableFeatureColumn[];
    [AuthModuleRoutesTableFeature.DefaultColumns]?: AuthModuleRoutesTableFeatureDefaultColumn[];
    [AuthModuleRoutesTableFeature.PassengersInfo]?: AuthModuleRoutesTableFeaturePassengersInfo;
    [AuthModuleRoutesTableFeature.PassengerChange]?: AuthModuleRoutesTableFeaturePassengersChange;
    [AuthModuleRoutesTableFeature.MasterCustomer]?: AuthModuleRoutesTableFeatureMasterCustomer;
    [AuthModuleRoutesTableFeature.MovePassengersDefaultOptions]?: AuthModuleRoutesTableFeatureMovePassengersDefaultOption[];
    [AuthModuleRoutesTableFeature.ShuttleCompanyChange]?: AuthModuleRoutesTableFeatureShuttleCompanyChange;
  };
  [AuthModuleName.KPI]?: {
    [AuthModuleKPIFeature.Type]: AuthModuleKPIFeatureType;
    [AuthModuleKPIFeature.DemandsCount]?: AuthModuleKPIFeatureDemandsCount;
  };
  [AuthModuleName.RouteTemplates]?: {
    [AuthModuleRouteTemplatesFeature.Type]: AuthModuleRouteTemplatesFeatureType;
  };
  [AuthModuleName.Transportation]?: {
    [AuthModuleTransportationFeature.Type]: AuthModuleTransportationFeatureType;
    [AuthModuleTransportationFeature.SelfShuttleCompany]?: AuthModuleTransportationFeatureSelfShuttleCompany;
    [AuthModuleTransportationFeature.ShuttleCompanyFines]?: AuthModuleTransportationFeatureShuttleCompanyFines;
    [AuthModuleTransportationFeature.ShuttleCompanyContracts]?: AuthModuleTransportationFeatureShuttleCompanyContracts;
    [AuthModuleTransportationFeature.ShuttleCompanyContractsSpecialRates]?: AuthModuleTransportationFeatureShuttleCompanyContractsSpecialRates;
    [AuthModuleTransportationFeature.ShuttleCompanyContractPriceType]?: AuthModuleTransportationFeatureShuttleCompanyContractPriceType[];
    [AuthModuleTransportationFeature.ShuttleCompanyCancellationAndCloses]?: AuthModuleTransportationFeatureShuttleCompanyCancellationAndCloses;
    [AuthModuleTransportationFeature.ShuttleCompanyContractImport]?: AuthModuleTransportationFeatureShuttleCompanyContractImport;
    [AuthModuleTransportationFeature.ShuttleCompanyPermissions]?: AuthModuleTransportationFeatureShuttleCompanyPermissions;
    [AuthModuleTransportationFeature.DriverAdditionalFields]?: AuthModuleTransportationFeatureDriverAdditionalField[];
    [AuthModuleTransportationFeature.ExecutorType]?: AuthModuleTransportationFeatureExecutorType;
  };
  [AuthModuleName.EducationInstitutions]?: {
    [AuthModuleEducationInstitutionsFeature.Type]: AuthModuleEducationInstitutionsFeatureType;
    [AuthModuleEducationInstitutionsFeature.EntityManager]?: AuthModuleEducationInstitutionsFeatureEntityManager;
  };
  [AuthModuleName.Contracts]?: {
    [AuthModuleContractsFeature.Type]: AuthModuleContractsFeatureType;
  };
  [AuthModuleName.CtrlClient]?: {
    [AuthModuleCtrlClientFeature.Type]: AuthModuleCtrlClientFeatureType;
  };
  [AuthModuleName.CtrlServer]?: {
    [AuthModuleCtrlServerFeature.Type]: AuthModuleCtrlServerFeatureType;
  };
  [AuthModuleName.CustomerStations]?: {
    [AuthModuleCustomerStationsFeature.Type]: AuthModuleCustomerStationsFeatureType;
    [AuthModuleCustomerStationsFeature.NameLocalization]?: AuthModuleCustomerStationsFeatureNameLocalization[];
  };
  [AuthModuleName.ShiftVsWorld]?: {
    [AuthModuleShiftVsWorldFeature.Type]: AuthModuleShiftVsWorldFeatureType;
  };
  [AuthModuleName.Tags]?: {
    [AuthModuleTagsFeature.Type]: AuthModuleTagsFeatureType;
    [AuthModuleTagsFeature.EntityManager]?: AuthModuleTagsFeatureEntityManager;
  };
  [AuthModuleName.NextYear]?: {
    [AuthModuleNextYearFeature.Type]: AuthModuleNextYearFeatureType;
  };
  [AuthModuleName.BulkChanges]?: {
    [AuthModuleBulkChangesFeature.Type]: AuthModuleBulkChangesFeatureType;
  };
  [AuthModuleName.ReOptimization]?: {
    [AuthModuleReOptimizationFeature.Type]: AuthModuleReOptimizationFeatureType;
    [AuthModuleReOptimizationFeature.Cleanup]?: AuthModuleReOptimizationFeatureCleanup;
    [AuthModuleReOptimizationFeature.ReOptimize]?: AuthModuleReOptimizationFeatureReOptimize;
  };
}

export interface AuthModuleFeature {
  module: AuthModuleName;
  name?: string;
  values?: string[];
}

export interface AuthModuleFeatureGroup {
  condition: AuthModuleFeatureGroupCondition;
  values: AuthModuleFeature[][];
}

export interface AuthPermissionGroup {
  condition: AuthPermissionGroupCondition;
  values: AuthPermission[][];
}

export interface AuthModuleItemFeatureAndPermission extends Object {
  feature?: AuthModuleFeature;
  features?: AuthModuleFeature[];
  featureGroup?: AuthModuleFeatureGroup;
  permission?: AuthPermission;
  permissionGroup?: AuthPermissionGroup;
}

export interface AuthCustomer {
  customerGuid: string;
  customerId: number;
  customerLanguage: Languages;
  name: string;
  selfShuttleCompanyId: number;
  type: AuthCustomerType;
}

export interface AuthPerson {
  email: string;
  firstName: string;
  lastName: string;
  memberId: number;
  mobile: string;
  personId: number;
  role: number;
  roleName: string;
  sendAnalytics: boolean;
}

export interface AuthPolicies {
  [AuthPolicyName.Routes]?: AuthPolicyRoute[];
}

export interface AuthUserInfo {
  customer: AuthCustomer;
  optionalModules: AuthOptionalModule[];
  person: AuthPerson;
  modules: AuthModules;
  policies: AuthPolicies;
  permissions: AuthPermission[];
}
