import { EventEmitter, Injectable, WritableSignal, signal, TemplateRef } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { UPopupService } from '@shift/ulib';

import { AuthDataService } from '@app/auth/services';
import { LocalizedToastrService } from '@app/shared/services/localized-toast.service';
import { ValidationService } from '@app/shared/services/validation.service';
import {
  EventData,
  TablePageRowEditMode,
  AddEditModalTab,
  AddEditModalConfig,
  AddEditModalAction,
  AddEditModalDataField,
  AddEditModalField,
  AddEditModalFieldBlur,
  AddEditModalFieldChange,
  AddEditModalDictionary
} from '@app/shared/models';
import { addEditModalConfig } from '@app/shared/configs';

@Injectable({
  providedIn: 'root'
})
export class AddEditModalService {
  readonly #metaData: WritableSignal<{ [key: string]: any; }> = signal({});
  readonly #extraTabTemplate = signal(null);

  readonly extraTabTemplate = this.#extraTabTemplate.asReadonly();
  readonly metaData = this.#metaData.asReadonly();

  readonly metaData$: Observable<{ [key: string]: any; }> = toObservable(this.metaData);

  isLoading: boolean;
  isEditMode: boolean;
  isSubmitting: boolean;
  form: UntypedFormGroup;
  modalRef: BsModalRef;
  editMode: TablePageRowEditMode;
  dictionary: AddEditModalDictionary = {};
  isValidFn: (data: any) => boolean;
  fieldsMessage: { [key: string]: string };
  eventData: Subject<EventData> = new Subject<EventData>();
  blurEvent: Subject<AddEditModalFieldBlur> = new Subject<AddEditModalFieldBlur>();
  action: EventEmitter<AddEditModalAction> = new EventEmitter<AddEditModalAction>();
  fieldChange: Subject<AddEditModalFieldChange> = new Subject<AddEditModalFieldChange>();

  constructor(
    private translateService: TranslateService,
    private toastr: LocalizedToastrService,
    private popupService: UPopupService,
    private authDataService: AuthDataService
  ) {}

  updateMetaData(data: { [key: string]: any; }) {
    this.#metaData.update(state => ({
      ...state,
      ...data
    }));
  }

  updateExtraTabTemplate(template: TemplateRef<any>) {
    this.#extraTabTemplate.set(template);
  }

  canSubmit(addEditForm: UntypedFormGroup): boolean {
    return addEditForm.disabled || addEditForm.valid && !this.isSubmitting;
  }

  closeConfirmModal(): Observable<{ action: AddEditModalAction; formValue?: any }> {
    if (!this.form.dirty) {
      this.hideModal();

      return of({ action: AddEditModalAction.ShutDown });
    }

    if (this.popupService.popupRef) {  return of(); }

    return new Observable((subscriber: Subscriber<{ action: AddEditModalAction; formValue?: any; }>) => {
      this.popupService.showMessage({
        showXIcon: true,
        message: addEditModalConfig.dictionary.closeConfirm,
        yes: addEditModalConfig.dictionary.yes,
        no: addEditModalConfig.dictionary.no
      },
      () => {
        this.submit()
          .pipe(take(1))
          .subscribe(res => {
            this.action.emit(AddEditModalAction.Submit);

            subscriber.next(res);
            subscriber.complete();
          });
      },
      () => {
        this.hideModal();

        this.action.emit(AddEditModalAction.ShutDown);

        subscriber.next({ action: AddEditModalAction.ShutDown });
        subscriber.complete();
      },
      () => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  submit(closeModal?: boolean): Observable<{ action: AddEditModalAction; formValue: any }> {
    return new Observable((subscriber: Subscriber<{ action: AddEditModalAction; formValue: any; }>) => {
      const formValue = this.getModalFormValue(this.form);

      if (this.canSubmit(this.form)) {
        if (this.isValidFn ? this.isValidFn(formValue) : true) {
          subscriber.next({ action: this.isEditMode ? AddEditModalAction.Edit : AddEditModalAction.Add, formValue });
          subscriber.complete();

          if (closeModal) {
            this.hideModal();
          }
        }
      } else {
        subscriber.complete();
        this.popupService.showErrorMessage({ message: addEditModalConfig.dictionary.invalidForm });
      }
    });
  }

  updateDictionary(dictionary: AddEditModalDictionary) {
    this.dictionary = { ...dictionary };
  }

  generateModalForm(modalConfig: AddEditModalConfig, data = {}): UntypedFormGroup {
    const modalForm = new UntypedFormGroup({});

    modalConfig.tabs.forEach(tab => this.addTabForm(modalForm, tab, tab.formGroupAutoInitTab ? data[tab.dataName] : data));

    this.addHiddenDataFields(modalForm, modalConfig.hiddenDataFields, data);

    this.isLoading = false;

    return modalForm;
  }

  addTabForm(modalForm: UntypedFormGroup, tab: AddEditModalTab, data) {
    let tabFieldsForm = modalForm;

    if (tab.formGroupTab) {
      modalForm.addControl(tab.dataName, new UntypedFormGroup({}));

      tabFieldsForm = modalForm.get(tab.dataName) as UntypedFormGroup;
    }

    tab.columns.forEach(column => {
      column.fields
        .filter(field => !field.dataField?.feature || this.authDataService.checkFeature(field.dataField?.feature))
        .forEach(field => {
          if (field.dataField) {
            tabFieldsForm.addControl(
              field.dataField.name,
              this.generateFieldForm(
                field.dataField,
                field.dataField.unwrapped ? data : data && data[field.dataField.name] !== undefined ?
                  data[field.dataField.name] : (this.isEditMode ? null : field.dataField.defaultValue || null)
              )
            );
          } else if (field.dataFields) {
            field.dataFields.forEach(dataField => {
              if (!dataField.feature || this.authDataService.checkFeature(dataField.feature)) {
                tabFieldsForm.addControl(
                  dataField.name,
                  this.generateFieldForm(
                    dataField,
                    data && data[dataField.name] !== undefined ? data[dataField.name] :
                      (this.isEditMode ? null : dataField.defaultValue || null)
                  )
                );
              }
            });
          }

          this.addMetadataToField(field);
        });
    });

    return this.modalRef;
  }

  addMetadataToField(field: AddEditModalField) {
    const metaData = this.metaData();

    if (metaData && field.config?.selectItemsMetadataKey) {
      field.config.selectItems = metaData[field.config.selectItemsMetadataKey];
    }
  }

  addHiddenDataFields(modalForm: UntypedFormGroup, hiddenDataFields: AddEditModalDataField[], data): void {
    if (hiddenDataFields) {
      hiddenDataFields.forEach(dataField => {
        modalForm.addControl(dataField.name, this.generateFieldForm(dataField, data && data[dataField.name] || (this.isEditMode ? null : dataField.defaultValue || null)));
      });
    }
  }

  generateFieldForm(field: AddEditModalDataField, data): UntypedFormControl | UntypedFormArray {
    let fieldForm;

    if (field.isArray) {
      fieldForm = new UntypedFormArray([]);

      if (data && Object.keys(data).length !== 0) {
        data.forEach(dataItem => {
          let fieldItemForm;

          if (field.arrayDataFields) {
            fieldItemForm = new UntypedFormGroup({});
            field.arrayDataFields.forEach(dataField => {
              if (!dataField.feature || this.authDataService.checkFeature(dataField.feature)) {
                fieldItemForm.addControl(dataField.name, this.generateFieldForm(dataField, this.getFieldDataValue(dataField, dataItem)));
              }
            });
          } else {
            if (!field.feature || this.authDataService.checkFeature(field.feature)) {
              fieldItemForm = new UntypedFormControl({
                value: data || data === 0 ? data : field.defaultValue || null,
                disabled: this.isEditMode && field.editReadonly || field.readonly
              }, [ ...(field.required ? [ Validators.required ] : []), ...(field.customValidatorName ? [ ValidationService[field.customValidatorName] ] : []) ]);
            }
          }

          fieldForm.push(fieldItemForm);
        });
      }
    } else if (field.isGroup && (!field.feature || this.authDataService.checkFeature(field.feature))) {
      fieldForm = new UntypedFormGroup({});

      field.groupDataFields.forEach(dataField => {
        if (!dataField.feature || this.authDataService.checkFeature(dataField.feature)) {
          fieldForm.addControl(dataField.name, this.generateFieldForm(dataField, this.getFieldDataValue(dataField, data)));
        }
      });
    } else {
      if (!field.feature || this.authDataService.checkFeature(field.feature)) {
        fieldForm = new UntypedFormControl({
          value: data || data === 0 || data === false ? data : (typeof field.defaultValue !== 'undefined' ? field.defaultValue : null),
          disabled: this.isEditMode && field.editReadonly || field.readonly
        }, [ ...(field.required ? [ Validators.required ] : []), ...(field.customValidatorName ? [ ValidationService[field.customValidatorName] ] : []) ]);
      }
    }

    return fieldForm;
  }

  getModalFormValue(form: UntypedFormGroup): any {
    return form.getRawValue() || {};
  }

  successCallback(): void {
    this.toastr.success(this.translateService.instant(this.dictionary.submitSuccess || addEditModalConfig.dictionary.submitSuccess));

    this.form.markAsPristine();
  }

  errorCallback(): void {
    this.popupService.showErrorMessage({ message: this.dictionary.error || addEditModalConfig.dictionary.error });
  }

  hideModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  reset(): void {
    this.form = new UntypedFormGroup({});
    this.isValidFn = undefined;
    this.isEditMode = undefined;
    this.modalRef = undefined;
  }

  getFieldDataValue(dataField: AddEditModalDataField, data): any {
    return (data && (data[dataField.name] || data[dataField.name] === 0 || data[dataField.name] === false)) ? data[dataField.name] : (this.isEditMode ? null : dataField.defaultValue || null);
  }

  updateFieldsMessage(fields: { [key: string]: string }): void {
    this.fieldsMessage = {
      ...this.fieldsMessage,
      ...fields
    };
  }
}
