import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, of, Subject, Subscriber } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NgProgress } from 'ngx-progressbar';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UPopupService } from '@shift/ulib';

import { appConfig } from '@app/shared/configs';
import { HeaderMenuIconsTemplate, VisibleComponent } from '@app/shared/models';
import { CommonService, HeaderDataService, HeaderMenuIconsService, OperationGuidService } from '@app/shared/services';
import { RoutesAiSuggestionsConfirmComponent } from '@app/routes/components';
import { routesConfig } from '@app/routes/configs';
import {
  RoutesAiTransportationSuggestionsStatusChangedAiJobState,
  RoutesTransportationAiSuggestionsType,
  RouteDailyRow,
  RoutesTransportationAiSuggestionsItem
} from '@app/routes/models';
import { RoutesTransportationAiSuggestionsService } from './routes-transportation-ai-suggestions.service';
import { RoutesAiTransportationSuggestionsHub } from './routes-ai-transportation-suggestions-hub.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesAiSuggestionsDataService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly ngProgress = inject(NgProgress);
  private readonly bsModalService = inject(BsModalService);
  private readonly operationGuidService = inject(OperationGuidService);
  private readonly headerDataService = inject(HeaderDataService);
  private readonly headerMenuIconsService = inject(HeaderMenuIconsService);
  private readonly commonService = inject(CommonService);
  private readonly transportationAiSuggestionsService = inject(RoutesTransportationAiSuggestionsService);
  private readonly aiTransportationSuggestionsHub = inject(RoutesAiTransportationSuggestionsHub);
  private readonly uPopupService = inject(UPopupService);

  private aiSuggestionsUnsubscribe = new Subject<void>();

  readonly #aiSuggestionsActive = signal<boolean>(false);
  readonly #aiSuggestionsLoading = signal<boolean>(false);
  readonly #transportationAiSuggestions = signal<RoutesTransportationAiSuggestionsItem[]>([]);
  readonly #rideIdByRouteIds = signal<Record<number, number>>({});
  readonly #headerMenuIconsTemplates = signal<{ defaultTemplate: HeaderMenuIconsTemplate; aiSuggestionsTemplate: HeaderMenuIconsTemplate; }>(null);

  readonly aiSuggestionsActive = this.#aiSuggestionsActive.asReadonly();
  readonly aiSuggestionsLoading = this.#aiSuggestionsLoading.asReadonly();
  readonly transportationAiSuggestions = this.#transportationAiSuggestions.asReadonly();
  readonly transportationAiSuggestionsByRideIds = computed<Record<number, RoutesTransportationAiSuggestionsItem>>(() => (
    this.transportationAiSuggestions().reduce((acc, item) => ({
      ...acc,
      [item.rideId]: item
    }), {})
  ));
  readonly hasAiSuggestions = computed(() => !!this.transportationAiSuggestions().length);

  private onAiTransportationSuggestionsStatusChanged() {
    this.aiTransportationSuggestionsHub.onAiTransportationSuggestionsStatusChanged()
      .pipe(
        takeUntil(this.aiSuggestionsUnsubscribe)
      )
      .subscribe(({ aiJobState }) => {
        switch (aiJobState) {
          case RoutesAiTransportationSuggestionsStatusChangedAiJobState.Updated:
          case RoutesAiTransportationSuggestionsStatusChangedAiJobState.Completed: {
            this.transportationAiSuggestionsService.getSuggestions()
              .pipe(
                takeUntil(this.aiSuggestionsUnsubscribe)
              )
              .subscribe(suggestions => {
                if (this.ngProgress.ref(appConfig.progressBarId).isStarted) {
                  this.ngProgress.ref(appConfig.progressBarId).complete();
                }

                this.#transportationAiSuggestions.set(suggestions ?? []);

                if (!this.#transportationAiSuggestions().length) {
                  this.disableAiSuggestions();
                }
              });

            break;
          }

          case RoutesAiTransportationSuggestionsStatusChangedAiJobState.Failed: {
            if (this.ngProgress.ref(appConfig.progressBarId).isStarted) {
              this.ngProgress.ref(appConfig.progressBarId).complete();
            }

            this.disableAiSuggestions();

            break;
          }
        }
      });
  }

  private onRouteUpdated() {
    this.aiTransportationSuggestionsHub.onRouteUpdated()
      .pipe(
        takeUntil(this.aiSuggestionsUnsubscribe)
      )
      .subscribe(({ routeId }) => this.removeTransportationAiSuggestions(routeId));
  }

  enableAiSuggestions() {
    this.#aiSuggestionsActive.set(true);

    this.headerDataService.updateIsDateDisabled(true);
    this.headerDataService.updateIsRoutesViewTypeDisabled(true);
    this.headerMenuIconsService.setMenuIconsByTemplate(this.#headerMenuIconsTemplates()?.aiSuggestionsTemplate);
    this.commonService.updateVisibleComponent(VisibleComponent.AiAutoAssignment, true);
  }

  disableAiSuggestions() {
    this.#aiSuggestionsActive.set(false);
    this.#rideIdByRouteIds.set({});
    this.#transportationAiSuggestions.set([]);

    if (this.ngProgress.ref(appConfig.progressBarId).isStarted) {
      this.ngProgress.ref(appConfig.progressBarId).complete();
    }

    this.headerDataService.updateIsDateDisabled(false);
    this.headerDataService.updateIsRoutesViewTypeDisabled(false);
    this.headerMenuIconsService.setMenuIconsByTemplate(this.#headerMenuIconsTemplates()?.defaultTemplate);
    this.commonService.updateVisibleComponent(VisibleComponent.AiAutoAssignment, false);

    this.aiSuggestionsUnsubscribe.next();
    this.aiTransportationSuggestionsHub.stop();
    this.operationGuidService.removeGuid();
  }

  openConfirmModal() {
    return this.bsModalService.show(
      RoutesAiSuggestionsConfirmComponent,
      {
        id: 'routes-ai-suggestions-confirm',
        class: 'u-modal u-modal_app-routes-ai-suggestions-confirm',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false
      }
    );
  }

  initAiSuggestions(routes: RouteDailyRow[], types: RoutesTransportationAiSuggestionsType[]) {
    this.#aiSuggestionsLoading.set(true);

    this.ngProgress.ref(appConfig.progressBarId).start();
    this.enableAiSuggestions();

    this.transportationAiSuggestionsService.initialize(routes.map(item => item.rideId), types)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.#aiSuggestionsLoading.set(false))
      )
      .subscribe(async guid => {
        this.#rideIdByRouteIds.set(routes.reduce((acc, item) => ({ ...acc, [item.routeId]: item.rideId }), {}));

        this.operationGuidService.setGuid(guid);

        await this.aiTransportationSuggestionsHub.initialize(guid);

        this.onAiTransportationSuggestionsStatusChanged();
        this.onRouteUpdated();
      });
  }

  initHeaderMenuIconsTemplates(defaultTemplate: HeaderMenuIconsTemplate, aiSuggestionsTemplate: HeaderMenuIconsTemplate) {
    this.#headerMenuIconsTemplates.set({ defaultTemplate, aiSuggestionsTemplate });
  }

  onDailyRoutesSelect(routes: RouteDailyRow[]) {
    if (this.#transportationAiSuggestions().length) {
      const transportationAiSuggestions = this.#transportationAiSuggestions().filter(item => routes.some(route => route.rideId === item.rideId));

      if (!transportationAiSuggestions.length) {
        this.disableAiSuggestions();
      } else {
        this.#transportationAiSuggestions.set(transportationAiSuggestions);
      }
    }
  }

  removeTransportationAiSuggestions(routeId: number) {
    const rideId = this.#rideIdByRouteIds()[routeId];

    this.#transportationAiSuggestions.update(items => items.filter(item => item.rideId !== rideId));

    if (!this.#transportationAiSuggestions().length) {
      this.disableAiSuggestions();
    }
  }

  closeMessage(): Observable<boolean> {
    if (!this.#aiSuggestionsActive()) {
      return of(true);
    }

    return new Observable((subscriber: Subscriber<boolean>) => {
      this.uPopupService.showMessage(
        {
          message: routesConfig.dictionary.aiSuggestions.exitAiSuggestions,
          yes: routesConfig.dictionary.aiSuggestions.exit,
          no: routesConfig.dictionary.no
        },
        () => {
          this.disableAiSuggestions();
          subscriber.next(true);
          subscriber.complete();
        },
        () => {
          subscriber.next(false);
          subscriber.complete();
        },
        () => {
          subscriber.next(false);
          subscriber.complete();
        }
      );
    });
  }
}
