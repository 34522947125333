<u-modal-entity
  class="add-edit-modal u-modal-entity_app-add-edit-modal"
  [ngClass]="{
    'u-modal-entity_app-add-edit-modal_no-vertical-scroll': config.noVerticalScrollTabs &&
      ((config.noVerticalScrollTabs | includes: activeSubTabName) || (config.noVerticalScrollTabs | includes: activeTabName)),
    'u-modal-entity_app-add-edit-modal_no-margin': config.noMarginTabs &&
      ((config.noMarginTabs | includes: activeSubTabName) || (config.noMarginTabs | includes: activeTabName)),
    'u-modal-entity_app-add-edit-modal_split-layout': addEditModalService.editMode === tablePageRowEditMode.SplitLayout
  }"
  [title]="addEditModalService.editMode === tablePageRowEditMode.Modal ? (config.title | translate) : ''"
  [titleTooltip]="titleTooltip"
  [buttons]="modalButtons"
  [isRtl]="isRtl"
  [formChanged]="true"
  (action)="checkActions($event)"
>
  <tabset
    class="u-tabset-pills u-tabset-pills_modal u-tabset-pills_app-add-edit-modal add-edit-modal__tabs"
    type="pills"
    [ngClass]="{ 'u-tabset-pills_app-add-edit-modal_split-layout': addEditModalService.editMode === tablePageRowEditMode.SplitLayout }"
  >
    <tab
      *ngFor="let tab of config.tabs | addEditModalTabsFilter : addEditModalService"
      class="add-edit-modal__tab"
      [id]="tab.dataName"
      [disabled]="!!tab.subTabs"
      (selectTab)="onSelectTab(tab)"
    >
      <ng-template tabHeading>
        <div
          class="add-edit-modal__tab-header-wrapper"
          (mouseenter)="tabHeaderMouseEnter(tab, tabHeaderDropdownPopover)"
          (mouseleave)="tabHeaderMouseLeave(tab, tabHeaderDropdownPopover)"
        >
          <div
            class="add-edit-modal__tab-header tab-{{tab.dataName}}"
            #tabHeaderDropdownPopover="uPopover"
            [ngClass]="{
              'add-edit-modal__tab-header_active': tab.dataName === activeTabName,
              'add-edit-modal__tab-header_invalid': config.showInvalidTabIndicator && form.controls[tab.dataName] && form.controls[tab.dataName].invalid
            }"
            [uPopover]="tab.subTabs && tabHeaderDropdown"
            [placement]="'bottom'"
            [triggers]="'manual'"
            [autoClose]="false"
            [showArrow]="false"
            [popoverClass]="'u-popover_rounded u-popover_app-add-edit-modal'"
          >
            {{tab.title | translate}}{{tab.subTabs ? ' ▾' : ''}}
          </div>

          <ng-template #tabHeaderDropdown>
            <div
              class="add-edit-modal__tab-header-dropdown"
            >
              <div
                *ngFor="let subTab of tab.subTabs"
                class="add-edit-modal__tab-header-dropdown-item"
                [uId]="'add-edit-modal-sub-tab-' + subTab.dataName"
                (click)="tabHeaderDropdownItemClick(tab, subTab, tabHeaderDropdownPopover)"
              >
                {{subTab.title | translate}}
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>

      <ng-container *ngTemplateOutlet="tabContent; context: { $implicit: tab }"></ng-container>
    </tab>

    @if (config.actions?.length || addEditModalService.extraTabTemplate()) {
      <tab
        class="add-edit-modal__tab"
        [id]="'add-edit-modal-tab-extra'"
        [customClass]="'add-edit-modal__tab_extra'"
        [disabled]="true"
      >
        <ng-template tabHeading>
          <div class="add-edit-modal__extra-tab">
            @if (addEditModalService.extraTabTemplate()) {
              <ng-container
                [ngTemplateOutlet]="addEditModalService.extraTabTemplate()"
              />
            }

            @if (config.actions?.length) {
              <u-droplist
                class="u-droplist_app-add-edit-modal-tab-actions"
                [selectedClickable]="false"
                [items]="config.actions"
                [selected]="{ value: '', name: 'general.actions' }"
                (changeSelected)="onActionChange($event)"
              />
            }
          </div>
        </ng-template>
      </tab>
    }

    <ng-container *ngFor="let tab of config.tabs">
      <tab
        *ngFor="let subTab of tab.subTabs"
        class="add-edit-modal__tab"
        [id]="tab.dataName + '-' + subTab.dataName"
        [active]="activeSubTabName === subTab.dataName"
      >
        <ng-container *ngTemplateOutlet="tabContent; context: { $implicit: subTab }"></ng-container>
      </tab>
    </ng-container>
  </tabset>
</u-modal-entity>

<ng-template
  #tabContent
  let-tab
>
  <div
    *ngIf="
      tab.formGroupTab || tab.formCustom ? form.controls[tab.dataName] :
      (tab.columns.length === 1 && tab.columns[0].fields.length === 1 && tab.columns[0].fields[0].dataField) ?
      form.controls[tab.columns[0].fields[0].dataField.name] : form
    "
    class="u-modal__body add-edit-modal__tab-content"
    [class.add-edit-modal__tab-content_multiple-rows]="tab.multipleRows"
    [class.add-edit-modal__tab-content_full-height]="tab.fullHeight"
  >
    <div
      *ngFor="let column of tab.columns | addEditModalColumnsCheckFeature"
      class="add-edit-modal__tab-column"
      [class.add-edit-modal__tab-column_next-row]="column.nextRow"
    >
      <div
        *ngIf="column.title"
        class="u-text-line u-text-line_app-title add-edit-modal__tab-column-title"
        [class.add-edit-modal__tab-column-title_no-margin]="column.subtitle"
      >
        {{column.title | translate}}
      </div>
      <div
        *ngIf="column.subtitle"
        class="add-edit-modal__tab-column-subtitle"
      >
        {{column.subtitle | translate}}
      </div>
      <div class="add-edit-modal__tab-column-fields">
        <app-dynamic-field
          *ngFor="let field of (column.fields | addEditModalFieldsCheckFeature) | addEditModalFieldsCheckConditions: form.value"
          [field]="field"
          [addEditModalService]="addEditModalService"
          [form]="
            tab.formGroupTab || tab.formCustom ?
            (field.dataField ? form.controls[tab.dataName] && form.controls[tab.dataName].controls[field.dataField.name] : form.controls[tab.dataName]):
            (field.dataField ? form.controls[field.dataField.name] : form)
          "
        ></app-dynamic-field>
      </div>
    </div>
  </div>
</ng-template>
