import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BsModalService } from 'ngx-bootstrap/modal';
import { max } from 'lodash';

import { LoadingDataService } from '@app/shared/services';
import { RouteDailyRow } from '@app/routes/models';
import { routesConfig } from '@app/routes/configs';
import { RouteSplitComponent } from '@app/route-split/components';

@Injectable()
export class RoutesSplitRouteModalService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly bsModalService = inject(BsModalService);
  private readonly loadingDataService = inject(LoadingDataService);

  readonly #routeIdToEdit = signal<number>(null);

  readonly routeIdToEdit = this.#routeIdToEdit.asReadonly();

  openModal(route: RouteDailyRow, viewportElement: HTMLElement): void {
    this.bsModalService.show(
      RouteSplitComponent,
      {
        class: 'u-modal u-modal_app-routes-daily-route-split',
        animated: true,
        ignoreBackdropClick: true,
        backdrop: true,
        keyboard: false,
        initialState: {
          route: {
            id: route.routeId,
            direction: route.direction,
            allowEmptyStations: route.allowEmptyStations
          },
          trackingId: routesConfig.trackingId,
          viewportElement
        }
      }
    )
      .content
      .splitAction
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(routeIds => {
        this.#routeIdToEdit.set(max(routeIds));

        this.loadingDataService.updateRefresh();
      });
  }

  updateRouteIdToEdit(routeId: number): void {
    this.#routeIdToEdit.set(routeId);
  }
}
