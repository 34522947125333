import {
  AuthModuleBulkChangesFeature,
  AuthModuleBulkChangesFeatureType,
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureExport,
  AuthModuleReportsFeature,
  AuthModuleRoutesFeature,
  AuthPermission
} from '@app/auth/models';
import { GridHeaderButton, GridHeaderButtonValue, GridHeaderTemplate } from '@app/shared/models';

const DICTIONARY_PATH: string = 'shared.gridHeader';

const resetFiltersButton: GridHeaderButton = {
  id: GridHeaderButtonValue.ResetFilters,
  name: `${DICTIONARY_PATH}.buttons.resetFilters`,
  value: GridHeaderButtonValue.ResetFilters,
  showWhenFilteredColumns: true
};

const deleteButton: GridHeaderButton = {
  id: GridHeaderButtonValue.Delete,
  name: `${DICTIONARY_PATH}.buttons.delete`,
  value: GridHeaderButtonValue.Delete,
  class: 'u-grid-header__button_app-delete-btn'
};

const excelButton: GridHeaderButton = {
  id: GridHeaderButtonValue.Excel,
  name: 'Excel',
  value: GridHeaderButtonValue.Excel
};

const pdfButton: GridHeaderButton = {
  id: GridHeaderButtonValue.Pdf,
  name: 'PDF',
  value: GridHeaderButtonValue.Pdf
};

const pdfSCReportButton: GridHeaderButton = {
  id: GridHeaderButtonValue.PdfSCReport,
  name: `${DICTIONARY_PATH}.buttons.pdfSCReport`,
  value: GridHeaderButtonValue.PdfSCReport
};

const pdfBillingReportButton: GridHeaderButton = {
  id: GridHeaderButtonValue.PdfBillingReport,
  name: `${DICTIONARY_PATH}.buttons.pdfBillingReport`,
  value: GridHeaderButtonValue.PdfBillingReport,
  feature: {
    module: AuthModuleName.Reports,
    name: AuthModuleReportsFeature.ActualExpensesPdfBilling
  }
};

const bulkChangeButton: GridHeaderButton = {
  id: GridHeaderButtonValue.BulkChange,
  name: `${DICTIONARY_PATH}.buttons.bulkAction`,
  value: GridHeaderButtonValue.BulkChange,
  feature: {
    module: AuthModuleName.BulkChanges,
    name: AuthModuleBulkChangesFeature.Type,
    values: [
      AuthModuleBulkChangesFeatureType.Generic,
      AuthModuleBulkChangesFeatureType.ShuttleCompany
    ]
  }
};

const routeDetailsPdfButton: GridHeaderButton = {
  id: GridHeaderButtonValue.PdfRouteDetails,
  value: GridHeaderButtonValue.PdfRouteDetails,
  name: `${DICTIONARY_PATH}.buttons.routeDetailsPdf`
};

const routeDetailsExcelButton: GridHeaderButton = {
  id: GridHeaderButtonValue.RouteDetailsExcel,
  name: `${DICTIONARY_PATH}.buttons.routeDetailsExcel`,
  value: GridHeaderButtonValue.RouteDetailsExcel
};

const dailyAgendaExcelButton: GridHeaderButton = {
  id: GridHeaderButtonValue.DailyAgendaExcel,
  name: `${DICTIONARY_PATH}.buttons.dailyAgendaExcel`,
  value: GridHeaderButtonValue.DailyAgendaExcel
};

const reOptimizationButton: GridHeaderButton = {
  id: GridHeaderButtonValue.ReOptimization,
  name: `${DICTIONARY_PATH}.buttons.reOptimization`,
  value: GridHeaderButtonValue.ReOptimization,
  class: 'u-grid-header__button_app-re-optimization-btn'
};

const cleanupButton: GridHeaderButton = {
  id: GridHeaderButtonValue.Cleanup,
  name: `${DICTIONARY_PATH}.buttons.cleanup`,
  value: GridHeaderButtonValue.Cleanup,
  class: 'u-grid-header__button_app-re-optimization-btn'
};

const sendServiceSheetButton: GridHeaderButton = {
  id: GridHeaderButtonValue.SendServiceSheet,
  name: `${DICTIONARY_PATH}.buttons.sendServiceSheet`,
  value: GridHeaderButtonValue.SendServiceSheet,
  showAlways: true
};

const routesDailyButtons = [
  resetFiltersButton,
  bulkChangeButton,
  routeDetailsPdfButton,
  routeDetailsExcelButton,
  dailyAgendaExcelButton,
  {
    ...deleteButton,
    feature: {
      module: AuthModuleName.Routes,
      name: AuthModuleRoutesFeature.DeleteExitingRoutes
    }
  }
];

export const gridHeaderComponentConfig = {
  buttonsByTemplates: {
    [GridHeaderTemplate.Default]: [
      resetFiltersButton
    ],
    [GridHeaderTemplate.General]: [
      resetFiltersButton,
      deleteButton
    ],
    [GridHeaderTemplate.Passengers]: [
      resetFiltersButton,
      {
        ...deleteButton,
        permission: AuthPermission.ManagePassengers
      }
    ],
    [GridHeaderTemplate.RoutesDaily]: routesDailyButtons,
    [GridHeaderTemplate.RoutesDailyAiSuggestions]: [],
    [GridHeaderTemplate.RoutesDailyReOptimization]: [
      ...routesDailyButtons,
      reOptimizationButton
    ],
    [GridHeaderTemplate.RoutesDailyReOptimizationEmptyStations]: [
      ...routesDailyButtons,
      cleanupButton
    ],
    [GridHeaderTemplate.RoutesWeekly]: [
      resetFiltersButton,
      bulkChangeButton,
      routeDetailsPdfButton,
      {
        ...deleteButton,
        feature: {
          module: AuthModuleName.Routes,
          name: AuthModuleRoutesFeature.DeleteExitingRoutes
        }
      }
    ],
    [GridHeaderTemplate.RoutesDailySC]: [
      resetFiltersButton,
      bulkChangeButton,
      routeDetailsPdfButton,
      routeDetailsExcelButton,
      dailyAgendaExcelButton
    ],
    [GridHeaderTemplate.RoutesWeeklySC]: [
      resetFiltersButton,
      bulkChangeButton,
      routeDetailsPdfButton
    ],
    [GridHeaderTemplate.Accompanies]: [
      resetFiltersButton,
      excelButton,
      {
        ...deleteButton,
        permission: AuthPermission.ManageAccompanies
      }
    ],
    [GridHeaderTemplate.Accounting]: [
      resetFiltersButton
    ],
    [GridHeaderTemplate.Schools]: [
      resetFiltersButton,
      excelButton,
      deleteButton
    ],
    [GridHeaderTemplate.DemandsPassengersView]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ShuttleCompanies]: [
      resetFiltersButton,
      excelButton,
      {
        ...deleteButton,
        permission: AuthPermission.ManageShuttleCompanies
      }
    ],
    [GridHeaderTemplate.Stations]: [
      resetFiltersButton,
      excelButton,
      deleteButton
    ],
    [GridHeaderTemplate.PassengersArmy]: [
      resetFiltersButton,
      {
        ...deleteButton,
        permission: AuthPermission.ManagePassengers
      }
    ],
    [GridHeaderTemplate.PassengersMunicipality]: [
      resetFiltersButton,
      {
        ...excelButton,
        feature: {
          module: AuthModuleName.Passengers,
          name: AuthModulePassengersFeature.Export,
          values: [ AuthModulePassengersFeatureExport.Student ]
        }
      },
      {
        ...deleteButton,
        permission: AuthPermission.ManagePassengers
      }
    ],
    [GridHeaderTemplate.ReportsAccompanyHours]: [
      resetFiltersButton,
      excelButton,
      pdfButton
    ],
    [GridHeaderTemplate.ReportsAgenda]: [
      resetFiltersButton,
      excelButton,
      pdfButton
    ],
    [GridHeaderTemplate.ReportsChangesHistory]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ReportsExpenses]: [
      resetFiltersButton,
      excelButton,
      pdfBillingReportButton,
      pdfSCReportButton
    ],
    [GridHeaderTemplate.ReportsExpensesSC]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ReportsExpensesSummary]: [
      resetFiltersButton,
      excelButton,
      pdfSCReportButton
    ],
    [GridHeaderTemplate.ReportsExpensesSummarySC]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ReportsIncomeDetailed]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ReportsIncomeSummary]: [
      resetFiltersButton,
      excelButton
    ],
    [GridHeaderTemplate.ReportsServiceSheet]: [
      sendServiceSheetButton,
      resetFiltersButton
    ],
    [GridHeaderTemplate.ReportsScExpenses]: [
      excelButton
    ]
  }
};
