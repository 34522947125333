import { Injectable, OnDestroy, WritableSignal, signal } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UPopupService } from '@shift/ulib';

import { ModalActions } from '@app/shared/models';
import { AuthModulePassengersFeatureType } from '@app/auth/models';
import { AuthDataService } from '@app/auth/services';
import { PassengersService } from '@app/passengers/services/passengers.service';
import {
  Passenger,
  PassengerDetails,
  PassengersHistoryRow,
  PassengersTabName
} from '@app/passengers/models';
import { CommandersService } from '@app/ride-commanders/services';
import { SupervisorsService } from '@app/ride-supervisors/services';
import { passengersConfig } from '@app/passengers/configs';
import { PassengersRoutesPopupService } from '@app/passengers/services/passengers-routes-popup.service';

@Injectable({
  providedIn: 'root'
})
export class PassengersAddEditDataService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  readonly #selectedTab: WritableSignal<PassengersTabName> = signal(PassengersTabName.Details);
  readonly #passengersHistoryRows: WritableSignal<PassengersHistoryRow[]> = signal([]);

  readonly selectedTab = this.#selectedTab.asReadonly();
  readonly passengersHistoryRows = this.#passengersHistoryRows.asReadonly();

  constructor(
    private fb: FormBuilder,
    private uPopupService: UPopupService,
    private passengersService: PassengersService,
    private commandersService: CommandersService,
    private supervisorsService: SupervisorsService,
    private passengersRoutesPopupService: PassengersRoutesPopupService,
    private authDataService: AuthDataService
  ) {}

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private showRoutesPopupError() {
    this.uPopupService.showMessage({
      message: passengersConfig.dictionary.confirmRoutesPopup.message,
      yes: passengersConfig.dictionary.confirmRoutesPopup.confirm
    }, null);
  }

  private saveSupervisor(supervisor: PassengerDetails, isSupervisor: boolean = true): Observable<PassengerDetails> {
    if (isSupervisor) {
      return this.supervisorsService.saveSupervisor(supervisor);
    }

    return this.commandersService.saveCommander(supervisor);
  }

  savePassengerAndCheckRoutes(currentPassenger: Passenger, featureType: AuthModulePassengersFeatureType, wasEditedByRoutesPopup: boolean = false): Observable<[ PassengerDetails, boolean ]> {
    return this.passengersService.savePassenger(currentPassenger)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            if (wasEditedByRoutesPopup) {
              this.showRoutesPopupError();
            }

            return of(null);
          }

          const passenger = response.value;
          const passengerRoutes = passenger.passengerRoutes;

          if (passengerRoutes && passengerRoutes.length) {
            return this.passengersRoutesPopupService.showRoutesPopup(passenger.passengerId, passengerRoutes)
              .pipe(
                switchMap(({ type, params }) => {
                  switch (type) {
                    case ModalActions.Save: {
                      return this.savePassengerAndCheckRoutes(
                        {
                          details: {
                            ...passenger,
                            passengerRoutes: params.routes
                          },
                          settings: currentPassenger.settings
                        },
                        featureType,
                        true
                      );
                    }

                    case ModalActions.Cancel: {
                      return of(null);
                    }
                  }
                }),
                takeUntil(this.unsubscribe)
              );
          }

          return forkJoin([
            of(passenger),
            of(wasEditedByRoutesPopup)
          ]);
        })
      );
  }

  saveSupervisorAndCheckRoutes(currentPassenger: PassengerDetails, isSupervisor: boolean = true): Observable<PassengerDetails> {
    return this.saveSupervisor(currentPassenger, isSupervisor)
      .pipe(
        switchMap(passenger => {
          if (!passenger) {
            this.showRoutesPopupError();

            return of(null);
          }

          const passengerRoutes = passenger.passengerRoutes;

          if (passengerRoutes && passengerRoutes.length) {
            return this.passengersRoutesPopupService.showRoutesPopup(passenger.passengerId, passengerRoutes)
              .pipe(
                switchMap(({ type, params }) => {
                  switch (type) {
                    case ModalActions.Save: {
                      return this.saveSupervisorAndCheckRoutes(
                        {
                          ...passenger,
                          passengerRoutes: params.routes
                        },
                        isSupervisor
                      );
                    }

                    case ModalActions.Cancel: {
                      return of(null);
                    }
                  }
                }),
                takeUntil(this.unsubscribe)
              );
          }

          return of(passenger);
        })
      );
  }

  updateSelectedTab(tabName: PassengersTabName) {
    this.#selectedTab.set(tabName);
  }

  onTabSelect(tabName: PassengersTabName, addEditForm: UntypedFormGroup): Observable<any> {
    if (addEditForm.controls[tabName]) {
      this.updateSelectedTab(tabName);

      return of(null);
    }

    switch(tabName) {
      case PassengersTabName.Logs: {
        return this.passengersService.getAuditLogs(addEditForm.get('details.passengerId').value)
          .pipe(
            tap(logs => {
              this.#passengersHistoryRows.set(logs);

              this.updateSelectedTab(tabName);
            }),
            take(1)
          );
      }

      case PassengersTabName.Settings: {
        return this.passengersService.getPassengerSettings(addEditForm.get('details.passengerId').value)
          .pipe(
            tap(settings => {
              addEditForm.addControl(tabName, this.fb.group({
                isSmsNotificationsEnabled: [ settings.isSmsNotificationsEnabled ],
                travelingAlone: [ settings.travelingAlone ],
                rideSmsEventsMode: [ settings.rideSmsEventsMode ],
                rideSmsEventsOptions: [ settings.rideSmsEventsOptions ],
                permissions: this.fb.group({
                  rideReservationEnabled: [ settings.permissions.rideReservationEnabled ],
                  joinRouteTypes: [ { value: settings.permissions.joinRouteTypes, disabled: !settings.permissions.joinRouteTypes?.length } ],
                  joinRideEnabled: [ !!settings.permissions.joinRouteTypes?.length ],
                  createRouteTypes: [ { value: settings.permissions.createRouteTypes, disabled: !settings.permissions.createRouteTypes?.length } ],
                  createRideEnabled: [ !!settings.permissions.createRouteTypes?.length ]
                })
              }));

              if (!this.authDataService.managePassengers()) {
                addEditForm.controls?.[tabName]?.disable();
              }

              this.updateSelectedTab(tabName);
            }),
            take(1)
          );
      }

      default: {
        this.updateSelectedTab(tabName);

        return of(null);
      }
    }
  }

  resetData() {
    this.#selectedTab.set(PassengersTabName.Details);
    this.#passengersHistoryRows.set([]);
  }
}
