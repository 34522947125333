import { Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { LocalizationService, ValidationService } from '@app/shared/services';
import { AddEditModalFieldChange, ContactType } from '@app/shared/models';
import inputCommunicationsConfig from './input-communications.component.config';

@Component({
  selector: 'app-input-communications',
  templateUrl: './input-communications.component.html',
  styleUrls: [ './input-communications.component.scss', './input-communications.component.rtl.scss' ]
})
export class InputCommunicationsComponent implements OnInit {
  @Input() dumbComponent: boolean; // Dumb component means it will not modify original form, but only send events
  @Input() showTitle: boolean = true;
  @Input() showAddIcon: boolean = true;
  @Input() showRemoveIconInLastContact: boolean = false;
  @Input() form: UntypedFormArray = new UntypedFormArray([]);

  @Output() changeEvent: EventEmitter<AddEditModalFieldChange> = new EventEmitter<AddEditModalFieldChange>();
  @Output() addContactAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeContactAction: EventEmitter<{ index: number; }> = new EventEmitter<{ index: number; }>();

  @HostBinding('class') hostClasses: string = 'input-communications';

  private readonly fb = inject(UntypedFormBuilder);
  private readonly destroyRef = inject(DestroyRef);
  private readonly localizationService = inject(LocalizationService);
  private readonly validationService = inject(ValidationService);

  isRtl: boolean = this.localizationService.isRtl();
  config = cloneDeep(inputCommunicationsConfig);
  contactTypeNames: {[key: number]: string} = {};

  ngOnInit(): void {
    if (this.form && this.form.controls.length) {
      this.form.controls.forEach(contactForm => this.setFormValidators(contactForm as UntypedFormGroup));
    }

    this.config.contactTypes.forEach(contactType => {
      this.contactTypeNames[<number>contactType.value] = contactType.name;
    });
  }

  removeContact(i: number): void {
    this.removeContactAction.emit({ index: i });

    if (this.dumbComponent) { return; }

    this.form.markAsDirty();
    this.form.removeAt(i);
  }

  setFormValidators(contactForm: UntypedFormGroup): void {
    const type = contactForm.get('type');
    const data = contactForm.get('value') || contactForm.get('contact');

    this.validationService.updateValidationContactData(data, type.value);

    type.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(value => !!value)
      )
      .subscribe((value: ContactType) => this.validationService.updateValidationContactData(data, value));
  }

  addContact(): void {
    this.addContactAction.emit();

    if (this.dumbComponent) { return; }

    const newContactForm = this.fb.group({
      id: [ 0 ],
      type: [ ContactType.Mobile, Validators.required ]
    });

    // If u ever see this code and wonder what the hell is going on here
    // then ask BE guys why we have so many different models for the same thing
    if (this.form.controls[0]) {
      if (this.form.controls[0].get('value')) {
        newContactForm.addControl('value', new UntypedFormControl('', Validators.required));
      } else {
        newContactForm.addControl('contact', new UntypedFormControl('', Validators.required));
      }
    }

    this.setFormValidators(newContactForm);

    this.form.markAsDirty();
    this.form.push(newContactForm);
  }

  onCommunicationChange(newVal: string, itemIndex: number): void {
    this.changeEvent.emit({ fieldName: 'communications', newVal, itemIndex });
  }
}
