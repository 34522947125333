import {
  EnvType,
  HeaderMenuIcon,
  HeaderMenuIconCheckFnName,
  HeaderMenuIconChild,
  HeaderMenuIconFetchItemsKey,
  HeaderMenuIconsTemplate,
  HeaderMenuIconValue
} from '@app/shared/models';
import {
  AuthCustomerType,
  AuthModuleFeatureGroupCondition,
  AuthModuleName,
  AuthModulePassengersFeature,
  AuthModulePassengersFeatureType,
  AuthModuleRouteBuilderFeature,
  AuthModuleRoutePlannerFeature,
  AuthModuleRoutePlannerFeatureType,
  AuthModuleTransportationFeature,
  AuthModuleTransportationFeatureExecutorType,
  AuthPermission,
  AuthPermissionGroupCondition
} from '@app/auth/models';

const ID_PREFIX: string = 'header-menu-icons';
const DICTIONARY_PATH: string = 'shared.header.menuIcons';

const icons: { [key: string]: HeaderMenuIcon; } = {
  [HeaderMenuIconValue.Plus]: {
    value: HeaderMenuIconValue.Plus,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.Plus}`,
    name: `${DICTIONARY_PATH}.new`,
    urls: {
      default: '/assets/images/header/plus.svg',
      hover: '/assets/images/header/plus-hover.svg'
    }
  },
  [HeaderMenuIconValue.Feed]: {
    value: HeaderMenuIconValue.Feed,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.Feed}`,
    name: `${DICTIONARY_PATH}.feed`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/feed.svg',
      hover: '/assets/images/header/feed-hover.svg'
    }
  },
  [HeaderMenuIconValue.AiAutoAssignment]: {
    value: HeaderMenuIconValue.AiAutoAssignment,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.AiAutoAssignment}`,
    name: `${DICTIONARY_PATH}.aiAutoAssignment`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/ai.svg',
      hover: '/assets/images/header/ai-hover.svg'
    },
    feature: {
      module: AuthModuleName.RoutesTransportAi
    }
  },
  [HeaderMenuIconValue.MovePassengers]: {
    value: HeaderMenuIconValue.MovePassengers,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.MovePassengers}`,
    name: `${DICTIONARY_PATH}.movePassengersDaily`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/move-passengers.svg',
      hover: '/assets/images/header/move-passengers-hover.svg'
    },
    feature: {
      module: AuthModuleName.Passengers
    }
  },
  [HeaderMenuIconValue.Activities]: {
    value: HeaderMenuIconValue.Activities,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.Activities}`,
    name: `${DICTIONARY_PATH}.notifications`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/bell.svg',
      hover: '/assets/images/header/bell-hover.svg'
    }
  },
  [HeaderMenuIconValue.MonitoringDashboard]: {
    value: HeaderMenuIconValue.MonitoringDashboard,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.MonitoringDashboard}`,
    name: `${DICTIONARY_PATH}.monitoringDashboard`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/location.svg',
      hover: '/assets/images/header/location-hover.svg'
    }
  },
  [HeaderMenuIconValue.SendChanges]: {
    value: HeaderMenuIconValue.SendChanges,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.SendChanges}`,
    name: `${DICTIONARY_PATH}.sendChanges`,
    urls: {
      default: '/assets/images/header/plane.svg',
      hover: '/assets/images/header/plane-hover.svg'
    }
  },
  [HeaderMenuIconValue.Notes]: {
    value: HeaderMenuIconValue.Notes,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.Notes}`,
    name: `${DICTIONARY_PATH}.notes`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/notes.svg',
      hover: '/assets/images/header/notes-hover.svg'
    }
  },
  [HeaderMenuIconValue.Refresh]: {
    value: HeaderMenuIconValue.Refresh,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.Refresh}`,
    name: `${DICTIONARY_PATH}.refresh`,
    urls: {
      default: '/assets/images/header/refresh.svg',
      hover: '/assets/images/header/refresh-hover.svg'
    }
  },
  [HeaderMenuIconValue.PassengersImport]: {
    value: HeaderMenuIconValue.PassengersImport,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.PassengersImport}`,
    name: `${DICTIONARY_PATH}.passengersImport`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/import.svg',
      hover: '/assets/images/header/import-hover.svg'
    }
  },
  [HeaderMenuIconValue.ImportContract]: {
    value: HeaderMenuIconValue.ImportContract,
    id: `${ID_PREFIX}-${HeaderMenuIconValue.ImportContract}`,
    name: `${DICTIONARY_PATH}.importContract`,
    activateOnClick: true,
    urls: {
      default: '/assets/images/header/import.svg',
      hover: '/assets/images/header/import-hover.svg'
    },
    feature: {
      module: AuthModuleName.Transportation,
      name: AuthModuleTransportationFeature.ShuttleCompanyContractImport
    }
  }
};

const children: { [key: string]: HeaderMenuIconChild; } = {
  [HeaderMenuIconValue.NewBuilder]: {
    value: HeaderMenuIconValue.NewBuilder,
    name: `${DICTIONARY_PATH}.addTrack.newBuilder`,
    features: [
      {
        module: AuthModuleName.RouteBuilder,
        name: AuthModuleRouteBuilderFeature.NewRoute
      }
    ],
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.NewBuilderFull]: {
    value: HeaderMenuIconValue.NewBuilderFull,
    name: `${DICTIONARY_PATH}.addTrack.newBuilderFull`
  },
  [HeaderMenuIconValue.NewPassenger]: {
    value: HeaderMenuIconValue.NewPassenger,
    name: `${DICTIONARY_PATH}.newPassenger`,
    feature: {
      module: AuthModuleName.Passengers,
      name: AuthModulePassengersFeature.Type,
      values: [
        AuthModulePassengersFeatureType.Generic,
        AuthModulePassengersFeatureType.Soldier,
        AuthModulePassengersFeatureType.Student,
        AuthModulePassengersFeatureType.Iec
      ]
    },
    permission: AuthPermission.ManagePassengers
  },
  [HeaderMenuIconValue.RegularRoute]: {
    value: HeaderMenuIconValue.RegularRoute,
    name: `${DICTIONARY_PATH}.regularRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.FixedRoute]: {
    value: HeaderMenuIconValue.FixedRoute,
    name: `${DICTIONARY_PATH}.fixedRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.ShiftsRoute]: {
    value: HeaderMenuIconValue.ShiftsRoute,
    name: `${DICTIONARY_PATH}.shiftsRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.SpecialRoute]: {
    value: HeaderMenuIconValue.SpecialRoute,
    name: `${DICTIONARY_PATH}.specialRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.ShuttleRoute]: {
    value: HeaderMenuIconValue.ShuttleRoute,
    name: `${DICTIONARY_PATH}.shuttleRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.DeliveryRoute]: {
    value: HeaderMenuIconValue.DeliveryRoute,
    name: `${DICTIONARY_PATH}.deliveryRoute`,
    fetchItemsKey: HeaderMenuIconFetchItemsKey.RouteTypes,
    checkFnName: HeaderMenuIconCheckFnName.CheckRouteTypes,
    feature: {
      module: AuthModuleName.RouteBuilder,
      name: AuthModuleRouteBuilderFeature.NewRoute
    },
    permission: AuthPermission.CreateRoutes
  },
  [HeaderMenuIconValue.AddNewRoute]: {
    value: HeaderMenuIconValue.AddNewRoute,
    name: `${DICTIONARY_PATH}.addNewRoute`,
    permission: AuthPermission.CreateRoutes,
    disabled: true,
    features: [
      {
        module: AuthModuleName.RouteBuilder,
        name: AuthModuleRouteBuilderFeature.NewRoute
      }
    ]
  },
  [HeaderMenuIconValue.AddNewTemplate]: {
    value: HeaderMenuIconValue.AddNewTemplate,
    name: `${DICTIONARY_PATH}.addNewTemplate`,
    permission: AuthPermission.CreateRoutes,
    disabled: true,
    features: [
      {
        module: AuthModuleName.RouteBuilder,
        name: AuthModuleRouteBuilderFeature.NewRoute
      }
    ]
  },
  [HeaderMenuIconValue.Quote]: {
    value: HeaderMenuIconValue.Quote,
    name: `${DICTIONARY_PATH}.quote`
  },
  [HeaderMenuIconValue.ProFormaInvoice]: {
    value: HeaderMenuIconValue.ProFormaInvoice,
    name: `${DICTIONARY_PATH}.proFormaInvoice`
  },
  [HeaderMenuIconValue.TaxInvoice]: {
    value: HeaderMenuIconValue.TaxInvoice,
    name: `${DICTIONARY_PATH}.taxInvoice`
  },
  [HeaderMenuIconValue.TaxInvoiceReceipt]: {
    value: HeaderMenuIconValue.TaxInvoiceReceipt,
    name: `${DICTIONARY_PATH}.taxInvoiceReceipt`
  },
  [HeaderMenuIconValue.Receipt]: {
    value: HeaderMenuIconValue.Receipt,
    name: `${DICTIONARY_PATH}.receipt`
  },
  [HeaderMenuIconValue.CreditTaxInvoice]: {
    value: HeaderMenuIconValue.CreditTaxInvoice,
    name: `${DICTIONARY_PATH}.creditTaxInvoice`
  }
};

const iconsByTheme: { [key: string]: { [key: string]: HeaderMenuIcon; }; } = {
  default: {
    [HeaderMenuIconValue.Plus]: icons[HeaderMenuIconValue.Plus],
    [HeaderMenuIconValue.Feed]: icons[HeaderMenuIconValue.Feed],
    [HeaderMenuIconValue.AiAutoAssignment]: icons[HeaderMenuIconValue.AiAutoAssignment],
    [HeaderMenuIconValue.MovePassengers]: icons[HeaderMenuIconValue.MovePassengers],
    [HeaderMenuIconValue.Activities]: icons[HeaderMenuIconValue.Activities],
    [HeaderMenuIconValue.MonitoringDashboard]: icons[HeaderMenuIconValue.MonitoringDashboard],
    [HeaderMenuIconValue.SendChanges]: icons[HeaderMenuIconValue.SendChanges],
    [HeaderMenuIconValue.Notes]: icons[HeaderMenuIconValue.Notes],
    [HeaderMenuIconValue.Refresh]: icons[HeaderMenuIconValue.Refresh],
    [HeaderMenuIconValue.PassengersImport]: icons[HeaderMenuIconValue.PassengersImport],
    [HeaderMenuIconValue.ImportContract]: icons[HeaderMenuIconValue.ImportContract]
  },
  orange: {
    [HeaderMenuIconValue.Plus]: {
      ...icons[HeaderMenuIconValue.Plus],
      urls: {
        default: '/assets/images/themes/orange/header/plus.svg',
        hover: '/assets/images/themes/orange/header/plus-hover.svg'
      }
    },
    [HeaderMenuIconValue.Feed]: {
      ...icons[HeaderMenuIconValue.Feed],
      urls: {
        ...icons[HeaderMenuIconValue.Feed].urls,
        hover: '/assets/images/themes/orange/header/feed-hover.svg'
      }
    },
    [HeaderMenuIconValue.AiAutoAssignment]: {
      ...icons[HeaderMenuIconValue.AiAutoAssignment],
      urls: {
        ...icons[HeaderMenuIconValue.AiAutoAssignment].urls,
        hover: '/assets/images/themes/orange/header/ai-hover.svg'
      }
    },
    [HeaderMenuIconValue.MovePassengers]: {
      ...icons[HeaderMenuIconValue.MovePassengers],
      urls: {
        ...icons[HeaderMenuIconValue.MovePassengers].urls,
        hover: '/assets/images/themes/orange/header/move-passengers-hover.svg'
      }
    },
    [HeaderMenuIconValue.Activities]: {
      ...icons[HeaderMenuIconValue.Activities],
      urls: {
        ...icons[HeaderMenuIconValue.Activities].urls,
        hover: '/assets/images/themes/orange/header/bell-hover.svg'
      }
    },
    [HeaderMenuIconValue.MonitoringDashboard]: {
      ...icons[HeaderMenuIconValue.MonitoringDashboard],
      urls: {
        ...icons[HeaderMenuIconValue.MonitoringDashboard].urls,
        hover: '/assets/images/themes/orange/header/location-hover.svg'
      }
    },
    [HeaderMenuIconValue.SendChanges]: {
      ...icons[HeaderMenuIconValue.SendChanges],
      urls: {
        ...icons[HeaderMenuIconValue.SendChanges].urls,
        hover: '/assets/images/themes/orange/header/plane-hover.svg'
      }
    },
    [HeaderMenuIconValue.Notes]: {
      ...icons[HeaderMenuIconValue.Notes],
      urls: {
        ...icons[HeaderMenuIconValue.Notes].urls,
        hover: '/assets/images/themes/orange/header/notes-hover.svg'
      }
    },
    [HeaderMenuIconValue.Refresh]: {
      ...icons[HeaderMenuIconValue.Refresh],
      urls: {
        ...icons[HeaderMenuIconValue.Refresh].urls,
        hover: '/assets/images/themes/orange/header/refresh-hover.svg'
      }
    },
    [HeaderMenuIconValue.PassengersImport]: {
      ...icons[HeaderMenuIconValue.PassengersImport],
      urls: {
        ...icons[HeaderMenuIconValue.PassengersImport].urls,
        hover: '/assets/images/themes/orange/header/import-hover.svg'
      }
    },
    [HeaderMenuIconValue.ImportContract]: {
      ...icons[HeaderMenuIconValue.ImportContract],
      urls: {
        ...icons[HeaderMenuIconValue.ImportContract].urls,
        hover: '/assets/images/themes/orange/header/import-hover.svg'
      }
    }
  },
  green: {
    [HeaderMenuIconValue.Plus]: {
      ...icons[HeaderMenuIconValue.Plus],
      urls: {
        default: '/assets/images/themes/green/header/plus.svg',
        hover: '/assets/images/themes/green/header/plus-hover.svg'
      }
    },
    [HeaderMenuIconValue.Feed]: {
      ...icons[HeaderMenuIconValue.Feed],
      urls: {
        ...icons[HeaderMenuIconValue.Feed].urls,
        hover: '/assets/images/themes/green/header/feed-hover.svg'
      }
    },
    [HeaderMenuIconValue.AiAutoAssignment]: {
      ...icons[HeaderMenuIconValue.AiAutoAssignment],
      urls: {
        ...icons[HeaderMenuIconValue.AiAutoAssignment].urls,
        hover: '/assets/images/themes/green/header/ai-hover.svg'
      }
    },
    [HeaderMenuIconValue.MovePassengers]: {
      ...icons[HeaderMenuIconValue.MovePassengers],
      urls: {
        ...icons[HeaderMenuIconValue.MovePassengers].urls,
        hover: '/assets/images/themes/green/header/move-passengers-hover.svg'
      }
    },
    [HeaderMenuIconValue.Activities]: {
      ...icons[HeaderMenuIconValue.Activities],
      urls: {
        ...icons[HeaderMenuIconValue.Activities].urls,
        hover: '/assets/images/themes/green/header/bell-hover.svg'
      }
    },
    [HeaderMenuIconValue.MonitoringDashboard]: {
      ...icons[HeaderMenuIconValue.MonitoringDashboard],
      urls: {
        ...icons[HeaderMenuIconValue.MonitoringDashboard].urls,
        hover: '/assets/images/themes/green/header/location-hover.svg'
      }
    },
    [HeaderMenuIconValue.SendChanges]: {
      ...icons[HeaderMenuIconValue.SendChanges],
      urls: {
        ...icons[HeaderMenuIconValue.SendChanges].urls,
        hover: '/assets/images/themes/green/header/plane-hover.svg'
      }
    },
    [HeaderMenuIconValue.Notes]: {
      ...icons[HeaderMenuIconValue.Notes],
      urls: {
        ...icons[HeaderMenuIconValue.Notes].urls,
        hover: '/assets/images/themes/green/header/notes-hover.svg'
      }
    },
    [HeaderMenuIconValue.Refresh]: {
      ...icons[HeaderMenuIconValue.Refresh],
      urls: {
        ...icons[HeaderMenuIconValue.Refresh].urls,
        hover: '/assets/images/themes/green/header/refresh-hover.svg'
      }
    },
    [HeaderMenuIconValue.PassengersImport]: {
      ...icons[HeaderMenuIconValue.PassengersImport],
      urls: {
        ...icons[HeaderMenuIconValue.PassengersImport].urls,
        hover: '/assets/images/themes/green/header/import-hover.svg'
      }
    },
    [HeaderMenuIconValue.ImportContract]: {
      ...icons[HeaderMenuIconValue.ImportContract],
      urls: {
        ...icons[HeaderMenuIconValue.ImportContract].urls,
        hover: '/assets/images/themes/green/header/import-hover.svg'
      }
    }
  }
};

const generalActionIconsByTheme: { [key: string]: HeaderMenuIcon[]; } = {
  default: [
    iconsByTheme.default[HeaderMenuIconValue.Plus],
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  orange: [
    iconsByTheme.orange[HeaderMenuIconValue.Plus],
    iconsByTheme.orange[HeaderMenuIconValue.Activities],
    iconsByTheme.orange[HeaderMenuIconValue.Notes],
    iconsByTheme.orange[HeaderMenuIconValue.Refresh]
  ],
  green: [
    iconsByTheme.green[HeaderMenuIconValue.Plus],
    iconsByTheme.green[HeaderMenuIconValue.Activities],
    iconsByTheme.green[HeaderMenuIconValue.Notes],
    iconsByTheme.green[HeaderMenuIconValue.Refresh]
  ]
};

const routesActionIconsByTheme: { [key: string]: HeaderMenuIcon[]; } = {
  default: [
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.MonitoringDashboard],
    iconsByTheme.default[HeaderMenuIconValue.SendChanges],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  orange: [
    iconsByTheme.orange[HeaderMenuIconValue.Activities],
    iconsByTheme.orange[HeaderMenuIconValue.MonitoringDashboard],
    iconsByTheme.orange[HeaderMenuIconValue.SendChanges],
    iconsByTheme.orange[HeaderMenuIconValue.Notes],
    iconsByTheme.orange[HeaderMenuIconValue.Refresh]
  ],
  green: [
    iconsByTheme.green[HeaderMenuIconValue.Activities],
    iconsByTheme.green[HeaderMenuIconValue.MonitoringDashboard],
    iconsByTheme.green[HeaderMenuIconValue.SendChanges],
    iconsByTheme.green[HeaderMenuIconValue.Notes],
    iconsByTheme.green[HeaderMenuIconValue.Refresh]
  ]
};

const routesDailyPlusIcon: HeaderMenuIcon = {
  ...iconsByTheme.default[HeaderMenuIconValue.Plus],
  activateOnClick: true,
  featureGroup: {
    condition: AuthModuleFeatureGroupCondition.Or,
    values: [
      [
        {
          module: AuthModuleName.RouteBuilder,
          name: AuthModuleRouteBuilderFeature.NewRoute
        }
      ],
      [
        {
          module: AuthModuleName.Passengers,
          name: AuthModulePassengersFeature.Type,
          values: [
            AuthModulePassengersFeatureType.Generic,
            AuthModulePassengersFeatureType.Soldier,
            AuthModulePassengersFeatureType.Student,
            AuthModulePassengersFeatureType.Iec
          ]
        }
      ]
    ]
  },
  permissionGroup: {
    condition: AuthPermissionGroupCondition.Or,
    values: [
      [
        AuthPermission.CreateRoutes
      ],
      [
        AuthPermission.ManagePassengers
      ]
    ]
  },
  children: [
    children[HeaderMenuIconValue.AddNewRoute],
    children[HeaderMenuIconValue.RegularRoute],
    children[HeaderMenuIconValue.FixedRoute],
    children[HeaderMenuIconValue.ShiftsRoute],
    children[HeaderMenuIconValue.SpecialRoute],
    children[HeaderMenuIconValue.ShuttleRoute],
    children[HeaderMenuIconValue.DeliveryRoute],
    children[HeaderMenuIconValue.NewPassenger]
  ],
  childrenDividerBefore: HeaderMenuIconValue.NewPassenger
};

const routesDailyPlusIconSC: HeaderMenuIcon = {
  ...iconsByTheme.default[HeaderMenuIconValue.Plus],
  activateOnClick: true,
  replaceByOneChild: true,
  feature: {
    module: AuthModuleName.RouteBuilder,
    name: AuthModuleRouteBuilderFeature.NewRoute
  },
  permission: AuthPermission.CreateRoutes,
  children: [
    children[HeaderMenuIconValue.NewBuilder]
  ]
};

const defaultTemplates: { [key: string]: HeaderMenuIcon[]; } = {
  [HeaderMenuIconsTemplate.RoutesDaily]: [
    routesDailyPlusIcon,
    iconsByTheme.default[HeaderMenuIconValue.Feed],
    {
      ...iconsByTheme.default[HeaderMenuIconValue.AiAutoAssignment],
      permission: AuthPermission.EditRoutes
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.MovePassengers],
      permission: AuthPermission.EditRoutes
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.MonitoringDashboard],
    iconsByTheme.default[HeaderMenuIconValue.SendChanges],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RoutesDailyAiSuggestions]: [
    routesDailyPlusIcon,
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Feed],
      disabled: true
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.AiAutoAssignment],
      permission: AuthPermission.EditRoutes
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.MovePassengers],
      permission: AuthPermission.EditRoutes,
      disabled: true
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    {
      ...iconsByTheme.default[HeaderMenuIconValue.MonitoringDashboard],
      disabled: true
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.SendChanges],
      disabled: true
    },
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RoutesSC]: [
    routesDailyPlusIconSC,
    iconsByTheme.default[HeaderMenuIconValue.Feed],
    iconsByTheme.default[HeaderMenuIconValue.AiAutoAssignment],
    ...routesActionIconsByTheme.default
  ],
  [HeaderMenuIconsTemplate.RoutesDailyAiSuggestionsSC]: [
    routesDailyPlusIconSC,
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Feed],
      disabled: true
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.AiAutoAssignment],
      permission: AuthPermission.EditRoutes
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    {
      ...iconsByTheme.default[HeaderMenuIconValue.MonitoringDashboard],
      disabled: true
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.SendChanges],
      disabled: true
    },
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RoutesWeekly]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      activateOnClick: true,
      replaceByOneChild: true,
      featureGroup: {
        condition: AuthModuleFeatureGroupCondition.Or,
        values: [
          [
            {
              module: AuthModuleName.RouteBuilder,
              name: AuthModuleRouteBuilderFeature.NewRoute
            }
          ],
          [
            {
              module: AuthModuleName.Passengers,
              name: AuthModulePassengersFeature.Type,
              values: [
                AuthModulePassengersFeatureType.Generic,
                AuthModulePassengersFeatureType.Soldier,
                AuthModulePassengersFeatureType.Student,
                AuthModulePassengersFeatureType.Iec
              ]
            }
          ]
        ]
      },
      permissionGroup: {
        condition: AuthPermissionGroupCondition.Or,
        values: [
          [
            AuthPermission.CreateRoutes
          ],
          [
            AuthPermission.ManagePassengers
          ]
        ]
      },
      children: [
        children[HeaderMenuIconValue.AddNewRoute],
        children[HeaderMenuIconValue.RegularRoute],
        children[HeaderMenuIconValue.FixedRoute],
        children[HeaderMenuIconValue.ShiftsRoute],
        children[HeaderMenuIconValue.SpecialRoute],
        children[HeaderMenuIconValue.ShuttleRoute],
        children[HeaderMenuIconValue.DeliveryRoute],
        children[HeaderMenuIconValue.NewPassenger]
      ],
      childrenDividerBefore: HeaderMenuIconValue.NewPassenger
    },
    iconsByTheme.default[HeaderMenuIconValue.Feed],
    ...routesActionIconsByTheme.default
  ],
  [HeaderMenuIconsTemplate.Accompanies]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManageAccompanies
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.Accounting]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      children: [
        children[HeaderMenuIconValue.Quote],
        children[HeaderMenuIconValue.ProFormaInvoice],
        children[HeaderMenuIconValue.TaxInvoice],
        children[HeaderMenuIconValue.TaxInvoiceReceipt],
        children[HeaderMenuIconValue.Receipt],
        children[HeaderMenuIconValue.CreditTaxInvoice]
      ]
    },
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.Branches]: generalActionIconsByTheme.default,
  [HeaderMenuIconsTemplate.BuilderDefaultRoutes]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      activateOnClick: true,
      feature: {
        module: AuthModuleName.RouteBuilder,
        name: AuthModuleRouteBuilderFeature.NewRoute
      },
      permission: AuthPermission.CreateRoutes,
      children: [
        {
          ...children[HeaderMenuIconValue.NewBuilder],
          features: [
            ...children[HeaderMenuIconValue.NewBuilder].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.SubContractor ]
            }
          ]
        },
        {
          ...children[HeaderMenuIconValue.AddNewRoute],
          features: [
            ...children[HeaderMenuIconValue.AddNewRoute].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.ShuttleCompany ]
            }
          ]
        },
        children[HeaderMenuIconValue.RegularRoute],
        children[HeaderMenuIconValue.FixedRoute],
        children[HeaderMenuIconValue.ShiftsRoute],
        children[HeaderMenuIconValue.SpecialRoute],
        children[HeaderMenuIconValue.ShuttleRoute],
        children[HeaderMenuIconValue.DeliveryRoute],
        children[HeaderMenuIconValue.NewBuilderFull]
      ],
      childrenDividerBefore: HeaderMenuIconValue.NewBuilderFull
    }
  ],
  [HeaderMenuIconsTemplate.BuilderDefaultTemplates]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      activateOnClick: true,
      replaceByOneChild: true,
      children: [
        {
          ...children[HeaderMenuIconValue.AddNewTemplate],
          features: [
            ...children[HeaderMenuIconValue.AddNewTemplate].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.ShuttleCompany ]
            }
          ]
        },
        {
          ...children[HeaderMenuIconValue.AddNewTemplate],
          disabled: false,
          name: `${DICTIONARY_PATH}.newRouteTemplate`,
          features: [
            ...children[HeaderMenuIconValue.AddNewTemplate].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.SubContractor ]
            }
          ]
        },
        children[HeaderMenuIconValue.RegularRoute],
        children[HeaderMenuIconValue.FixedRoute],
        children[HeaderMenuIconValue.ShiftsRoute],
        children[HeaderMenuIconValue.SpecialRoute],
        children[HeaderMenuIconValue.ShuttleRoute],
        children[HeaderMenuIconValue.DeliveryRoute]
      ]
    }
  ],
  [HeaderMenuIconsTemplate.BuilderFull]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      name: `${DICTIONARY_PATH}.newPassenger`,
      features: [
        {
          module: AuthModuleName.Passengers,
          name: AuthModulePassengersFeature.Type,
          values: [
            AuthModulePassengersFeatureType.Generic,
            AuthModulePassengersFeatureType.Soldier,
            AuthModulePassengersFeatureType.Student
          ]
        },
        {
          module: AuthModuleName.RoutePlanner,
          name: AuthModuleRoutePlannerFeature.Type,
          values: [ AuthModuleRoutePlannerFeatureType.Generic ]
        }
      ],
      permission: AuthPermission.ManagePassengers
    }
  ],
  [HeaderMenuIconsTemplate.BuilderFullResults]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.CreateRoutes,
      replaceByOneChild: true,
      activateOnClick: true,
      children: [
        {
          ...children[HeaderMenuIconValue.NewBuilder],
          features: [
            ...children[HeaderMenuIconValue.NewBuilder].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.SubContractor ]
            }
          ]
        },
        {
          ...children[HeaderMenuIconValue.AddNewRoute],
          features: [
            ...children[HeaderMenuIconValue.AddNewRoute].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.ShuttleCompany ]
            }
          ]
        },
        children[HeaderMenuIconValue.RegularRoute],
        children[HeaderMenuIconValue.FixedRoute],
        children[HeaderMenuIconValue.ShiftsRoute],
        children[HeaderMenuIconValue.SpecialRoute],
        children[HeaderMenuIconValue.ShuttleRoute],
        children[HeaderMenuIconValue.DeliveryRoute]
      ]
    }
  ],
  [HeaderMenuIconsTemplate.Contracts]: generalActionIconsByTheme.default,
  [HeaderMenuIconsTemplate.Drivers]: generalActionIconsByTheme.default,
  [HeaderMenuIconsTemplate.Passengers]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManagePassengers
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.PassengersArmy]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManagePassengers
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.PassengersImport],
      permission: AuthPermission.ManagePassengers
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.PassengersMunicipality]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManagePassengers
    },
    {
      ...iconsByTheme.default[HeaderMenuIconValue.PassengersImport],
      permission: AuthPermission.ManagePassengers
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.Reports]: [
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RideSupervisors]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManagePassengers
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RideOrders]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      name: `${DICTIONARY_PATH}.newOrder`
    },
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.RouteTemplates]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      activateOnClick: true,
      replaceByOneChild: true,
      children: [
        {
          ...children[HeaderMenuIconValue.AddNewTemplate],
          features: [
            ...children[HeaderMenuIconValue.AddNewTemplate].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.ShuttleCompany ]
            }
          ]
        },
        {
          ...children[HeaderMenuIconValue.AddNewTemplate],
          disabled: false,
          name: `${DICTIONARY_PATH}.newRouteTemplate`,
          features: [
            ...children[HeaderMenuIconValue.AddNewTemplate].features,
            {
              module: AuthModuleName.Transportation,
              name: AuthModuleTransportationFeature.ExecutorType,
              values: [ AuthModuleTransportationFeatureExecutorType.SubContractor ]
            }
          ]
        },
        children[HeaderMenuIconValue.RegularRoute],
        children[HeaderMenuIconValue.FixedRoute],
        children[HeaderMenuIconValue.ShiftsRoute],
        children[HeaderMenuIconValue.SpecialRoute],
        children[HeaderMenuIconValue.ShuttleRoute],
        children[HeaderMenuIconValue.DeliveryRoute]
      ]
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.Schools]: generalActionIconsByTheme.default,
  [HeaderMenuIconsTemplate.DemandsPassengersView]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      feature: {
        module: AuthModuleName.Passengers,
        name: AuthModulePassengersFeature.Type,
        values: [
          AuthModulePassengersFeatureType.Generic,
          AuthModulePassengersFeatureType.Soldier,
          AuthModulePassengersFeatureType.Student
        ]
      },
      permission: AuthPermission.ManagePassengers
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.DemandsShiftsView]: [
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.ShuttleCompaniesGeneral]: generalActionIconsByTheme.default,
  [HeaderMenuIconsTemplate.ShuttleCompaniesCustomers]: [
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      name: `${DICTIONARY_PATH}.newCustomer`
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.ShuttleCompanies]: [
    iconsByTheme.default[HeaderMenuIconValue.ImportContract],
    {
      ...iconsByTheme.default[HeaderMenuIconValue.Plus],
      permission: AuthPermission.ManageShuttleCompanies
    },
    iconsByTheme.default[HeaderMenuIconValue.Activities],
    iconsByTheme.default[HeaderMenuIconValue.Notes],
    iconsByTheme.default[HeaderMenuIconValue.Refresh]
  ],
  [HeaderMenuIconsTemplate.Stations]: generalActionIconsByTheme.default
};

const orangeTemplates = Object.entries(defaultTemplates).reduce((acc, [ key, value ]) => ({
  ...acc,
  [key]: value.map(icon => ({
    ...icon,
    urls: iconsByTheme.orange[icon.value].urls
  }))
}), {});

const greenTemplates = Object.entries(defaultTemplates).reduce((acc, [ key, value ]) => ({
  ...acc,
  [key]: value.map(icon => ({
    ...icon,
    urls: iconsByTheme.green[icon.value].urls
  }))
}), {});

const templatesByEnvTypes = {
  default: defaultTemplates,
  [EnvType.IDF]: orangeTemplates,
  [EnvType.Traffical]: orangeTemplates
};

export const headerMenuIconsConfig = {
  mainMenuIconsIds: [ icons[HeaderMenuIconValue.Plus].id ],
  templates: {
    default: templatesByEnvTypes,
    [AuthCustomerType.ShuttleCompany]: {
      ...templatesByEnvTypes,
      [EnvType.Shift]: greenTemplates
    }
  }
};
