<div class="routes-change__container">
  <button class="routes-change__close" (click)="closeAction.emit()"></button>
  <ng-container [ngSwitch]="type">
    <app-routes-change-hour
      *ngSwitchCase="routesChangeViewType.Hour"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
      [timeType]="changeOptions.timeType"
      [authCustomer]="authCustomer"
      [activeDays]="activeDays"
    ></app-routes-change-hour>
    <app-routes-change-accompany
      *ngSwitchCase="routesChangeViewType.Accompany"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
    ></app-routes-change-accompany>
    <app-routes-change-accompany-cost
      *ngSwitchCase="routesChangeViewType.AccompanyCost"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
    ></app-routes-change-accompany-cost>
    <app-routes-change-passenger
      *ngSwitchCase="routesChangeViewType.Passenger"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
    ></app-routes-change-passenger>
    <app-routes-change-shuttle-company
      *ngSwitchCase="routesChangeViewType.ShuttleCompany"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
      [aiSuggestion]="aiSuggestion"
    ></app-routes-change-shuttle-company>
    <app-routes-change-car
      *ngSwitchCase="routesChangeViewType.Car"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
      [viewTypeMode]="viewTypeMode"
    ></app-routes-change-car>
    <app-routes-change-supervisor
      *ngSwitchCase="routesChangeViewType.Supervisor"
      [authCustomer]="authCustomer"
      [activeRide]="activeRide"
      [viewportElement]="viewportElement"
    ></app-routes-change-supervisor>
    <app-routes-change-time-tracking
      *ngSwitchCase="routesChangeViewType.TimeTracking"
      [activeRide]="activeRide"
    ></app-routes-change-time-tracking>
  </ng-container>
</div>
