import { computed, DestroyRef, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { environment } from '@environments/environment';
import { AuthDataService } from '@app/auth/services';
import {
  CustomerDataType,
  HeaderMenuIcon,
  HeaderMenuIconChild,
  HeaderMenuIconFetchItemsKey,
  HeaderMenuIconsTemplate,
  HeaderMenuIconValue
} from '@app/shared/models';
import { patchSignal } from '@app/shared/utils';
import { headerMenuIconsConfig } from '@app/shared/configs';
import { routesConfig } from '@app/routes/configs';
import { ActivitiesDataService } from '@app/activities/services';
import { CustomerDataService } from './api/customer-data.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuIconsService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly authDataService = inject(AuthDataService);
  private readonly commonService = inject(CommonService);
  private readonly activitiesDataService = inject(ActivitiesDataService);
  private readonly customerDataService = inject(CustomerDataService);

  private showMenu: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private menuIcons: BehaviorSubject<HeaderMenuIcon[]> = new BehaviorSubject([]);
  private menuIconClick: Subject<HeaderMenuIcon | HeaderMenuIconChild> = new Subject();
  private disabledMenuIcons: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly #state = signal({
    items: {
      [HeaderMenuIconFetchItemsKey.RouteTypes]: {
        isLoading: false,
        items: undefined
      }
    }
  });

  readonly state = this.#state.asReadonly();
  readonly items = computed(() => this.state().items);

  showMenu$ = this.showMenu.asObservable();
  menuIcons$ = combineLatest([
    this.commonService.visibleComponents$,
    this.menuIcons.asObservable(),
    this.activitiesDataService.activitiesLiveCounter$,
    this.disabledMenuIcons.asObservable()
  ])
    .pipe(
      map(([ visibleComponents, menuIcons, activitiesLiveCounter, disabledMenuIcons ]) => menuIcons.map(menuIcon => ({
        ...menuIcon,
        ...(visibleComponents.hasOwnProperty(menuIcon.value) && menuIcon.activateOnClick ? { selected: visibleComponents[menuIcon.value] } : {}),
        ...(menuIcon.value === HeaderMenuIconValue.Activities ? { counter: activitiesLiveCounter } : {}),
        disabled: disabledMenuIcons || menuIcon.disabled
      })))
    );
  mainMenuIcons$ = this.menuIcons$.pipe(
    map(items => items.filter(item => headerMenuIconsConfig.mainMenuIconsIds.includes(item.id))),
    distinctUntilChanged()
  );
  actionMenuIcons$ = this.menuIcons$.pipe(
    map(items => items.filter(item => !headerMenuIconsConfig.mainMenuIconsIds.includes(item.id))),
    distinctUntilChanged()
  );
  menuIconClick$ = this.menuIconClick.asObservable();

  readonly fetchItemsByKeys = {
    [HeaderMenuIconFetchItemsKey.RouteTypes]: this.getRouteTypes.bind(this)
  };

  private getRouteTypes() {
    return this.customerDataService.getCustomerData({ types: [ CustomerDataType.RouteTypes ] })
      .pipe(map(({ routetypes }) => routetypes));
  }

  private checkRouteTypes = (icon: HeaderMenuIcon | HeaderMenuIconChild): boolean =>
    !!this.items()[HeaderMenuIconFetchItemsKey.RouteTypes].items?.find(type => type.id === routesConfig.routeTypeByHeaderMenuIconValue[icon.value]);

  private setMenuIcons(icons: HeaderMenuIcon[]) {
    this.menuIcons.next(
      icons
        .filter((icon: HeaderMenuIcon) => this.authDataService.checkFeatureAndPermission(icon) && (!this?.[icon?.checkFnName] || this?.[icon.checkFnName](icon)))
        .map((icon: HeaderMenuIcon) => {
          const children = icon.children?.filter((child: HeaderMenuIconChild) => this.authDataService.checkFeatureAndPermission(child) && (!this?.[child?.checkFnName] || this?.[child.checkFnName](child))) || [];

          return icon.children ? {
            ...icon,
            ...(
              icon.replaceByOneChild && children?.length === 1
                ? { name: children[0].name, value: children[0].value, children: null }
                : {
                  children: icon.childrenDividerBefore
                    ? this.addDividerToChildren(children, icon.childrenDividerBefore)
                    : children
                })
          } : icon;
        })
    );
  }

  private addDividerToChildren(children: HeaderMenuIconChild[], childrenDividerBefore: HeaderMenuIconValue): HeaderMenuIconChild[] {
    const dividerIndex = children.findIndex(child => childrenDividerBefore === child.value) - 1;

    return children.map((child, index) => ({ ...child, divider: index === dividerIndex }));
  }

  private getFetchItemsData(icons: HeaderMenuIcon[]) {
    const skipItemsForLoading = Object.keys(this.items()).reduce((acc, item) => this.items()[item].items || this.items()[item].isLoading ? [ ...acc, item ] : acc, []);
    let fetchItems = {};

    for (const icon of icons) {
      if (icon?.fetchItemsKey && !skipItemsForLoading.includes(icon?.fetchItemsKey)) {
        fetchItems = { ...fetchItems, [icon.fetchItemsKey]: this.fetchItemsByKeys[icon.fetchItemsKey]() };
      }

      for (const child of icon?.children || []) {
        if (child?.fetchItemsKey && !skipItemsForLoading.includes(child?.fetchItemsKey)) {
          fetchItems = { ...fetchItems, [child.fetchItemsKey]: this.fetchItemsByKeys[child.fetchItemsKey]() };
        }
      }
    }

    return fetchItems;
  }

  setMenuIconsByTemplate(template: HeaderMenuIconsTemplate) {
    const userInfo = this.authDataService.userInfo();
    const templatesByCustomerType = headerMenuIconsConfig.templates[userInfo.customer.type] || headerMenuIconsConfig.templates.default;
    const templates = templatesByCustomerType[environment.config.environmentType] || templatesByCustomerType.default;
    const menuIcons = templates?.[template];

    if (menuIcons) {
      const fetchItems = this.getFetchItemsData(menuIcons);
      const fetchItemsKeys = Object.keys(fetchItems);
      const fetchItemsObs = Object.values(fetchItems);

      if (fetchItemsObs.length) {
        patchSignal(this.#state, state => ({ items: { ...state.items, ...fetchItemsKeys.reduce((acc, key) => ({ ...acc, [key]: { ...state.items[key], isLoading: true } }), {}) } }));

        forkJoin(fetchItemsObs)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
            (data) => {
              patchSignal(this.#state, state => ({ items: { ...state.items, ...fetchItemsKeys.reduce((acc, key, index) => ({ ...acc, [key]: { ...state.items[key], items: data[index], isLoading: false } }), {}) } }));

              this.setMenuIcons(menuIcons);
            },
            () => patchSignal(this.#state, state => ({ items: { ...state.items, ...fetchItemsKeys.reduce((acc, key) => ({ ...acc, [key]: { ...state.items[key], isLoading: false } }), {}) } }))
          );
      } else {
        this.setMenuIcons(menuIcons);
      }
    }
  }

  resetIcons() {
    this.menuIcons.next([]);
  }

  selectIcon(icon: HeaderMenuIcon | HeaderMenuIconChild, selectIcon: boolean) {
    this.menuIconClick.next(icon);

    const isIcon = (icon as HeaderMenuIcon).urls;

    if (isIcon && selectIcon && (icon as HeaderMenuIcon).activateOnClick) {
      this.menuIcons.next(
        this.menuIcons.value.map(menuIcon => menuIcon.value === icon.value ? {
          ...menuIcon,
          selected: !menuIcon.selected
        } : menuIcon)
      );
    }
  }

  unselectIcon(icon: HeaderMenuIcon) {
    this.menuIcons.next(
      this.menuIcons.value.map(menuIcon => icon.value === menuIcon.value ? {
        ...menuIcon,
        selected: false
      } : menuIcon)
    );
  }

  updateShowMenu(value: boolean) {
    this.showMenu.next(value);
  }

  updateDisabledMenuIcons(value: boolean) {
    this.disabledMenuIcons.next(value);
  }
}
